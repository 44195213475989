export const endpoints = {
  ORGANIZATION_SUBSIDIARY_VIEW: "/organization/subsidiary/view",
  ORGANIZATION_SUBSIDIARY_SELECT: "/organization/subsidiary/select",
  ORGANIZATION_SUBSIDIARY_CREATE: "/organization/subsidiary/create",
  ORGANIZATION_SUBSIDIARY_UPDATE: "/organization/subsidiary/update",
  ORGANIZATION_SUBSIDIARY_DELETE: "/organization/subsidiary/delete",
  ORGANIZATION_SUBSIDIARY_EMPLOYEE_CREATE: "/organization/subsidiary/admin/create",
  ORGANIZATION_SUBSIDIARY_EMPLOYEE_UPDATE: "/organization/subsidiary/admin/update",
  ORGANIZATION_SUBSIDIARY_EMPLOYEE_DELETE: "/organization/subsidiary/admin/delete",
  PROBLEMS_TREE_SELECT: "/organization/related/problem",
  NEIGHBORHOODS_TREE_SELECT: "/organization/related/address",
}