import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { Pagination } from "../../../utils/models/paginationType"
import { ProblemModel } from "../utils/models/problemModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { DetailedProblemModel } from "../utils/models/detailedProblemModel"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

// get problems
export function useGetProblems() {
  const url = addQueryParams(endpoints.ORGANIZATION_PROBLEMS_VIEW)

  return useQuery<Pagination<ProblemModel>, ErrorRes>(
    [queryKeys.ORGANIZATION_PROBLEMS_VIEW, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get detailed problem
export function useGetDetailedProblem(id: number | undefined) {
  return useQuery<DetailedProblemModel, ErrorRes>(
    [queryKeys.ORGANIZATION_DETAILED_PROBLEM, id],
    async () => {
      const res = await $api.get(`${endpoints.ORGANIZATION_PROBLEMS_VIEW}?id=${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: errorHandler,
    },
  )
}

// get employees select
export function useGetEmployeesSelect() {
  return useQuery<{ full_name: string, id: number }[], ErrorRes>(
    [queryKeys.ORGANIZATION_EMPLOYEES_SELECT],
    async () => {
      const res = await $api.get(endpoints.ORGANIZATION_EMPLOYEES_SELECT)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      onError: errorHandler,
    },
  )
}

// get subsidiaries select
export function useGetSubsidiariesSelect() {
  return useQuery<{
    "id": number
    "name": string
  }[], ErrorRes>(
    [queryKeys.ORGANIZATION_SUBSIDIARY_SELECT],
    async () => {
      const res = await $api.get(endpoints.ORGANIZATION_SUBSIDIARY_SELECT)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}