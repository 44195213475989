import { useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"

import { PAGE } from "../utils/constants/queryParamsKeys"

type returnType = {
  query: queryString.ParsedQuery,
  append: (key: string, value: string) => void
  remove: (key: string) => void
}

export const useQueryParams = (): returnType => {
  const location = useLocation()
  const navigate = useNavigate()

  // query
  const query = queryString.parse(location.search)

  // push
  const push = () => {
    navigate({
      search: queryString.stringify(query),
    })
  }

  // append
  const append = (key: string, value: string) => {
    if (query.page && +query.page > 1 && key !== PAGE) {
      query[PAGE] = "1"
    }

    query[key] = value

    push()
  }

  // remove
  const remove = (key: string) => {
    delete query[key]

    push()
  }

  return {
    query, append, remove,
  }
}