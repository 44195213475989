import { useMutation, useQueryClient } from "@tanstack/react-query"

import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { OrganizationBodyModel } from "../utils/models/organizationBodyModel"
import { EmployeeBodyModel } from "../utils/models/employeeBodyModel"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// attach organization
export function useAttachOrganization() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { organization_id: number }>(
    async (req) => {
      const res = await $api.post(endpoints.CITY_ORGANIZATION_ATTACH, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ORGANIZATION])
      },
      onError: errorHandler,
    },
  )
}

// create organization
export function useCreateOrganization() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, OrganizationBodyModel>(
    async (req) => {
      const res = await $api.post(endpoints.CITY_ORGANIZATION_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ORGANIZATION])
      },
      onError: errorHandler,
    },
  )
}

// update organization
export function useUpdateOrganization() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, OrganizationBodyModel & { id: number }>(
    async (req) => {
      const res = await $api.put(endpoints.CITY_ORGANIZATION_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ORGANIZATION])
      },
      onError: errorHandler,
    },
  )
}

// delete organization
export function useDeleteOrganization() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.CITY_ORGANIZATION_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ORGANIZATION])
      },
      onError: errorHandler,
    },
  )
}

// create employee
export function useCreateEmployee() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, EmployeeBodyModel>(
    async (req) => {
      const res = await $api.post(endpoints.CITY_EMPLOYEE_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ORGANIZATION])
      },
      onError: errorHandler,
    },
  )
}

// update employee
export function useUpdateEmployee() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, EmployeeBodyModel & { id: number }>(
    async (req) => {
      const res = await $api.post(endpoints.CITY_EMPLOYEE_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ORGANIZATION])
      },
      onError: errorHandler,
    },
  )
}

//delete employee
export function useDeleteEmployee() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
      const res = await $api.delete(`${endpoints.CITY_EMPLOYEE_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ORGANIZATION])
      },
      onError: errorHandler,
    },
  )
}

// create tag
export const useCreateTag = () => {
  return useMutation<SuccessRes<{ data: { id: number, name: string } }>, ErrorRes, { name: string }>(
    async (req) => {
      const res = await $api.post(endpoints.CITY_TAG_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
      },
      onError: errorHandler,
    },
  )
}

// delete tag
export const useDeleteTag = () => {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number }>(
    async (req) => {
      const res = await $api.delete(`${endpoints.CITY_TAG_DELETE}?id=${req.id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_TAGS])
      },
      onError: errorHandler,
    },
  )
}