import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/endPoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { AdminModel } from "../utils/models/adminModel"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { MainOrganizationBodyModel } from "../utils/models/mainOrganizationBodyModel"
import { ID } from "../../../utils/constants/queryParamsKeys"

// create admin
export function useCreateAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, Omit<AdminModel, "id" | "status">>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_MAIN_ORGANIZATION_ADMIN_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_MAIN_ORGANIZATION_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// update admin
export function useUpdateAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, Omit<AdminModel, "status" | "main_organization_id">>(
    async (req) => {
      const res = await $api.put(endpoints.REGION_MAIN_ORGANIZATION_ADMIN_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_MAIN_ORGANIZATION_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete admin
export function useDeleteAdmin() {
  const qc = useQueryClient()
  return useMutation(async (id: number) => {
      const res = await $api.delete(`${endpoints.REGION_MAIN_ORGANIZATION_ADMIN_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_MAIN_ORGANIZATION_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// create main organization
export function useCreateMainOrganization() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, MainOrganizationBodyModel>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_MAIN_ORGANIZATION_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_MAIN_ORGANIZATION_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// update organization
export function useUpdateMainOrganization() {
  const qc = useQueryClient()

  return useMutation<
    SuccessRes,
    ErrorRes,
    MainOrganizationBodyModel & { id: number }
  >(
    async (req) => {
      const res = await $api.put(endpoints.REGION_MAIN_ORGANIZATION_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_MAIN_ORGANIZATION_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete main organization
export function useDeleteMainOrganization() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(
        `${endpoints.REGION_MAIN_ORGANIZATION_DELETE}?${ID}=${id}`,
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_MAIN_ORGANIZATION_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// create tag
export const useCreateTag = () => {
  return useMutation<SuccessRes<{ data: { id: number, name: string } }>, ErrorRes, { name: string }>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_TAG_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
      },
      onError: errorHandler,
    },
  )
}

// delete tag
export const useDeleteTag = () => {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number }>(
    async (req) => {
      const res = await $api.delete(`${endpoints.REGION_TAG_DELETE}?id=${req.id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_TAGS])
      },
      onError: errorHandler,
    },
  )
}