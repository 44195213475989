import React from "react"
import { Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"

import DeleteIcon from "../../assets/icons/DeleteIcon"
import EditIcon from "../../assets/icons/EditIcon"
import BodyLayout from "../../common/body-layout/BodyLayout"
import OrganizationModal from "./components/organization-modal/OrganizationModal"
import { useGetOrganizations } from "./services/queries"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import { useAppDispatch } from "../../hooks/redux"
import { regionOrganizationReducerActions } from "../../store/reducers/regionOrganizationReducer"
import { useDeleteOrganization } from "./services/mutation"
import { OrganizationModel } from "./utils/models/organizationModel"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setModalData } = regionOrganizationReducerActions
  const { data, isLoading } = useGetOrganizations()
  const deleteOrganization = useDeleteOrganization()

  // open problem-accident-modal
  const handleOpenModal = () => {
    dispatch(setModalData({ visible: true }))
  }

  // update
  const handleUpdate = (data: OrganizationModel) => {
    dispatch(
      setModalData({
        visible: true,
        id: data?.id,
      }),
    )
  }

  // delete
  const handleDelete = (id: number) => {
    return deleteOrganization.mutateAsync(id)
  }

  const columns: ColumnsType<OrganizationModel> = [
    {
      title: "N",
      render: (_, __, index) => <span>{index + 1}</span>,
      width: 60,
    },
    {
      title: "Nomi",
      dataIndex: "name",
      render: (name) => <p className={styles.name}>{name}</p>,
    },
    {
      title: "Muammo sinfi",
      render: (record: OrganizationModel) => (
        <div className={styles.classes}>
          {record?.problem_classes?.map((item) => (
            <Tag className={styles.tag} key={item.id}>
              {item.image && <img alt="" src={item.image} />}
              <span>{item.name}</span>
            </Tag>
          ))}
        </div>
      ),
    },
    {
      render: (record: OrganizationModel) => (
        <div className={styles.actions}>
          <div className="f_div" onClick={() => handleUpdate(record)}>
            <EditIcon />
          </div>
          <DeleteConfirm
            text={`${record.name} nomli tashkilotni o'chirmoqchimisiz?`}
            onConfirm={() => handleDelete(record.id)}
            isLoading={deleteOrganization.isLoading}
          >
            <div className="f_div">
              <DeleteIcon />
            </div>
          </DeleteConfirm>
        </div>
      ),
      width: 84,
    },
  ]

  return (
    <>
      <BodyLayout
        title="Tashkilotlar"
        createButton={{
          text: "Tashkilot qo'shish",
          onCreate: handleOpenModal,
        }}
        pagination={{
          total: data?.total,
        }}
      >
        <div className={styles.organizations}>
          <Table
            loading={isLoading}
            dataSource={data?.data}
            className={styles.organizations_table}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.id}
          />
        </div>
      </BodyLayout>
      <OrganizationModal />
    </>
  )
}

export default Index
