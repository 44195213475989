import React from "react"
import { Button, Form, Input } from "antd"

import Logo from "../../../../assets/images/logo.png"
import { useLogin } from "../../services/mutation"
import { LoginBodyType } from "../../utils/models/loginType"

import styles from "./authForm.module.scss"

const AuthForm: React.FC = () => {
  const login = useLogin()

  // finish
  const onFinish = (values: LoginBodyType) => {
    login.mutate(values)
  }

  return (
    <>
      <div className={styles.auth_form}>
        <div className={styles.auth_form_title}>
          <img alt="" src={Logo} />
          {/*<h1>XALQ NAZORATI</h1>*/}
        </div>
        <div className={styles.auth_form_body}>
          <h2>Kirish</h2>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Username"
              name="login"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "" }]}
            >
              <Input.Password />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={login.isLoading}
            >
              Kirish
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}

export default AuthForm
