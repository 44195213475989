import React, { useState } from "react"

import { StatisticsEvaluationsModel } from "../../../utils/models/statisticsEvaluationsModel"

import styles from "./statisticsEvaluations.module.scss"
import { Progress, Rate, Spin } from "antd"
import StatisticsEvaluationsModal from "./modal/StatisticsEvaluationsModal"

type Props = {
  evaluations: StatisticsEvaluationsModel | undefined
  isModal?: boolean
  isLoading: boolean
}

const StatisticsEvaluations: React.FC<Props> = ({
  evaluations,
  isModal,
  isLoading,
}) => {
  const [visibleModal, setVisibleModal] = useState(false)

  // open modal
  const handleOpenModal = () => {
    setVisibleModal(true)
  }

  // percentage
  const percentage = (count: number) => {
    if (count === 0) return 0
    return (100 * count) / (evaluations?.count ?? 0)
  }

  return (
    <Spin spinning={isLoading}>
      <div className={styles.evaluations}>
        <div className={styles.header}>
          <div className={styles.header_left}>
            <h2>Sharhlar</h2>
            <p>Barcha sharhlar bo’yicha to’liq statistika</p>
          </div>
          {!isModal && (
            <div className={styles.header_right}>
              <p onClick={handleOpenModal}>To'liq ko'rish</p>
            </div>
          )}
        </div>
        <div className={`${styles.body} ${isModal ? styles.modal : ""}`}>
          <div className={styles.body_left}>
            <h3>{evaluations?.rating}</h3>
            <p>{evaluations?.count} ta sharh</p>
          </div>
          <div className={styles.body_right}>
            {evaluations?.comments?.map((comment) => (
              <div key={comment.id}>
                <div className={styles.comment_item}>
                  <div className={styles.star}>
                    <Rate value={comment.stars_number} disabled />
                  </div>
                  <div className={styles.count}>
                    <p>{comment.count}</p>
                    <Progress percent={percentage(comment.count)} showInfo={false} />
                  </div>
                </div>
                {isModal &&
                  comment?.evaluation_variants?.map((item) => (
                    <div key={item.id} className={styles.variant_item}>
                      <p>{item.name}</p>
                      <p>{item.count}</p>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        {!isModal && (
          <StatisticsEvaluationsModal
            visible={visibleModal}
            setVisible={setVisibleModal}
            evaluations={evaluations}
          />
        )}
      </div>
    </Spin>
  )
}

export default StatisticsEvaluations
