import { Button, Checkbox, Form, Input, Modal, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { CheckboxChangeEvent } from "antd/es/checkbox"

import { useCreateCityAdmin, useUpdateCityAdmin } from "../../services/mutation"
import { useGetCityOrganizations } from "../../services/queries"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { cityEmployeereducerActions } from "../../../../store/reducers/cityEmployeeReducer"
import { CityEmployeeModel } from "../../utils/models/CityEmployeeModel"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"

import styles from "./modal.module.scss"

const CityEmployeeModal: React.FC = () => {
  const [form] = Form.useForm()
  const createCityAdmin = useCreateCityAdmin()
  const { data: cityOrganization } = useGetCityOrganizations()
  const { cityEmployeeModalData } = useAppSelector(
    (state) => state.cityEmployeeReducer,
  )
  const dispatch = useAppDispatch()
  const { setCityEmployeeModalData: setModalOpen } = cityEmployeereducerActions
  const updateCityAdmin = useUpdateCityAdmin()
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  // is updating
  const isUpdating = useMemo(() => {
    return !!cityEmployeeModalData.data
  }, [cityEmployeeModalData.data])


  const selectOptions = cityOrganization?.data
    ?.filter((item) => item?.my_organization === true)
    ?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }))

  // onCancel
  const onCancel = () => {
    dispatch(
      setModalOpen({
        visible: false,
      }),
    )
  }

  useEffect(() => {
    if (cityEmployeeModalData.data) {
      form.setFieldsValue({
        ...cityEmployeeModalData.data,
        organization_ids: cityEmployeeModalData?.data?.organizations.map(
          (item) => item.id,
        ),
      })
    }
  }, [form, cityEmployeeModalData.data])

  //onFinish
  const onFinish = (values: CityEmployeeModel) => {
    const req = {
      ...values,
      phone: parsePhoneNumber(values.phone),
    }

    if (cityEmployeeModalData.data) {
      updateCityAdmin
        .mutateAsync({ ...req, id: cityEmployeeModalData?.data?.id })
        .then(onCancel)
    } else {
      createCityAdmin.mutateAsync(req).then(onCancel)
    }
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    setIsChangingPassword(false)
  }

  // change is updating password
  const handleChangeIsUpdatingPassword = (e: CheckboxChangeEvent) => {
    setIsChangingPassword(e.target.checked)
  }

  // title
  const title = useMemo(() => {
    if (cityEmployeeModalData.data) return "Xodim tahrirlash"
    return "Xodim qo'shish"
  }, [cityEmployeeModalData.data])

  return (
    <Modal
      title={title}
      open={cityEmployeeModalData.visible}
      onCancel={onCancel}
      className={styles.modal}
      footer={false}
      centered
      afterClose={handleAfterClose}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          label="To'liq ismi"
          name={"full_name"}
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <PhoneInput name="phone" label="Telefon raqam" required={true} />
        <Form.Item
          label="Tashkilot"
          name={"organization_ids"}
          rules={[{ required: true, message: "" }]}
        >
          <Select
            mode="multiple"
            options={selectOptions}
          />
        </Form.Item>
        <div className={styles.auth}>
          <Form.Item
            label="Login"
            name={"login"}
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          {(isChangingPassword || !isUpdating) && (
            <Form.Item
              label="Parol"
              name={"password"}
              rules={[{ required: true, message: "" }]}
            >
              <Input.Password />
            </Form.Item>
          )}
        </div>
        {isUpdating && (
          <Form.Item className={styles.change_password}>
            <Checkbox checked={isChangingPassword} onChange={handleChangeIsUpdatingPassword}>Parolni
              yangilash</Checkbox>
          </Form.Item>
        )}
        <div className={styles.btns}>
          <Button onClick={onCancel}>Bekor qilish</Button>
          <Button htmlType="submit" loading={createCityAdmin.isLoading || updateCityAdmin.isLoading}>
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CityEmployeeModal
