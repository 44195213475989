import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Pusher from "pusher-js"

import { sliceNames } from "../../utils/constants/sliceNames"


const initialState: { pusher: Pusher | null } = {
  pusher: null,
}

const pusherReducer = createSlice({
  name: sliceNames.PUSHER,
  initialState,
  reducers: {
    setPusher: (state, action: PayloadAction<Pusher>) => {
      state.pusher = action.payload
    },
  },
})

export default pusherReducer.reducer
export const pusherReducerActions = pusherReducer.actions