import React from "react"

const DownloadPdfIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.8333C5.93333 11.8333 5.87333 11.82 5.80667 11.7933C5.62 11.72 5.5 11.5333 5.5 11.3333V7.33331C5.5 7.05998 5.72667 6.83331 6 6.83331C6.27333 6.83331 6.5 7.05998 6.5 7.33331V10.1266L6.98 9.64665C7.17333 9.45331 7.49333 9.45331 7.68667 9.64665C7.88 9.83998 7.88 10.16 7.68667 10.3533L6.35333 11.6866C6.26 11.78 6.12667 11.8333 6 11.8333Z"
        fill="#292D32"
      />
      <path
        d="M5.99964 11.8333C5.87297 11.8333 5.7463 11.7866 5.6463 11.6866L4.31297 10.3533C4.11964 10.16 4.11964 9.83998 4.31297 9.64665C4.5063 9.45331 4.8263 9.45331 5.01964 9.64665L6.35297 10.98C6.5463 11.1733 6.5463 11.4933 6.35297 11.6866C6.25297 11.7866 6.1263 11.8333 5.99964 11.8333Z"
        fill="#292D32"
      />
      <path
        d="M10.0007 15.1666H6.00065C2.38065 15.1666 0.833984 13.62 0.833984 9.99998V5.99998C0.833984 2.37998 2.38065 0.833313 6.00065 0.833313H9.33398C9.60732 0.833313 9.83398 1.05998 9.83398 1.33331C9.83398 1.60665 9.60732 1.83331 9.33398 1.83331H6.00065C2.92732 1.83331 1.83398 2.92665 1.83398 5.99998V9.99998C1.83398 13.0733 2.92732 14.1666 6.00065 14.1666H10.0007C13.074 14.1666 14.1673 13.0733 14.1673 9.99998V6.66665C14.1673 6.39331 14.394 6.16665 14.6673 6.16665C14.9407 6.16665 15.1673 6.39331 15.1673 6.66665V9.99998C15.1673 13.62 13.6207 15.1666 10.0007 15.1666Z"
        fill="#292D32"
      />
      <path
        d="M14.6673 7.16664H12.0007C9.72065 7.16664 8.83398 6.27997 8.83398 3.99997V1.33331C8.83398 1.13331 8.95398 0.946639 9.14065 0.873305C9.32732 0.793305 9.54065 0.839972 9.68732 0.979972L15.0207 6.31331C15.1607 6.45331 15.2073 6.67331 15.1273 6.85997C15.0473 7.04664 14.8673 7.16664 14.6673 7.16664ZM9.83398 2.53997V3.99997C9.83398 5.71997 10.2807 6.16664 12.0007 6.16664H13.4607L9.83398 2.53997Z"
        fill="#292D32"
      />
    </svg>
  )
}

export default DownloadPdfIcon
