import React, { useEffect } from "react"
import queryString from "query-string"
import { Form, FormInstance, TreeSelect } from "antd"

import SelectSuffixIcon from "../../assets/icons/SelectSuffixIcon"
import { useGetNeighborhoodSelect } from "../../features/app/services/queries"
import Spinner from "../spinner/Spinner"

import styles from "./treeSelectNeighborhoods.module.scss"

type Props = {
  enabled: boolean
  districtIds: number[] | undefined
  formInstance: FormInstance
  initialData?: string[]
  endpoint: string
}

const TreeSelectNeighborhoods: React.FC<Props> = ({ enabled, districtIds, formInstance, initialData, endpoint }) => {
  const { data, isLoading } = useGetNeighborhoodSelect(enabled, districtIds, endpoint)

  // initial data
  useEffect(() => {
    if (initialData && data) {
      formInstance.setFieldsValue({
        neighborhoods: initialData,
      })
    }
  }, [initialData, data])

  // tree data
  const treeData = data?.map(neighborhood => {
    return {
      title: neighborhood.name,
      disabled: "selected" in neighborhood && !neighborhood.selected,
      value: queryString.stringify({
        name: neighborhood.name,
        key: "neighborhood_ids",
        id: neighborhood.id,
      }),
      children: neighborhood.streets?.map(street => {
        return {
          title: street.name,
          disabled: "selected" in street && !street.selected,
          value: queryString.stringify({
            name: street.name,
            key: "street_ids",
            id: street.id,
          }),
          children: street.bloks?.map(item => {
            return {
              title: item.name,
              disabled: "selected" in item && !item.selected,
              value: queryString.stringify({
                name: item.name,
                key: "blok_ids",
                id: item.id,
              }),
            }
          }),
        }
      }),
    }
  })


  return (
    <Form.Item
      label="Mahalla, Ko’cha, Ko’p qavatli bino"
      name="neighborhoods"
      rules={[{ required: true, message: "" }]}
    >
      <TreeSelect
        treeData={treeData}
        treeCheckable={true}
        className={styles.tree_select}
        suffixIcon={(isLoading && districtIds) ? <Spinner /> : <SelectSuffixIcon />}
        showCheckedStrategy={TreeSelect.SHOW_ALL}
        rootClassName={styles.tree_select}
      />
    </Form.Item>
  )
}

export default TreeSelectNeighborhoods