import React from "react"

import PlusIcon from "../../../../../assets/icons/PlusIcon"
import ProblemClassModal from "../modal/ProblemClassModal"
import { useAppDispatch } from "../../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../../store/reducers/regionProblemReducer"

import styles from "../../../index.module.scss"

type Props = {
  isLoading: boolean
}

const ProblemClassCreate: React.FC<Props> = ({ isLoading }) => {
  const dispatch = useAppDispatch()
  const { setProblemClassModalData: setModalData } = regionProblemReducerActions

  // open problem-accident-modal
  const handleOpenModal = () => {
    dispatch(setModalData({ visible: true }))
  }

  return (
    <>
      {!isLoading && (
        <div
          className={`${styles.create} ${styles.class}`}
          onClick={handleOpenModal}
        >
          <PlusIcon />
          <p>Muammo sinfi qo’shish</p>
        </div>
      )}
      <ProblemClassModal />
    </>
  )
}

export default ProblemClassCreate
