import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/endpoints"
import { UserModel } from "../../../utils/models/userModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { LocalStorage } from "../../../services/LocalStorage"
import { USER } from "../../../utils/constants/localStorageKeys"

// get profile
export const useGetProfile = () => {
  return useQuery<UserModel, ErrorRes>(
    [queryKeys.PROFILE_VIEW],
    async () => {
      const res = await $api.get(endpoints.PROFILE_VIEW)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      onError: errorHandler,
      onSuccess: (res) => {
        LocalStorage.set(USER, {
          role: res.role,
          id: res.id,
          full_name: res.full_name,
          image: res.image,
          login: res.login,
          phone: res.phone,
        })
      },
    },
  )
}