import { CityFormFields } from "./../utils/models/adminFormFields"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/endPoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { AdminModel } from "../utils/models/adminModel"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// create admin
export function useCreateAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, Omit<AdminModel, "id" | "status">>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_ADMIN_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_CITIES_VIEW])
      },
      onError: errorHandler,
    },
  )
}

//create city
export function useCreateCity() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, CityFormFields>(async (req) => {
      const res = await $api.post(endpoints.REGION_CITY_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_CITIES_VIEW])
      },
      onError: errorHandler,
    })
}

//update city
export function useUpdateCity() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, CityFormFields & { id: number }>(async (req) => {
      const res = await $api.put(endpoints.REGION_CITY_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_CITIES_VIEW])
      },
      onError: errorHandler,
    })
}

// update admin
export function useUpdateAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, Omit<AdminModel, "status" | "city_id">>(
    async (req) => {
      const res = await $api.put(endpoints.REGION_ADMIN_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_CITIES_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete admin
export function useDeleteAdmin() {
  const qc = useQueryClient()
  return useMutation(async (id: number) => {
      const res = await $api.delete(`${endpoints.ADMIN_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_CITIES_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete city
export function useDeleteCity() {
  const qc = useQueryClient()
  return useMutation(async (id: number) => {
      const res = await $api.delete(`${endpoints.REGION_CITY_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_CITIES_VIEW])
      },
      onError: errorHandler,
    },
  )
}
