import { Button, Form, Modal, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { useGetDetailedTrouble } from "../../services/queries"
import { chatReducerActions } from "../../../../store/reducers/chatReducer"
import { parseUnix } from "../../../../utils/helpers/parseUnix"
import { problemStatusLabels } from "../../../../utils/constants/problemStatusLabels"
import { problemStatuses } from "../../../../utils/enums/problemStatuses"
import Images from "./images/Images"
import { isEmptyArray } from "../../../../utils/helpers/isEmptyArray"
import { regionTroubleReducerActions } from "../../../../store/reducers/regionTroubleReducer"

import styles from "./problemModal.module.scss"
import dayjs from "dayjs"
import { dayjsFormats } from "../../../../utils/constants/dayjsFormats"
import {
  useGetRegionCitySelectAll,
  useGetRegionMainOrganizationSelectAll,
  useGetRegionOrganizationSelectAll,
} from "../../../region-problems/services/queries"
import { problemTypes } from "../../../../utils/enums/problemTypes"
import { useUpdateRegionProblem } from "../../services/mutation"

const { Option } = Select

const ProblemModal = () => {
  const [form] = Form.useForm()
  const { modalData } = useAppSelector((state) => state.regionTroubleReducer)
  const { data, isLoading } = useGetDetailedTrouble(modalData?.id)
  const [activeOrg, setActiveOrg] = useState<string | undefined>()
  const [orgName, setOrgName] = useState<string | number | null>()
  const dispatch = useAppDispatch()
  const { setModalData } = regionTroubleReducerActions
  const { setChats } = chatReducerActions
  const { data: selectAllOrg } = useGetRegionOrganizationSelectAll()
  const { data: selectAllMainOrg } = useGetRegionMainOrganizationSelectAll()
  const { data: selectAllCity } = useGetRegionCitySelectAll()

  const updateRegionProblem = useUpdateRegionProblem()

  const selectData =
    activeOrg == "organization"
      ? selectAllOrg
      : activeOrg == "main_organization"
      ? selectAllMainOrg
      : activeOrg == "city"
      ? selectAllCity
      : null

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    dispatch(setChats([]))
  }

  const onFinish = (val: { type: string; name: number }) => {
    const test = {
      [`${val?.type}_id`]: Number(val?.name),
      id: Number(modalData?.id),
    }
    updateRegionProblem.mutateAsync(test).then(handleCancel)
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        ...data,
        name:
          data?.type === "organization"
            ? data?.organization?.name
            : data?.type === "main_organization"
            ? data?.main_organization?.name
            : data?.city?.name,
        type: data?.type === "unknown" ? "Tashkilot nomini tanlang..." : data?.type,
      })
      setActiveOrg(data?.type)
    }
  }, [modalData, data])

  const activeSubOrg: any = data ? data[data?.type as keyof typeof data] : ""
  const currentSubOrgId = selectData?.find(
    (item: any) => item?.name === form.getFieldValue("name")
  )?.id

  return (
    <Modal
      centered
      width={994}
      footer={false}
      onCancel={handleCancel}
      open={modalData?.visible}
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      className={styles.modal_root}
    >
      <Spin spinning={isLoading}>
        <div className={styles.modal}>
          <div className={styles.modal_header}>
            <p>{data?.problem?.name}</p>
            <span className={`status_${data?.status}`}>
              {problemStatusLabels[data?.status as problemStatuses]}
            </span>
          </div>
          <div className={styles.modal_body}>
            <div className={styles.modal_body_left}>
              <div className={styles.info}>
                <div className={styles.info_item}>
                  <span>Yaratilgan vaqti</span>
                  <p>{data?.created_at ? parseUnix(data?.created_at) : "-"}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Fuqaro</span>
                  <p>{data?.citizen?.full_name}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Telefon raqami</span>
                  <p>{data?.citizen?.phone}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Manzil</span>
                  <p>{data?.address}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Izoh</span>
                  <p>{data?.text}</p>
                </div>
                {!isEmptyArray(data?.images) && <Images images={data?.images} />}
              </div>
              <div className={styles.info}>
                <div className={styles.info_item}>
                  <span>Ijrochi</span>
                  <span>
                    {data?.organization_user?.full_name ? (
                      <>
                        <p>{data?.organization_user?.full_name}</p>
                        <p>{data?.organization_user?.phone}</p>
                      </>
                    ) : data?.subsidiary_user?.full_name ? (
                      <>
                        <p>{data?.subsidiary_user?.full_name}</p>
                        <p>{data?.subsidiary_user?.phone}</p>
                      </>
                    ) : data?.main_organization_user ? (
                      <>
                        <p>{data?.main_organization_user?.full_name}</p>
                        <p>{data?.main_organization_user?.phone}</p>
                      </>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
                {data?.deadline && (
                  <div className={styles.info_item}>
                    <span>Muddat</span>
                    <p>
                      {dayjs
                        .unix(data?.deadline)
                        .format(`${dayjsFormats.DATE} ${dayjsFormats.TIME}`)}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.info}>
                <div className={styles.status}>
                  <p>Holat</p>
                  <span className={`status_${data?.status}`}>
                    {problemStatusLabels[data?.status as problemStatuses]}
                  </span>
                </div>
                {data?.comment && (
                  <div className={styles.info_item}>
                    <span>Izoh</span>
                    <p>{data?.comment}</p>
                  </div>
                )}
                {!isEmptyArray(data?.result_images) && (
                  <Images images={data?.result_images} />
                )}
                {(data?.status === problemStatuses.NEW ||
                  data?.type === problemTypes.UNKNOW) && (
                  <div>
                    <Form layout="vertical" onFinish={onFinish} form={form}>
                      <div className={styles.formLeftSide}>
                        <Form.Item
                          label="Topshiriqni yo'naltirish"
                          name={"type"}
                          rules={[{ required: true, message: "" }]}
                        >
                          <Select
                            value={activeOrg}
                            onChange={(e) => {
                              setActiveOrg(e)
                              form.resetFields(["name"])
                            }}
                            placeholder="Tashkilot nomini tanlang..."
                          >
                            <Option key={"organization"}>Tashkilot</Option>
                            <Option key={"main_organization"}>
                              Katta tashkilot
                            </Option>
                            <Option key={"city"}>Shahar/tuman administratori</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label=""
                          name={"name"}
                          // initialValue={data?.organization?.name}
                          rules={[{ required: true, message: "" }]}
                        >
                          <Select
                            placeholder="Tashkilot agentligini tanlang..."
                            value={orgName}
                            onChange={(e) => setOrgName(e)}
                          >
                            {selectData?.map(
                              (item: { id: number; name: string }) => (
                                <Option key={item?.id}>{item?.name}</Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className={styles.submitButton}>
                        <Button onClick={handleCancel}>Bekor qilish</Button>
                        <Button
                          htmlType="submit"
                          loading={updateRegionProblem?.isLoading}
                          disabled={
                            data?.type === activeOrg &&
                            activeSubOrg?.id === currentSubOrgId
                          }
                        >
                          Saqlash
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.modal_body_right}>
              <div className={styles.events}>
                {data?.events
                  ?.slice()
                  ?.reverse()
                  .map((item) => (
                    <div className={styles.events_item} key={item.id}>
                      <span>{item.text}</span>
                      <span>{parseUnix(item?.created_at)}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default ProblemModal
