import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { StatisticsModel } from "../utils/models/statisticsModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"


// get statistics
export const useGetStatistics = () => {
  const url = addQueryParams(endpoints.ORGANIZATION_STATISTICS)

  return useQuery<StatisticsModel, ErrorRes>(
    [queryKeys.ORGANIZATION_STATISTICS, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}