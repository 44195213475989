const CollapseExpandIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.90224 5.19294L3.56913 7.92002C3.52251 7.9582 3.4631 7.98421 3.39841 7.99475C3.33372 8.00529 3.26666 7.99989 3.20572 7.97923C3.14479 7.95857 3.09271 7.92358 3.05609 7.8787C3.01947 7.83381 2.99995 7.78104 3 7.72708V2.27292C2.99995 2.21895 3.01947 2.16619 3.05609 2.1213C3.09271 2.07642 3.14479 2.04143 3.20572 2.02077C3.26666 2.00011 3.33372 1.99471 3.39841 2.00525C3.4631 2.01579 3.52251 2.0418 3.56913 2.07998L6.90224 4.80706C6.93323 4.83239 6.95782 4.86246 6.97459 4.89557C6.99137 4.92868 7 4.96416 7 5C7 5.03584 6.99137 5.07132 6.97459 5.10443C6.95782 5.13754 6.93323 5.16761 6.90224 5.19294Z"
        fill="#667085"
      />
    </svg>
  )
}

export default CollapseExpandIcon
