import AccidentsIcon from "../../assets/icons/AccidentsIcon"
import CitiesIcon from "../../assets/icons/CitiesIcon"
import EmployeesIcon from "../../assets/icons/EmployeesIcon"
import OrganizationsIcon from "../../assets/icons/OrganizationsIcon"
import ProblemsIcon from "../../assets/icons/ProblemsIcon"
import SettingsIcon from "../../assets/icons/SettingsIcon"
import StatisticsIcon from "../../assets/icons/StatisticsIcon"
import { rootPaths } from "../../routing/root/rootPaths"
import { roles } from "../../utils/enums/roles"
import { hasPermission } from "../../utils/helpers/hasPermission"
import { LinkModel } from "../../utils/models/linkModel"
import SubsidiaryIcon from "../../assets/icons/SubsidiaryIcon"
import FeedbacksIcon from "../../assets/icons/FeedbacksIcon"
import ProblemClassIcon from "../../assets/icons/ProblemClassIcon"
import HomeHashtagIcon from "../../assets/icons/HomeHashtagIcon"

const allLinks: LinkModel[] = [
  {
    // region | city_super | organization_super
    path: rootPaths.STATISTICS,
    icon: StatisticsIcon,
    text: "Statistika",
    allowedRoles: [
      roles.CITY_SUPER_ADMIN,
      roles.ORGANIZATION_SUPER_ADMIN,
      roles.REGION_ADMIN,
    ],
  },
  {
    // region
    path: rootPaths.CITIES,
    icon: CitiesIcon,
    text: "Shahar/tumanlar",
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    // region
    path: rootPaths.MAIN_ORGANIZATIONS,
    icon: HomeHashtagIcon,
    text: "Katta tashkilot",
    allowedRoles: [
      roles.REGION_ADMIN,
    ],
  },
  {
    // region | city_super
    path: rootPaths.ORGANIZATIONS,
    icon: OrganizationsIcon,
    text: "Tashkilotlar",
    allowedRoles: [roles.REGION_ADMIN, roles.CITY_SUPER_ADMIN, roles.MAIN_ORGANIZATION_SUPER_ADMIN],
  },
  {
    // city_super | organization_super | subsidiary_super
    path: rootPaths.EMPLOYEES,
    icon: EmployeesIcon,
    text: "Xodimlar",
    allowedRoles: [roles.CITY_SUPER_ADMIN, roles.ORGANIZATION_SUPER_ADMIN, roles.SUBSIDIARY_SUPER_ADMIN, roles.MAIN_ORGANIZATION_SUPER_ADMIN],
  },
  {
    // city_super | city | organization_super | organization
    path: rootPaths.ACCIDENTS,
    icon: AccidentsIcon,
    text: "Avariyalar",
    allowedRoles: [
      roles.REGION_ADMIN,
    ],
  },
  {
    // region | city_super | city | organization_super | organization | subsidiary_super | subsidiary
    path: rootPaths.PROBLEMS,
    icon: ProblemsIcon,
    text: "Muammolar",
    allowedRoles: [
      roles.REGION_ADMIN,
      roles.CITY_SUPER_ADMIN,
      roles.CITY_ADMIN,
      roles.ORGANIZATION_SUPER_ADMIN,
      roles.ORGANIZATION_ADMIN,
      roles.SUBSIDIARY_SUPER_ADMIN,
      roles.SUBSIDIARY_ADMIN,
      roles.MAIN_ORGANIZATION_SUPER_ADMIN,
      roles.MAIN_ORGANIZATION_ADMIN
    ],
  },
  {
    // region
    path: rootPaths.FEEDBACKS,
    icon: FeedbacksIcon,
    text: "Feedback",
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    // region
    path: rootPaths.PROBLEM_CLASS,
    icon: ProblemClassIcon,
    text: "Muammo sinfi",
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    // organization | organization_super
    path: rootPaths.SUBSIDIARIES,
    icon: SubsidiaryIcon,
    text: "Shirkatlar",
    allowedRoles: [
      roles.ORGANIZATION_SUPER_ADMIN,
    ],
  },
  {
    // region
    path: rootPaths.SETTINGS,
    icon: SettingsIcon,
    text: "Sozlamalar",
    allowedRoles: [
      roles.REGION_ADMIN,
    ],
  },
]

// filtering
export const rootLinks: LinkModel[] = allLinks.filter((item) => {
  if (item.allowedRoles) {
    return hasPermission(item.allowedRoles)
  }
  return true
})
