import React from "react"

import SettingsCenter from "./components/settings-center/SettingsCenter"
import Navbar from "./components/navbar/Navbar"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  return (
    <div className={styles.settings}>
      <Navbar />
      <SettingsCenter />
    </div>
  )
}

export default Index
