import React, { useMemo } from "react"
import { Table, Tabs, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { useNavigate } from "react-router-dom"
import queryString from "query-string"

import BodyLayout from "../../common/body-layout/BodyLayout"
import RejectModal from "./components/reject-modal/RejectModal"
import CompleteModal from "./components/complete-modal/CompleteModal"
import { useGetProblems } from "./services/queries"
import { ProblemModel } from "./utils/models/problemModel"
import { problemStatuses } from "../../utils/enums/problemStatuses"
import ProblemActions from "./components/problem-actions/ProblemActions"
import { problemStatusLabels } from "../../utils/constants/problemStatusLabels"
import ProblemModal from "./components/problem-modal/ProblemModal"
import { STATUS } from "../../utils/constants/queryParamsKeys"
import { useAppDispatch } from "../../hooks/redux"
import { subsidiaryProblemReducerActions } from "../../store/reducers/subsidiaryProblemReducer"
import { problemTypes } from "../../utils/enums/problemTypes"

import styles from "./index.module.scss"
import { parseUnix } from "../../utils/helpers/parseUnix"
import dayjs from "dayjs"

const Index: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setModalData } = subsidiaryProblemReducerActions
  const { data, isLoading } = useGetProblems()

  // change tab
  const handleChangeTab = (activeKey: string) => {
    const query: { status?: string } = {}

    if (activeKey) {
      query.status = activeKey
    } else {
      delete query?.status
    }

    navigate({
      search: queryString.stringify(query),
    })
  }

  // is actionable
  const isActionable = (type: problemTypes, status: problemStatuses) => {
    return (
      type === problemTypes.SUBSIDIARY &&
      status !== problemStatuses.CLOSED &&
      status !== problemStatuses.COMPLETED &&
      status !== problemStatuses.DELETED
    )
  }

  // columns
  const columns: ColumnsType<ProblemModel> = [
    {
      title: "Yartilgan sana",
      dataIndex: "created_at",
      render: (value) => parseUnix(value),
    },
    {
      title: "Nomi",
      dataIndex: "problem",
      render: (value) => value.name,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => (
        <span className={`${styles.problems_status} status_${status}`}>
          {problemStatusLabels[status as problemStatuses]}
        </span>
      ),
    },
    {
      title: "Ijrochi",
      render: (record) => {
        if (record.organization_user) {
          return (
            <Tooltip title={record?.organization_user?.phone}>
              <span>{record?.organization_user?.full_name}</span>
            </Tooltip>
          )
        }
        if (record.subsidiary_user) {
          return (
            <Tooltip title={record?.subsidiary_user?.phone}>
              <span>{record?.subsidiary_user?.full_name}</span>
            </Tooltip>
          )
        }
        if (record.main_organization_user) {
          return (
            <Tooltip title={record?.main_organization_user?.phone}>
              <span>{record?.main_organization_user?.full_name}</span>
            </Tooltip>
          )
        }

        return "-"
      },
    },
    {
      title: "Tugatish muddati",
      render: (record) => {
        if (record.deadline) {
          return dayjs.unix(record?.deadline).format("DD.MM.YYYY")
        }

        return "-"
      },
    },
    {
      render: (record) =>
        isActionable(record.type, record.status) && <ProblemActions {...record} />,
    },
  ]

  // active key
  const activeKey = useMemo(() => {
    return (queryString.parse(location.search)[STATUS] as string) ?? ""
  }, [queryString.parse(location.search)[STATUS]])

  // row click
  const handleRowClick = (record: ProblemModel) => {
    dispatch(
      setModalData({
        visible: true,
        id: record.id,
      })
    )
  }

  return (
    <BodyLayout title="Muommolar" pagination={{ total: data?.total }}>
      <Tabs
        onChange={handleChangeTab}
        className={styles.tabs}
        activeKey={activeKey}
        items={[
          { key: "", label: <span>Barchasi</span> },
          { key: problemStatuses.NEW, label: <span>Yangi</span> },
          {
            key: problemStatuses.REJECTED,
            label: <span>Qayta yo'naltirilgan</span>,
          },
          { key: problemStatuses.PROCESS, label: <span>Jarayonda</span> },
          { key: problemStatuses.COMPLETED, label: <span>Yakunlangan</span> },
          { key: problemStatuses.CLOSED, label: <span>Tasdiqlangan</span> },
          { key: problemStatuses.USER_REJECTED, label: <span>Tasdiqlanmagan</span> },
          {
            key: problemStatuses.DELETED,
            label: <span>Muvaffaqiyatsiz yakunlangan</span>,
          },
        ]}
      />
      <Table
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        loading={isLoading}
        rowKey={(record) => record.id}
        className={styles.problems}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record),
          }
        }}
      />
      <RejectModal />
      <CompleteModal />
      <ProblemModal />
    </BodyLayout>
  )
}

export default Index
