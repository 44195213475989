import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { sliceNames } from "../../utils/constants/sliceNames"
import { EmployeeModel } from "../../features/city-organizations/utils/models/employeeModel"
import { OrganizationModel } from "../../features/city-organizations/utils/models/organizationModel"

const initialState: {
  visibleAttachModal: boolean
  organizationModalData: {
    visible: boolean
    organizationId?: number,
    data?: OrganizationModel
  }
  employeeModalData: {
    visible: boolean
    organizationId?: number
    data?: EmployeeModel
  }
} = {
  visibleAttachModal: false,
  organizationModalData: {
    visible: false,
  },
  employeeModalData: {
    visible: false,
  },
}

const mainOrganizationOrganizationReducer = createSlice({
  name: sliceNames.MAIN_ORGANIZATION_ORGANIZATION,
  initialState,
  reducers: {
    setVisibleAttachModal: (state, action: PayloadAction<boolean>) => {
      state.visibleAttachModal = action.payload
    },
    setOrganizationModalData: (state, action: PayloadAction<{
      visible: boolean,
      data?: OrganizationModel,
      organizationId?: number
    }>) => {
      state.organizationModalData = action.payload
    },
    setEmployeeModalData: (state, action: PayloadAction<{
      visible: boolean,
      organizationId?: number,
      data?: EmployeeModel
    }>) => {
      state.employeeModalData = action.payload
    },
  },
})

export default mainOrganizationOrganizationReducer.reducer
export const mainOrganizationOrganizationReducerActions = mainOrganizationOrganizationReducer.actions
