import { Form, Modal, Select } from "antd"
import React from "react"

import CloseIcon from "../../../../assets/icons/CloseIcon"
// import PlusIcon from "../../../../assets/icons/PlusIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
// import {
//   useGetDistrictSelect,
//   useGetRegionSelect,
// } from "../../../app/services/queries"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useGetOrganizationsSelect } from "../../services/queries"
import { useAttachOrganization } from "../../services/mutations"
import SmallCheckIcon from "../../../../assets/icons/SmallCheckIcon"
import { cityOrganizationReducerActions } from "../../../../store/reducers/cityOrganizationReducer"

import styles from "./organizationAttachModal.module.scss"

const OrganizationAttachModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setVisibleAttachModal } =
    cityOrganizationReducerActions
  const { visibleAttachModal } = useAppSelector(
    (state) => state.cityOrganizationReducer,
  )
  // const [activeRegion, setActiveRegion] = useState<number | undefined>()
  // const { data: regions, isLoading: regionsIsLoading } =
  //   useGetRegionSelect(visibleAttachModal)
  // const { data: districts, isLoading: districtsIsLoading } =
  //   useGetDistrictSelect(activeRegion)
  const { data: organizations, isLoading: organizationsIsloading } =
    useGetOrganizationsSelect(visibleAttachModal)
  // const [activeOrganization, setActiveOrganization] = useState<number>()
  const attachOrganization = useAttachOrganization()

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setVisibleAttachModal(false))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    // setActiveOrganization(undefined)
    // setActiveRegion(undefined)
  }

  // open organization problem-accident-modal
  // const handleOpenOrganizationModal = () => {
  //   dispatch(setOrganizationModalData({
  //     visible: true,
  //   }))
  //   handleCancel()
  // }

  // change region
  // const handleChangeRegion = (value: number) => {
  //   setActiveRegion(value)
  //   form.resetFields(["district_id"])
  // }

  // change organization
  // const handleChangeOrganization = (value: number) => {
  //   // const activeOrganization = organizations?.find((item) => item.id === value)

  //   // setActiveOrganization(value)
  //   // setActiveRegion(activeOrganization?.region_id)
  //   // form.setFieldsValue({
  //   //   region_id: activeOrganization?.region_id,
  //   //   district_id: activeOrganization?.district_id,
  //   // })
  // }

  // finish
  const onFinish = (fields: { organization_id: number }) => {
    attachOrganization.mutateAsync({ organization_id: fields?.organization_id }).then(handleCancel)
  }

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title="Tashkilot biriktirish"
      onCancel={handleCancel}
      open={visibleAttachModal}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      okButtonProps={{
        size: "large",
        loading: attachOrganization.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Tashkilot"
          name="organization_id"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            loading={organizationsIsloading}
            popupClassName={styles.organization_popup}
            suffixIcon={<SelectSuffixIcon />}
          // onChange={handleChangeOrganization}
          // dropdownRender={(menu) => {
          //   return (
          //     <>
          //       <div
          //         className={`${styles.create_item} ant-select-item`}
          //         onClick={handleOpenOrganizationModal}
          //       >
          //         <PlusIcon />
          //         <span>Tashkilot qo’shish</span>
          //       </div>
          //       {menu}
          //     </>
          //   )
          // }}
          >
            {organizations?.map((item) => (
              <Select.Option
                key={item.id}
                value={item.id}
                disabled={item.my_organization}
              >
                <div className="d-f ai-c jc-sb">
                  <span>{item.name}</span>
                  {item.my_organization && <SmallCheckIcon />}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* {activeOrganization && (
          <>
            <Form.Item
              name="region_id"
              label="Viloyat"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                disabled
                loading={regionsIsLoading}
                onChange={handleChangeRegion}
                suffixIcon={<SelectSuffixIcon />}
              >
                {regions?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="district_id"
              label="Tuman/shahar"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                disabled
                loading={districtsIsLoading}
                suffixIcon={<SelectSuffixIcon />}
              >
                {districts?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )} */}
      </Form>
    </Modal>
  )
}

export default OrganizationAttachModal
