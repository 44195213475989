import { Button, Input } from "antd"
import React, { useState } from "react"

import {
  useDeleteEvolutionVariant,
  useUpdateEvolutionVariant,
} from "../../services/mutations"
import { EvolutionVariantsModel } from "../../utils/models/EvolutionModel"
import DeleteIcon from "../../../../assets/icons/DeleteIcon"
import Spinner from "../../../../common/spinner/Spinner"
import SmallCheckIcon from "../../../../assets/icons/SmallCheckIcon"

import styles from "./settingsCenter.module.scss"

type Props = {
  data: EvolutionVariantsModel
}

const SettingsChildInput: React.FC<Props> = ({ data }) => {
  const text = data?.name
  const [inputVal, setInputVal] = useState<string>(text)
  const deleteEvalutionVariant = useDeleteEvolutionVariant()
  const updateEvolution = useUpdateEvolutionVariant()

  const handleUpdate = (value: { id: number; name: string }) => {
    if (!updateEvolution.isLoading) {
      updateEvolution.mutate({ id: value.id, name: value.name })
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value)
  }

  const handleDelete = (id: number) => {
    if (!deleteEvalutionVariant.isLoading) {
      return deleteEvalutionVariant.mutate(id)
    }
  }
  return (
    <div className={styles.create}>
      <Input value={inputVal} onChange={handleChange} />
      {text !== inputVal && (
        updateEvolution.isLoading ? (
          <Spinner />
        ) : (
          <Button type="primary" className={styles.update}
                  onClick={() => handleUpdate({ id: data?.id, name: inputVal })}>
            <SmallCheckIcon />
          </Button>
        )
      )}
      <Button type="link" className={styles.delete} onClick={() => handleDelete(data?.id)}>
        {deleteEvalutionVariant.isLoading ? (
          <Spinner />
        ) : (
          <DeleteIcon />
        )}
      </Button>
    </div>
  )
}

export default SettingsChildInput
