import { Checkbox, Form, Input, Modal, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { subsidiaryReducerActions } from "../../../../store/reducers/subsidiaryReducer"
import {
  useCreateSubsidiaryAdmin,
  useUpdateSubsidiaryAdmin,
} from "../../services/mutation"
import { SubsidiaryAdminModel } from "../../utils/models/subsidiaryAdminModel"

import styles from "./subsidiaryModal.module.scss"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"
import { roles } from "../../../../utils/enums/roles"

const SubsidiaryModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { subsidiaryModalData: modalData } = useAppSelector(
    (state) => state.subsidiaryReducer,
  )

  const { setSubsidiary } = subsidiaryReducerActions
  const createSubsidiaryAdmin = useCreateSubsidiaryAdmin()
  const updateSubsidiaryAdmin = useUpdateSubsidiaryAdmin()
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  // is updating
  const isUpdating = useMemo(() => {
    return !!modalData.data
  }, [modalData.data])

  //handleCancel
  const handleCancel = () => {
    dispatch(
      setSubsidiary({
        visible: false,
      }),
    )
  }

  //onFinish
  const onFinish = (values: SubsidiaryAdminModel) => {
    const req = { ...values, phone: parsePhoneNumber(values.phone) }

    if (modalData.data) {
      updateSubsidiaryAdmin
        .mutateAsync({ ...req, id: modalData?.data?.id })
        .then(handleCancel)
    } else {
      createSubsidiaryAdmin.mutateAsync(req).then(handleCancel)
    }
  }

  //handleSubmit
  const handleSubmit = () => {
    form.submit()
  }

  //handleAfterClose
  const handleAfterClose = () => {
    form.resetFields()
    setIsChangingPassword(false)
  }

  useEffect(() => {
    if (modalData.data) {
      form.setFieldsValue({
        ...modalData?.data,
        id: modalData?.data?.id,
      })
    }
  }, [modalData.data, createSubsidiaryAdmin])

  // change is updating password
  const handleChangeIsUpdatingPassword = (e: CheckboxChangeEvent) => {
    setIsChangingPassword(e.target.checked)
  }

  // title
  const title = useMemo(() => {
    if (modalData.data) return "Xodim tahrirlash"
    return "Xodim qo'shish"
  }, [modalData.data])

  return (
    <Modal
      title={title}
      closeIcon={<CloseIcon />}
      open={modalData.visible}
      onCancel={handleCancel}
      okText="Saqlash"
      cancelText="Bekor qilish"
      className={styles.modal}
      centered
      okButtonProps={{
        onClick: handleSubmit,
        loading: createSubsidiaryAdmin.isLoading || updateSubsidiaryAdmin.isLoading,
      }}
      afterClose={handleAfterClose}
    >
      <Form
        layout="vertical"
        className={styles.form}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="To'liq ismi"
          name={"full_name"}
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <PhoneInput name="phone" label="Telefon raqam" required={true} />
        <Form.Item
          label="Lavozimi"
          name={"role"}
          rules={[{ required: true, message: "" }]}
        >
          <Select>
            <Select.Option value={roles.SUBSIDIARY_SUPER_ADMIN}>
              Admin
            </Select.Option>
            <Select.Option value={roles.SUBSIDIARY_ADMIN}>
              Xodim
            </Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.form_login}>
          <Form.Item
            label="Login"
            name={"login"}
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          {(isChangingPassword || !isUpdating) && (
            <Form.Item
              label="Parol"
              name={"password"}
              rules={[{ required: true, message: "" }]}
            >
              <Input.Password />
            </Form.Item>
          )}
        </div>
        {isUpdating && (
          <Form.Item className={styles.change_password}>
            <Checkbox checked={isChangingPassword} onChange={handleChangeIsUpdatingPassword}>Parolni
              yangilash</Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default SubsidiaryModal
