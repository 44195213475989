export const endpoints = {
  CITY_ORGANIZATION_VIEW: "/city/organization/view",
  CITY_ORGANIZATION_SELECT: "/city/organization/select",
  CITY_ORGANIZATION_ATTACH: "/city/organization/attach",
  CITY_ORGANIZATION_CREATE: "/city/organization/create",
  CITY_ORGANIZATION_UPDATE: "/city/organization/update",
  CITY_ORGANIZATION_DELETE: "/city/organization/delete",
  PROBLEMS_TREE_SELECT: "/city/organization/select/problem",
  NEIGHBORHOODS_TREE_SELECT: "/city/organization/select/address",
  CITY_EMPLOYEE_CREATE: "/city/organization/admin/create",
  CITY_EMPLOYEE_UPDATE: "/city/organization/admin/update",
  CITY_EMPLOYEE_DELETE: "/city/organization/admin/delete",
  CITY_TAG_VIEW: "/city/tag/view",
  CITY_TAG_CREATE: "/city/tag/create",
  CITY_TAG_DELETE: "/city/tag/delete",
}
