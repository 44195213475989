import React from "react"
import { Spin } from "antd"

import { useEvolutionViews } from "../../services/queries"
import SenterChild from "./SenterChild"

import styles from "./settingsCenter.module.scss"

const SettingsCenter = () => {
  const { data: evolutionView, isLoading } = useEvolutionViews()

  return (
    <Spin spinning={isLoading}>
      <div className={styles.settingsCenter}>
        <div className={styles.settingsCenter_body}>
          <div className={styles.settingsCenter_body_header}>
            <span>Baholash sozlamasi</span>
          </div>
          <div className={styles.settingsCenter_body_main}>
            {evolutionView?.map((data) => (
              <SenterChild key={data?.id} isLoading={isLoading} data={data} />
            ))}
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default SettingsCenter
