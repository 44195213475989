import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { SubsidiaryAdminModel } from "../utils/models/subsidiaryAdminModel"
import { queryKeys } from "../utils/constants/queryKeys"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

export function useCreateSubsidiaryAdmin() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, SubsidiaryAdminModel>(
    async (req) => {
      const res = await $api.post(endpoints.SUBSIDIARY_ADMIN_CREATE, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.SUBSIDIARY_ADMIN_VIEW])
      },
      onError: errorHandler,
    })
}

export function useUpdateSubsidiaryAdmin() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, SubsidiaryAdminModel>(
    async (req) => {
      const res = await $api.put(endpoints.SUBSIDIARY_ADMIN_UPDATE, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.SUBSIDIARY_ADMIN_VIEW])
      },
      onError: errorHandler,
    })
}

export function useDeleteSubsidiaryAdmin() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.SUBSIDIARY_ADMIN_DELETE}?id=${id}`)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.SUBSIDIARY_ADMIN_VIEW])
      },
      onError: errorHandler,
    })
}