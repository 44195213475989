import { Form, Input, Modal, Upload } from "antd"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import React, { useEffect, useMemo, useState } from "react"

import CloseIcon from "../../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../../store/reducers/regionProblemReducer"
import { STRING } from "../../../../../utils/constants/types"
import {
  useCreateProblemClass,
  useUpdateProblemClass,
} from "../../../services/mutations"
import { ProblemClassFormFields } from "../../../utils/models/problemClassFormFields"

import styles from "./problemClassModal.module.scss"

const ProblemClassModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const createProblemClass = useCreateProblemClass()
  const updateProblemClass = useUpdateProblemClass()
  const [image, setImage] = useState<Blob | undefined | string>()
  const { problemClassModalData: modalData } = useAppSelector(
    (state) => state.regionProblemReducer,
  )
  const { setProblemClassModalData: setModalData } = regionProblemReducerActions

  // initial form fields
  useEffect(() => {
    if (modalData.data) {
      form.setFieldsValue({
        ...modalData.data,
      })
      setImage(modalData.data.image)
    }
  }, [modalData.data, form])

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    setImage(undefined)
  }

  // change
  const handleChange = (e: UploadChangeParam<UploadFile>) => {
    setImage(e?.file?.originFileObj)
  }

  // ok
  const handleOk = () => {
    form.submit()
  }

  // handle file from event
  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  // finish
  const onFinish = (fields: ProblemClassFormFields) => {
    const formData = new FormData()
    let key: keyof typeof fields

    // remove image
    if (typeof fields.image === STRING) delete fields.image

    for (key in fields) {
      if (fields[key]) formData.append(key, fields[key] as string | Blob)
    }

    if (modalData.data) {
      formData.append("id", String(modalData.data.id))
      updateProblemClass.mutateAsync(formData).then(handleCancel)
    } else {
      createProblemClass.mutateAsync(formData).then(handleCancel)
    }
  }

  // image source
  const imageSource = () => {
    if (typeof image === STRING) return image as string
    return URL.createObjectURL(image as Blob)
  }

  // title
  const title = useMemo(() => {
    if (modalData.data) return "Muammo sinfi tahrirlash"
    return "Muammo sinfi yaratish"
  }, [modalData.data])

  return (
    <Modal
      centered
      width={512}
      okText="Saqlash"
      onOk={handleOk}
      title={title}
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      okButtonProps={{
        size: "large",
        loading: createProblemClass.isLoading || updateProblemClass.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form
        layout="vertical"
        className={styles.form}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="O'zbekcha nomi"
          name="name_uz"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Ruscha nomi"
          name="name_ru"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Rang" name="color">
          <input type="color" />
        </Form.Item>
        <Form.Item
          label="Belgisi"
          name="image"
          getValueFromEvent={handleFileFromEvent}
          rules={[{ required: true, message: "" }]}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={() => null}
            onChange={handleChange}
            accept="image/*"
          >
            {image ? (
              <img alt="" src={imageSource()} />
            ) : (
              <div className="support">
                <p>Yuklash uchun bosing</p>
                <span>PNG, JPG, yoki SVG (maksimal 5MB)</span>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ProblemClassModal
