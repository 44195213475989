export const endpoints = {


  REGION_MAIN_ORGANIZATION_VIEW: "/region/main-organization/view",

  REGION_MAIN_ORGANIZATION_CREATE: "/region/main-organization/create",
  REGION_MAIN_ORGANIZATION_DELETE: "/region/main-organization/delete",
  REGION_MAIN_ORGANIZATION_UPDATE: "/region/main-organization/update",

  REGION_MAIN_ORGANIZATION_ADMIN_CREATE: "/region/main-organization/admin/create",
  REGION_MAIN_ORGANIZATION_ADMIN_UPDATE: "/region/main-organization/admin/update",
  REGION_MAIN_ORGANIZATION_ADMIN_DELETE: "/region/main-organization/admin/delete",

  ADDRESS_REGION: "/api/address/region",

  REGION_ORGANIZATION_PROBLEMS_TREE_SELECT: "/region/organization/select/problem",

  REGION_TAG_VIEW: "/region/tag/view",
  REGION_TAG_CREATE: "/region/tag/create",
  REGION_TAG_DELETE: "/region/tag/delete",
}
