import React from "react"

type IProps = {
  onClick?: () => void
}

const EditIcon: React.FC<IProps> = ({ onClick }) => {

  // click
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      <path
        d="M2.39668 15.5964C2.43497 15.2518 2.45411 15.0795 2.50624 14.9185C2.55249 14.7756 2.61784 14.6397 2.70051 14.5143C2.79369 14.373 2.91627 14.2504 3.16142 14.0053L14.1667 3.00005C15.0871 2.07957 16.5795 2.07957 17.5 3.00005C18.4205 3.92052 18.4205 5.41291 17.5 6.33338L6.49475 17.3386C6.2496 17.5838 6.12702 17.7063 5.98572 17.7995C5.86035 17.8822 5.72439 17.9475 5.58152 17.9938C5.42048 18.0459 5.24819 18.0651 4.90362 18.1033L2.08331 18.4167L2.39668 15.5964Z"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
