import { queryKeys } from "../utils/constants/queryKeys"
import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endPoints"
import { MainOrganizationModel } from "../utils/models/mainOrganizationModel"
import { Pagination } from "../../../utils/models/paginationType"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"
import { DetailedMainOrganizationModel } from "../utils/models/detailedMainOrganizationModel"

// get cities
export function useGetMainOrganization() {
  const url = addQueryParams(endpoints.REGION_MAIN_ORGANIZATION_VIEW)

  return useQuery<Pagination<MainOrganizationModel>, ErrorRes>(
    [queryKeys.REGION_MAIN_ORGANIZATION_VIEW, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    }, {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    })
}

// get tags
export const useGetTags = () => {
  return useQuery<{ id: number, name: string }[], ErrorRes>(
    [queryKeys.REGION_TAGS],
    async () => {
      const res = await $api.get(endpoints.REGION_TAG_VIEW)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get detailed main organization
export function useGetDetailedMainOrganization(id: number | undefined) {
  return useQuery<DetailedMainOrganizationModel, ErrorRes>(
    [queryKeys.REGION_DETAILED_MAIN_ORGANIZATION, id],
    async () => {
      const res = await $api.get(`${endpoints.REGION_MAIN_ORGANIZATION_VIEW}?id=${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: errorHandler,
    },
  )
}