export const endpoints = {

  REGION_PROBLEM_CLASS_VIEW: "/region/problem/class/view",
  REGION_PROBLEM_CLASS_CREATE: "/region/problem/class/create",
  REGION_PROBLEM_CLASS_UPDATE: "/region/problem/class/update",
  REGION_PROBLEM_CLASS_DELETE: "/region/problem/class/delete",
  REGION_PROBLEM_CLASS_STATUS: "/region/problem/class/status",

  REGION_PROBLEM_TYPE_CREATE: "/region/problem/type/create",
  REGION_PROBLEM_TYPE_UPDATE: "/region/problem/type/update",
  REGION_PROBLEM_TYPE_DELETE: "/region/problem/type/delete",
  REGION_PROBLEM_TYPE_STATUS: "/region/problem/type/status",

  REGION_PROBLEM_CREATE: "/region/problem/create",
  REGION_PROBLEM_UPDATE: "/region/problem/update",
  REGION_PROBLEM_DELETE: "/region/problem/delete",
  REGION_PROBLEM_STATUS: "/region/problem/status",

  REGION_ORGANIZATION_SELECT_ALL: "/region/organization/select-all",
  REGION_MAIN_ORGANIZATION_SELECT_ALL: "/region/main-organization/select-all",
  REGION_CITY_SELECT_ALL: "/region/city/select-all",
}
