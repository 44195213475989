import { Collapse, Switch } from "antd"
import React from "react"

import CollapseExpandIcon from "../../../../assets/icons/CollapseExpandIcon"
import FolderIcon from "../../../../assets/icons/FolderIcon"
import { ProblemClassModel } from "../../utils/models/problemClassModel"
import ProblemTypeCreate from "../problem-type/create/ProblemTypeCreate"
import ProblemType from "../problem-type/ProblemType"
import ProblemClassActions from "./actions/ProblemClassActions"
import { useChangeStatusProblem } from "../../services/mutations"
import { endpoints } from "../../utils/constants/endpoints"

import styles from "./problemClass.module.scss"

const ProblemClass: React.FC<ProblemClassModel> = (props) => {
  const { id, name, image, problem_types, problem_types_count, status } = props
  const changeStatusProblem = useChangeStatusProblem()

  // stop propagation
  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
  }

  // change status
  const handleChangeStatus = () => {
    changeStatusProblem.mutate({ id: id, endpoint: endpoints.REGION_PROBLEM_CLASS_STATUS })
  }

  return (
    <Collapse
      ghost
      className={styles.class}
      expandIcon={() => <CollapseExpandIcon />}
    >
      <Collapse.Panel
        className="class"
        key={id}
        header={
          <div className={styles.class_header}>
            <div className={styles.class_header_left}>
              <FolderIcon />
              <div className={styles.image}>
                <img alt="" src={image} />
              </div>
              <p>{name}</p>
            </div>
            <div className={styles.class_header_right}>
              <div onClick={handleStopPropagation}>
                <Switch onClick={handleChangeStatus} checked={status}
                        loading={changeStatusProblem.isLoading}></Switch>
              </div>
              <span>{problem_types_count} muammo</span>
              <ProblemClassActions {...props} />
            </div>
          </div>
        }
      >
        {problem_types.map((item) => (
          <ProblemType key={item.id} {...item} />
        ))}
        <ProblemTypeCreate classId={id} />
      </Collapse.Panel>
    </Collapse>
  )
}

export default ProblemClass
