import React from "react"
import { Modal } from "antd"

import StatisticsEvaluations from "../StatisticsEvaluations"
import { StatisticsEvaluationsModel } from "../../../../utils/models/statisticsEvaluationsModel"
import CloseIcon from "../../../../assets/icons/CloseIcon"

import styles from "../statisticsEvaluations.module.scss"

type Props = {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  evaluations: StatisticsEvaluationsModel | undefined
}

const StatisticsEvaluationsModal: React.FC<Props> = ({
  visible,
  setVisible,
  evaluations,
}) => {
  // cancel
  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      centered
      width={1113}
      open={visible}
      footer={false}
      onCancel={handleCancel}
      className={styles.modal}
      closeIcon={<CloseIcon />}
    >
      <StatisticsEvaluations
        evaluations={evaluations}
        isModal={true}
        isLoading={false}
      />
    </Modal>
  )
}

export default StatisticsEvaluationsModal
