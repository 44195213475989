import React, { useState } from "react"
import { Select, Form as AntForm, FormInstance, Checkbox } from "antd"
import { DatePickerProps } from "antd/lib"
import dayjs from "dayjs"

import SelectSuffixIcon from "../../../../../assets/icons/SelectSuffixIcon"
import DatePicker from "../../../../../utils/helpers/datePicker"
import { dayjsFormats } from "../../../../../utils/constants/dayjsFormats"
import { useGetEmployeesSelect, useGetOrganizationsSelect } from "../../../services/queries"
import { ProblemAttachFormFieldsModel } from "../../../utils/models/problemAttachFormFieldsModel"
import { isEmptyArray } from "../../../../../utils/helpers/isEmptyArray"
import { dayjsUnits } from "../../../../../utils/enums/dayjsUnits"
import { useAppSelector } from "../../../../../hooks/redux"
import { roles } from "../../../../../utils/enums/roles"

import styles from "../problemModal.module.scss"

type Props = {
  formInstance: FormInstance
  onFinish: (fields: ProblemAttachFormFieldsModel) => void
  createdAt: number | undefined
}

const Form: React.FC<Props> = ({ formInstance, onFinish, createdAt }) => {
  const { data: employees, isLoading: employeesIsLoading } = useGetEmployeesSelect()
  const { data: organizations, isLoading: organizationsIsloading } = useGetOrganizationsSelect()
  const [required, setRequired] = useState("main_organization_user_id")
  const { user } = useAppSelector(state => state.userReducer)

  // change employee
  const handleChangeEmployee = () => {
    formInstance.resetFields(["organization_id"])
    setRequired("main_organization_user_id")
  }

  // change subsidiary
  const handleChangeSubsidiary = () => {
    formInstance.resetFields(["main_organization_user_id"])
    setRequired("organization_id")
  }

  // disabled date
  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    if (createdAt) {
      return current < dayjs.unix(createdAt).startOf(dayjsUnits.DAY)
        || current > dayjs().add(14, dayjsUnits.DAY).endOf(dayjsUnits.DAY)
        || current < dayjs().startOf(dayjsUnits.DAY)
    }

    return false
  }

  // is super admin
  const isSuperAdmin = user?.role === roles.MAIN_ORGANIZATION_SUPER_ADMIN

  return (
    <AntForm className={styles.form} layout="vertical" form={formInstance} onFinish={onFinish}>
      {isSuperAdmin && (
        <>
          <AntForm.Item
            label=""
            valuePropName="checked"
            name="confirmed"
          >
            <Checkbox>Tasdiqlash</Checkbox>
          </AntForm.Item>
          <AntForm.Item
            label="Xodim biriktirish"
            name="main_organization_user_id"
            rules={[{ required: required === "main_organization_user_id", message: "" }]}
          >
            <Select suffixIcon={<SelectSuffixIcon />} loading={employeesIsLoading} onChange={handleChangeEmployee}>
              {employees?.map(item => (
                <Select.Option value={item.id} key={item.id}>{item.full_name}</Select.Option>
              ))}
            </Select>
          </AntForm.Item>
        </>
      )}
      {!isEmptyArray(organizations) && (
        <AntForm.Item
          label="Tashkilot biriktirish"
          name="organization_id"
          rules={[{ required: required === "organization_id", message: "" }]}
        >
          <Select suffixIcon={<SelectSuffixIcon />} loading={organizationsIsloading} onChange={handleChangeSubsidiary}>
            {organizations?.map(item => (
              <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </AntForm.Item>
      )}
      <AntForm.Item label="Bajarish muddati" name="deadline" rules={[{ required: true, message: "" }]}>
        <DatePicker format={dayjsFormats.DATE} placeholder="" disabledDate={disabledDate} />
      </AntForm.Item>
    </AntForm>
  )
}

export default Form