import React, { ReactNode, useState } from "react"
import { Popover } from "antd"

type Props = {
  content: ReactNode
  children: ReactNode
}

const PopoverActions: React.FC<Props> = ({ content, children }) => {
  const [visible, setVisible] = useState(false)

  // stop propagation
  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
  }

  // close
  const handleClose = () => {
    setVisible(false)
  }

  // open
  const handleOpen = () => {
    setVisible(true)
  }

  return (
    <div onClick={handleStopPropagation}>
      <Popover
        placement="left"
        content={(
          <div
            className="f_div"
            onClick={(e) => {
              handleClose()
              handleStopPropagation(e)
            }}
          >
            {content}
          </div>
        )}
        mouseEnterDelay={60}
        mouseLeaveDelay={0.5}
        open={visible}
        trigger={["click", "hover"]}
        onOpenChange={setVisible}
      >
        <div className="f_div cursor-p" onClick={handleOpen}>
          {children}
        </div>
      </Popover>
    </div>
  )
}

export default PopoverActions