import { Avatar, Checkbox, Form, Input, Modal, Upload } from "antd"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import React, { useEffect, useState } from "react"
import { UserOutlined } from "@ant-design/icons"
import { CheckboxChangeEvent } from "antd/es/checkbox"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import UploadIcon from "../../../../assets/icons/UploadIcon"
import { STRING } from "../../../../utils/constants/types"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"
import { UserModel } from "../../../../utils/models/userModel"
import { useUpdateProfile } from "../../services/mutations"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"

import styles from "./profileModal.module.scss"

type Props = {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  openModal: boolean
  user: UserModel | undefined
}

const ProfileModal: React.FC<Props> = ({ setOpenModal, openModal, user }) => {
  const [form] = Form.useForm()
  const [image, setImage] = useState<Blob | undefined | string>()
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const updateProfile = useUpdateProfile()

  // initial form field
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        ...user,
      })
    }
    setImage(user?.image ?? undefined)
  }, [user])

  // cancel
  const handleCancel = () => {
    setOpenModal(false)
  }

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // change
  const handleChange = (e: UploadChangeParam<UploadFile>) => {
    setImage(e?.file?.originFileObj)
  }

  // image source
  const imageSource = () => {
    if (typeof image === STRING) return image as string
    return URL.createObjectURL(image as Blob)
  }

  // handle file from event
  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  // finish
  const onFinish = (fields: {
    full_name: string
    phone: string
    login: string
    password: string
    image?: string | File
  }) => {
    const formData = new FormData()
    let key: keyof typeof fields

    // remove image
    if (typeof fields.image === STRING) delete fields.image

    for (key in fields) {
      if (fields[key]) formData.append(key, fields[key] as string | Blob)
    }

    // phone
    formData.append("phone", parsePhoneNumber(fields.phone))

    updateProfile.mutateAsync(formData).then(handleCancel)
  }


  // change is updating password
  const handleChangeIsUpdatingPassword = (e: CheckboxChangeEvent) => {
    setIsChangingPassword(e.target.checked)
  }

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      onOk={handleSubmit}
      title="Profil sozlamasi"
      closeIcon={<CloseIcon />}
      cancelText="Bekor qilish"
      okText="Saqlash"
      centered
      okButtonProps={{
        loading: updateProfile.isLoading,
      }}
      className={styles.profileModal}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label="To'liq ismi" name={"full_name"} rules={[{ required: true, message: "" }]}>
          <Input />
        </Form.Item>
        <PhoneInput name="phone" label="Telefon" required={false} />
        <div className={styles.profileAuth}>
          <Form.Item
            label="Login"
            name={"login"}
            rules={[{ required: true, message: "" }, { min: 5, message: "" }]}
          >
            <Input />
          </Form.Item>
          {isChangingPassword && (
            <Form.Item
              label="Password"
              name={"password"}
              rules={[{ required: true, message: "" }, { min: 5, message: "" }]}
            >
              <Input.Password />
            </Form.Item>
          )}
        </div>
        <Form.Item>
          <Checkbox checked={isChangingPassword} onChange={handleChangeIsUpdatingPassword}>Parolni
            yangilash</Checkbox>
        </Form.Item>
        <div className={styles.upload}>
          {image ? (
            <img alt="" src={imageSource()} />
          ) : (
            <Avatar icon={<UserOutlined />} size={64} />
          )}
          <Form.Item name={"image"} getValueFromEvent={handleFileFromEvent}>
            <Upload
              name="avatar"
              listType="picture-card"
              className={styles.upload_avatar}
              showUploadList={false}
              customRequest={() => null}
              onChange={handleChange}
              accept="image/*"
            >
              <div className={styles.upload_avatar_in}>
                <UploadIcon />
                <p>Yuklash uchun bosing</p>
                <span>SVG, PNG yoki JPG (max. 800x400px)</span>
              </div>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default ProfileModal
