// import CityAccidents from "../../features/city-accidents/Index"
import CityEmployees from "../../features/city-employees/Index"
import CityOrganizations from "../../features/city-organizations/Index"
import CityProblems from "../../features/city-problems/Index"
import CityStatistics from "../../features/city-statistics/Index"
// import OrganizationAccidents from "../../features/organization-accidents/Index"
import OrganizationEmployees from "../../features/organization-employees/Index"
import OrganizationProblems from "../../features/organization-problems/Index"
import OrganizationStatistics from "../../features/organization-statistics/Index"
import RegionCities from "../../features/region-cities/Index"
import RegionOrganizations from "../../features/region-organizations/Index"
import RegionProblemClass from "../../features/region-problems/Index"
import RegionProblems from "../../features/region-troubles/Index"
import RegionSettings from "../../features/region-settings/Index"
import RegionStatistics from "../../features/region-statistics/Index"
import RegionFeedbacks from "../../features/region-feedbacks/Index"
import RegionMainOrganizations from "../../features/region-main-organizations/Index"
import RegionAccidents from "../../features/region-accidents/Index"
import SubsidiaryEmployees from "../../features/subsidiary-employees/Index"
import SubsidiaryProblems from "../../features/subsidiary-problems/Index"
import MainOrganizationOrganizations from '../../features/main-organization-organizations/Index'
import MainOrganizationEmployees from '../../features/main-organization-employees/Index'
import MainOrganizationProblems from '../../features/main-organization-problems/Index'
import { roles } from "../../utils/enums/roles"
import { hasPermission } from "../../utils/helpers/hasPermission"
import { RouteModel } from "../../utils/models/routeModel"
import { rootPaths } from "./rootPaths"
import OrganizationSubsidiaries from "../../features/organization-subsidiaries/Index"

const allRoutes: RouteModel[] = [
  // region
  {
    element: RegionStatistics,
    isExact: true,
    path: rootPaths.STATISTICS,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionCities,
    isExact: true,
    path: rootPaths.CITIES,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionOrganizations,
    isExact: true,
    path: rootPaths.ORGANIZATIONS,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionProblems,
    isExact: true,
    path: rootPaths.PROBLEMS,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionProblemClass,
    isExact: true,
    path: rootPaths.PROBLEM_CLASS,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionSettings,
    isExact: true,
    path: rootPaths.SETTINGS,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionFeedbacks,
    isExact: true,
    path: rootPaths.FEEDBACKS,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionMainOrganizations,
    isExact: true,
    path: rootPaths.MAIN_ORGANIZATIONS,
    allowedRoles: [roles.REGION_ADMIN],
  },
  {
    element: RegionAccidents,
    isExact: true,
    path: rootPaths.ACCIDENTS,
    allowedRoles: [roles.REGION_ADMIN],
  },

  // city
  {
    element: CityStatistics,
    isExact: true,
    path: rootPaths.STATISTICS,
    allowedRoles: [roles.CITY_SUPER_ADMIN],
  },
  {
    element: CityEmployees,
    isExact: true,
    path: rootPaths.EMPLOYEES,
    allowedRoles: [roles.CITY_SUPER_ADMIN],
  },
  {
    element: CityOrganizations,
    isExact: true,
    path: rootPaths.ORGANIZATIONS,
    allowedRoles: [roles.CITY_SUPER_ADMIN],
  },
  // {
  //   element: CityAccidents,
  //   isExact: true,
  //   path: rootPaths.ACCIDENTS,
  //   allowedRoles: [roles.CITY_SUPER_ADMIN, roles.CITY_ADMIN],
  // },
  {
    element: CityProblems,
    isExact: true,
    path: rootPaths.PROBLEMS,
    allowedRoles: [roles.CITY_SUPER_ADMIN, roles.CITY_ADMIN],
  },

  // main organization
  {
    element: MainOrganizationEmployees,
    isExact: true,
    path: rootPaths.EMPLOYEES,
    allowedRoles: [roles.MAIN_ORGANIZATION_SUPER_ADMIN]
  },
  {
    element: MainOrganizationProblems,
    isExact: true,
    path: rootPaths.PROBLEMS,
    allowedRoles: [roles.MAIN_ORGANIZATION_ADMIN, roles.MAIN_ORGANIZATION_SUPER_ADMIN],
  },
  {
    element: MainOrganizationOrganizations,
    isExact: true,
    path: rootPaths.ORGANIZATIONS,
    allowedRoles: [roles.MAIN_ORGANIZATION_SUPER_ADMIN],
  },

  // organization
  {
    element: OrganizationStatistics,
    isExact: true,
    path: rootPaths.STATISTICS,
    allowedRoles: [roles.ORGANIZATION_SUPER_ADMIN],
  },
  {
    element: OrganizationEmployees,
    isExact: true,
    path: rootPaths.EMPLOYEES,
    allowedRoles: [roles.ORGANIZATION_SUPER_ADMIN],
  },
  // {
  //   element: OrganizationAccidents,
  //   isExact: true,
  //   path: rootPaths.ACCIDENTS,
  //   allowedRoles: [roles.ORGANIZATION_SUPER_ADMIN],
  // },
  {
    element: OrganizationProblems,
    isExact: true,
    path: rootPaths.PROBLEMS,
    allowedRoles: [roles.ORGANIZATION_SUPER_ADMIN, roles.ORGANIZATION_ADMIN],
  },
  {
    element: OrganizationSubsidiaries,
    isExact: true,
    path: rootPaths.SUBSIDIARIES,
    allowedRoles: [roles.ORGANIZATION_SUPER_ADMIN],
  },
  // subsidiaries
  {
    element: SubsidiaryEmployees,
    isExact: true,
    path: rootPaths.EMPLOYEES,
    allowedRoles: [roles.SUBSIDIARY_SUPER_ADMIN],
  },
  {
    element: SubsidiaryProblems,
    isExact: true,
    path: rootPaths.PROBLEMS,
    allowedRoles: [roles.SUBSIDIARY_SUPER_ADMIN, roles.SUBSIDIARY_ADMIN],
  },
]

// filtering
export const rootRoutes: RouteModel[] = allRoutes.filter((item) => {
  if (item.allowedRoles) {
    return hasPermission(item.allowedRoles)
  }
  return true
})
