import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"

import LoginIcon from "../../assets/icons/LoginIcon"
import { rootPaths } from "../../routing/root/rootPaths"
import ProfileModal from "./components/profile-modal/ProfileModal"
import { useGetProfile } from "./services/queries"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { userReducerActions } from "../../store/reducers/userReducer"

import styles from "./profile.module.scss"

const Profile: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { logout } = userReducerActions
  const [openModal, setOpenModal] = useState(false)
  const { data: userFromBase } = useGetProfile()
  const { user: userFromState } = useAppSelector(state => state.userReducer)

  // user
  const user = useMemo(() => {
    return userFromBase ?? userFromState
  }, [userFromBase])

  // open problem-accident-modal
  const handleOpen = () => {
    setOpenModal(true)
  }

  // logout
  const handleLogout = () => {
    dispatch(logout())
    navigate(rootPaths.AUTH)
  }

  return (
    <>
      <div className={styles.profile}>
        <div className={styles.profile_left} onClick={handleOpen}>
          {user?.image ? (
            <img src={user.image} alt="" />
          ) : (
            <Avatar icon={<UserOutlined />} size={40} />
          )}
          <div className={styles.profile_left_main}>
            <h5>{user?.full_name}</h5>
            {/*<p>example@gmail.com</p>*/}
          </div>
        </div>
        <div className={styles.profile_right} onClick={handleLogout}>
          <LoginIcon />
        </div>
      </div>
      <ProfileModal openModal={openModal} setOpenModal={setOpenModal} user={user ?? undefined} />
    </>
  )
}

export default Profile
