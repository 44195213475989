import React from "react"
import { Spin } from "antd"
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { StatisticsProblemClassesModel } from "../../../utils/models/statisticsProblemClassesModel"

import styles from "./statisticsProblemClasses.module.scss"

type Props = {
  problem_classes: StatisticsProblemClassesModel[] | undefined
  isLoading: boolean
}

const StatisticsProblemClasses: React.FC<Props> = ({
  problem_classes,
  isLoading,
}) => {
  // data
  const data = problem_classes?.map((item) => {
    return {
      name: item.name,
      Yangi: item?.troubles?.new,
      Jarayonda: item.troubles.process,
      Yakunlandi: item.troubles.completed,
      Bajarildi: item.troubles.closed,
      "Rad etilgan": item.troubles.rejected,
      "Qayta yo'naltirilgan": item?.troubles?.user_rejected,
    }
  })

  return (
    <Spin spinning={isLoading}>
      <div className={styles.problem_classes}>
        <div className={styles.header}>
          <h2>Muammo sinfi</h2>
          <p>Muammolar sinfi bo’yicha statistika</p>
        </div>
        <div className={styles.body}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="Yangi" stackId="1" fill="#89CFF0" />
              <Bar dataKey="Jarayonda" stackId="1" fill="#F79009" />
              <Bar dataKey="Yakunlandi" stackId="1" fill="#12B76A" />
              <Bar dataKey="Bajarildi" stackId="1" fill="#6938EF" />
              <Bar dataKey="Rad etilgan" stackId="1" fill="#F04438" />
              <Bar dataKey="Qayta yo'naltirilgan" stackId="1" fill="#002244" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Spin>
  )
}

export default StatisticsProblemClasses
