export const endpoints = {
  MAIN_ORGANIZATION_ORGANIZATION_VIEW: "/main-organization/organization/view",
  MAIN_ORGANIZATION_ORGANIZATION_SELECT: "/main-organization/organization/select",
  MAIN_ORGANIZATION_ORGANIZATION_ATTACH: "/main-organization/organization/attach",
  MAIN_ORGANIZATION_ORGANIZATION_CREATE: "/main-organization/organization/create",
  MAIN_ORGANIZATION_ORGANIZATION_UPDATE: "/main-organization/organization/update",
  MAIN_ORGANIZATION_ORGANIZATION_DELETE: "/main-organization/organization/delete",
  MAIN_ORGANIZATION_EMPLOYEE_CREATE: "/main-organization/organization/admin/create",
  MAIN_ORGANIZATION_EMPLOYEE_UPDATE: "/main-organization/organization/admin/update",
  MAIN_ORGANIZATION_EMPLOYEE_DELETE: "/main-organization/organization/admin/delete",
}
