import React from "react"
import { Collapse } from "antd"

import CollapseExpandIcon from "../../../../assets/icons/CollapseExpandIcon"
import FolderIcon from "../../../../assets/icons/FolderIcon"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import { SubsidiaryModel } from "../../utils/models/subsidiaryModel"
import { useAppDispatch } from "../../../../hooks/redux"
import { organizationSubsidiaryReducerActions } from "../../../../store/reducers/organizationSubsidiaryReducer"
import Employee from "../employee/Employee"
import SubsidiaryFolderActions from "./actions/SubsidiaryFolderActions"

import styles from "./subsidiaryFolder.module.scss"

const SubsidiaryFolder: React.FC<SubsidiaryModel> = (props) => {
  const { id, name, problem_classes, admins } = props
  const dispatch = useAppDispatch()
  const { setEmployeeModalData } = organizationSubsidiaryReducerActions

  // open employee problem-accident-modal
  const handleOpenEmployeeModal = () => {
    dispatch(setEmployeeModalData({
      visible: true,
      subsidiaryId: id,
    }))
  }

  return (
    <Collapse
      ghost
      className={styles.subsidiary}
      expandIcon={() => <CollapseExpandIcon />}
    >
      <Collapse.Panel
        key={id}
        header={
          <div className={styles.subsidiary_header}>
            <div className={styles.subsidiary_header_left}>
              <FolderIcon />
              <p className={styles.name}>{name}</p>
              <div className={styles.classes}>
                {problem_classes?.map((item) => (
                  <div key={item.id}>
                    <span>&#x2022;</span>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.subsidiary_header_right}>
              <SubsidiaryFolderActions {...props} />
            </div>
          </div>
        }
      >
        {admins.map((item) => (
          <Employee {...item} key={item.id} />
        ))}
        <div className={styles.create} onClick={handleOpenEmployeeModal}>
          <PlusIcon />
          <p>Xodim biriktirish</p>
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}

export default SubsidiaryFolder