import { Button, Form, Modal, Select, Spin } from "antd"
import React, { useMemo, useRef } from "react"
import dayjs from "dayjs"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { cityProblemReducerActions } from "../../../../store/reducers/cityProblemReducer"
import {
  useGetDetailedProblem,
  useGetOrganizationsSelect,
} from "../../services/queries"
import { chatReducerActions } from "../../../../store/reducers/chatReducer"
import { parseUnix } from "../../../../utils/helpers/parseUnix"
import { problemStatusLabels } from "../../../../utils/constants/problemStatusLabels"
import { problemStatuses } from "../../../../utils/enums/problemStatuses"
import Images from "./images/Images"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useAttachProblem } from "../../services/mutations"
import { isEmptyArray } from "../../../../utils/helpers/isEmptyArray"
import { problemTypes } from "../../../../utils/enums/problemTypes"
import { dayjsFormats } from "../../../../utils/constants/dayjsFormats"
import MarkerIcon from "../../../../assets/icons/MarkerIcon"

import styles from "./problemModal.module.scss"
import { downloadPdf } from "../../../../utils/helpers/downloadPdf"
import DownloadPdfIcon from "../../../../assets/icons/DownloadPdfIcon"

const ProblemModal = () => {
  const pdfRef = useRef<any>()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setModalData } = cityProblemReducerActions
  const { modalData } = useAppSelector((state) => state.cityProblemReducer)
  const { setChats } = chatReducerActions
  const { data, isLoading } = useGetDetailedProblem(modalData?.id)
  const attachProblem = useAttachProblem()
  const { data: organizations, isLoading: organizationsIsLoading } =
    useGetOrganizationsSelect(modalData.visible)

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    dispatch(setChats([]))
  }

  // finish
  const onFinish = (fields: { organization_id: number }) => {
    modalData.id &&
      attachProblem
        .mutateAsync({
          organization_id: fields.organization_id,
          id: modalData.id,
        })
        .then(handleCancel)
  }

  // is editable
  const isEditable = useMemo(() => {
    return (
      data?.type === problemTypes.SUBSIDIARY &&
      (data?.status === problemStatuses.NEW ||
        data?.status === problemStatuses.REJECTED ||
        data?.status === problemStatuses.USER_REJECTED)
    )
  }, [data?.status])

  return (
    <Modal
      centered
      width={994}
      footer={false}
      onCancel={handleCancel}
      open={modalData?.visible}
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      className={styles.modal_root}
    >
      <Spin spinning={isLoading}>
        <div className={styles.modal} ref={pdfRef}>
          <div className={styles.modal_header}>
            <p>{data?.problem?.name}</p>
            {/* <span className={`status_${data?.status}`}>{problemStatusLabels[data?.status as problemStatuses]}</span> */}
            <div
              className={styles.modal_download}
              onClick={() => downloadPdf(pdfRef.current, data?.problem?.name || "")}
            >
              <span>Yuklab olish</span>
              <DownloadPdfIcon />
            </div>
          </div>
          <div className={styles.modal_body}>
            <div className={styles.modal_body_left}>
              <div className={styles.info}>
                <div className={styles.info_item}>
                  <span>Yaratilgan vaqti</span>
                  <p>{data?.created_at ? parseUnix(data?.created_at) : "-"}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Fuqaro</span>
                  <p>{data?.citizen?.full_name}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Telefon raqami</span>
                  <p>{data?.citizen?.phone}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Manzil</span>
                  {data?.lat && data?.long ? (
                    <p>
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/place/${data?.lat},${data?.long}`}
                      >
                        {data?.address}
                        <MarkerIcon />
                      </a>
                    </p>
                  ) : (
                    <p>{data?.address}</p>
                  )}
                </div>
                <div className={styles.info_item}>
                  <span>Izoh</span>
                  <p>{data?.text}</p>
                </div>
                {!isEmptyArray(data?.images) && <Images images={data?.images} />}
              </div>
              {isEditable ? (
                <Form
                  form={form}
                  className={styles.form}
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="organization_id"
                    label="Biriktiriladigan tashkilot"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Select
                      suffixIcon={<SelectSuffixIcon />}
                      loading={organizationsIsLoading}
                    >
                      {organizations
                        ?.filter((item) => item.my_organization)
                        ?.map((item) => (
                          <Select.Option value={item.id} key={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Form>
              ) : (
                <>
                  <div className={styles.info}>
                    <div className={styles.info_item}>
                      <span>Ijrochi</span>
                      <span>
                        {data?.organization_user?.full_name ? (
                          <>
                            <p>{data?.organization_user?.full_name}</p>
                            <p>{data?.organization_user?.phone}</p>
                          </>
                        ) : data?.subsidiary_user?.full_name ? (
                          <>
                            <p>{data?.subsidiary_user?.full_name}</p>
                            <p>{data?.subsidiary_user?.phone}</p>
                          </>
                        ) : data?.main_organization_user ? (
                          <>
                            <p>{data?.main_organization_user?.full_name}</p>
                            <p>{data?.main_organization_user?.phone}</p>
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>
                    {data?.deadline && (
                      <div className={styles.info_item}>
                        <span>Muddat</span>
                        <p>
                          {dayjs
                            .unix(data?.deadline)
                            .format(`${dayjsFormats.DATE} ${dayjsFormats.TIME}`)}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.info}>
                    <div className={styles.status}>
                      <p>Holat</p>
                      <span className={`status_${data?.status}`}>
                        {problemStatusLabels[data?.status as problemStatuses]}
                      </span>
                    </div>
                    {data?.comment && (
                      <div className={styles.info_item}>
                        <span>Izoh</span>
                        <p>{data?.comment}</p>
                      </div>
                    )}
                    {!isEmptyArray(data?.result_images) && (
                      <Images images={data?.result_images} />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.modal_body_right}>
              <div className={styles.events}>
                {data?.events
                  ?.slice()
                  ?.reverse()
                  .map((item) => (
                    <div className={styles.events_item} key={item.id}>
                      <span>{item.text}</span>
                      <span>{parseUnix(item?.created_at)}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {isEditable && (
            <div className={styles.modal_footer}>
              <Button size="large" onClick={handleCancel}>
                Bekor qilish
              </Button>
              <Button type="primary" size="large" onClick={handleSubmit}>
                Saqlash
              </Button>
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default ProblemModal
