import { ErrorRes, SuccessRes } from "./../../../utils/models/responseType"
import { EvolutionVariantsModelCreate } from "./../utils/models/EvolutionModel"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// get evaluation
export function useGetEvolutionVariant() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, EvolutionVariantsModelCreate>(
    async (req) => {
      const res = await $api.post(endpoints.EVOLUTION_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.EVOLUTION_VIEW])
      },
      onError: errorHandler,
    })
}

// delete
export function useDeleteEvolutionVariant() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.EVOLUTION_DELETE}?id=${id}`)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.EVOLUTION_VIEW])
      },
      onError: errorHandler,
    })
}

// update
export function useUpdateEvolutionVariant() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number, name: string }>(
    async (req) => {
      const res = await $api.put(`${endpoints.EVOLUTION_UPDATE}`, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.EVOLUTION_VIEW])
      },
      onError: errorHandler,
    })
}