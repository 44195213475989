import React from 'react'

const JumpPrevIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 14.1667L10.8333 10L15 5.83334M9.16667 14.1667L5 10L9.16667 5.83334"
        stroke="#344054"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default JumpPrevIcon
