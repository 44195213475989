import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"
import { MainOrganizationEmployeeModel } from "../../features/main-organization-employees/utils/models/MainOrganizationEmployeeModel"

const initialState: {
  mainOrganizationEmployeeModalData: { visible: boolean; data?: MainOrganizationEmployeeModel; }
} = {
  mainOrganizationEmployeeModalData: { visible: false },
}

const mainOrganizationEmployeeReducer = createSlice({
  name: sliceNames.CITY_EMPLOYEE,
  initialState,
  reducers: {
    setMainOrganizationEmployeeModalData: (state, action: PayloadAction<
      {
        visible: boolean,
        data?: MainOrganizationEmployeeModel
      }
    >) => {
      state.mainOrganizationEmployeeModalData = action.payload
    },
  },
})

export default mainOrganizationEmployeeReducer.reducer
export const mainOrganizationEmployeeReducerActions = mainOrganizationEmployeeReducer.actions