import React from "react"

import AvatarIcon from "../../../../assets/icons/AvatarIcon"
import DeleteIcon from "../../../../assets/icons/DeleteIcon"
import EditIcon from "../../../../assets/icons/EditIcon"
import { EmployeeModel } from "../../utils/models/employeeModel"
import { useAppDispatch } from "../../../../hooks/redux"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import { useDeleteEmployee } from "../../services/mutations"
import { mainOrganizationOrganizationReducerActions } from "../../../../store/reducers/mainOrganizationOrganizationReducer"

import styles from "./employee.module.scss"

const Employee: React.FC<EmployeeModel> = (props) => {
  const { full_name, phone, login, organization_id, id } = props
  const dispatch = useAppDispatch()
  const deleteEmployee = useDeleteEmployee()
  const { setEmployeeModalData } = mainOrganizationOrganizationReducerActions

  // handle update
  const handleUpdate = () => {
    dispatch(setEmployeeModalData({
      visible: true,
      organizationId: organization_id,
      data: props,
    }))
  }

  // delete
  const handleDelete = () => {
    return deleteEmployee.mutateAsync(id)
  }

  return (
    <div className={styles.employee}>
      <div className={styles.name}>
        <AvatarIcon />
        <p>{full_name}</p>
      </div>
      <div className={styles.phone}>
        <span>Telefon raqam:&nbsp;</span>
        <p>{phone}</p>
      </div>
      <div className={styles.login}>
        <span>Login:&nbsp;</span>
        <p>@{login}</p>
      </div>
      <div className={styles.actions}>
        <div className="f_div d-n" onClick={handleUpdate}>
          <EditIcon />
        </div>
        <DeleteConfirm
          text={`${full_name} nomli xodimni o'chirmoqchimisiz?`}
          onConfirm={handleDelete}
          isLoading={deleteEmployee.isLoading}
        >
          <div className="f_div">
            <DeleteIcon />
          </div>
        </DeleteConfirm>
      </div>
    </div>
  )
}

export default Employee
