import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constaints/endpoints";
import { queryKeys } from "../utils/constaints/queryKeys";
import { showMessage } from "../../../utils/helpers/showMessage";
import { messageTypes } from "../../../utils/enums/messageTypes";
import { errorHandler } from "../../../utils/helpers/errorHandler";

export function useUpdateRegionProblem() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes,
    {
      organization_id?: number,
      main_organization_id?: number,
      city_id?: number,
      id?: number
    }>(async (req) => {
      const res = await $api.put(endpoints.REGION_TROUBLE_ATTACH, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_TROUBLES])
      },
      onError: errorHandler
    })
}