import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { Pagination } from "../../../utils/models/paginationType"
import { OrganizationModel } from "../utils/models/organizationModel"
import { OrganizationSelectModel } from "../utils/models/organizationSelectModel"
import { DetailedOrganizationModel } from "../utils/models/detailedOrganizationModel"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

// get organizations
export function useGetOrganizations() {
  const url = addQueryParams(endpoints.MAIN_ORGANIZATION_ORGANIZATION_VIEW)

  return useQuery<Pagination<OrganizationModel>, ErrorRes>(
    [queryKeys.MAIN_ORGANIZATION_ORGANIZATION, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get detailed organization
export function useGetDetailedOrganization(id: number | undefined) {
  return useQuery<DetailedOrganizationModel, ErrorRes>(
    [queryKeys.MAIN_ORGANIZATION_DETAILED_ORGANIZATION, id],
    async () => {
      const res = await $api.get(`${endpoints.MAIN_ORGANIZATION_ORGANIZATION_VIEW}?id=${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: errorHandler,
    },
  )
}

// get organizations select
export function useGetOrganizationsSelect(enabled: boolean) {
  return useQuery<OrganizationSelectModel[], ErrorRes>(
    [queryKeys.MAIN_ORGANIZATION_ORGANIZATION_SELECT],
    async () => {
      const res = await $api.get(endpoints.MAIN_ORGANIZATION_ORGANIZATION_SELECT)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      retry: false,
      onError: errorHandler,
    },
  )
}
