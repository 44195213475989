export const sliceNames = {
  REGION_PROBLEM: "region-problem",
  REGION_CITY: "region-city",
  REGION_MAIN_ORGANIZATION: "region-main-organization",
  REGION_ORGANIZATION: "region-organization",
  REGION_TROUBLE: "region-trouble",
  REGION_STATISTICS: "region-statistics",
  ORGANIZATION_ADMINS: "organization_admins",
  ORGANIZATION_SUBSIDIARY: "organization-subsidiary",
  ORGANIZATION_PROBLEM: "organization-problem",
  MAIN_ORGANIZATION_PROBLEM: "main-organization-problem",
  CITY_ORGANIZATION: "city-organization",
  USER: "user",
  SUBSIDIARY: "subsidiary",
  CITY_EMPLOYEE: "city-employee",
  CITY_PROBLEM: "city-problem",
  CITY_STATISTICS: "city-statistics",
  SUBSIDIARY_PROBLEM: "subsidiary-problem",
  PUSHER: "pusher",
  CHATS: "chats",
  ORGANIZATION_ACCIDENT: "organization-accident",
  CITY_ACCIDENT: "city-accident",
  MAIN_ORGANIZATION_ORGANIZATION: 'main-organization-organization'
}
