import { Form, Image, Input, Modal, Upload } from "antd"
import React, { useState } from "react"
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { organizationProblemReducerActions } from "../../../../store/reducers/organizationProblemReducer"
import { useCompleteProblem } from "../../services/mutations"
import CompletedIcon from "../../../../assets/icons/CompletedIcon"
import ImageIcon from "../../../../assets/icons/ImageIcon"
import DeleteIcon from "../../../../assets/icons/DeleteIcon"

import styles from "./completeModal.module.scss"

const CompleteModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const [images, setImages] = useState<Array<RcFile>>([])
  const { setCompleteModalData: setModalData } = organizationProblemReducerActions
  const { completeModalData: modalData } = useAppSelector(state => state.organizationProblemReducer)
  const completeProblem = useCompleteProblem()

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    setImages([])
  }

  // finish
  const onFinish = (fields: { comment: string }) => {
    const formData = new FormData()

    formData.append("comment", fields.comment)
    formData.append("id", String(modalData.id))

    images?.forEach((image, index) => {
      formData.append(`image_${index}`, image)
    })

    completeProblem.mutateAsync(formData).then(handleCancel)
  }

  // drop change
  const handleDropChange = (e: UploadChangeParam<UploadFile>) => {
    const newImage = e.file.originFileObj

    newImage && setImages([...images, newImage])
  }

  // delete image
  const handleDeleteImage = (index: number) => {
    setImages(prevState => (
      prevState.filter((_, i) => i !== index)
    ))
  }

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Bajarildi"
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      className={styles.modal}
      okButtonProps={{
        size: "large",
        loading: completeProblem.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_content_top}>
          <CompletedIcon />
          <span>Bajarildi</span>
          <p>
            Muammo bartaraf etilgandan so’ng ushbu muammo yakunlansa natija muammo
            yuboruvchisiga yuboriladi
          </p>
        </div>
        <Form layout="vertical" className={styles.modal_content_bottom} onFinish={onFinish} form={form}>
          <Form.Item label="Izoh*" name="comment" rules={[{ required: true, message: "" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Rasm">
            <div className={styles.images_cont}>
              {images?.map((item, index) => (
                <div key={index} className={styles.images_cont_item}>
                  <ImageIcon />
                  <div className={styles.info}>
                    <p>{item.name}</p>
                    <label htmlFor={`image_${index}`}>
                      <span>Ochish</span>
                      <Image
                        id={`image_${index}`}
                        src={URL.createObjectURL(item)}
                      />
                    </label>
                  </div>
                  <div className={styles.delete} onClick={() => handleDeleteImage(index)}>
                    <DeleteIcon />
                  </div>
                </div>
              ))}
            </div>
            <Upload.Dragger
              className={styles.dragger}
              showUploadList={false}
              onChange={handleDropChange}
              customRequest={() => null}
              accept=".jpg, .jpeg, .png"
            >
              <p className={styles.description}>
                <span>Click to upload</span> or drag and drop
                <br />
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default CompleteModal
