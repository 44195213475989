import React, { useEffect, useMemo, useRef } from "react"
import { Button, Modal, Form as AntForm, Spin } from "antd"
import dayjs from "dayjs"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { problemStatusLabels } from "../../../../utils/constants/problemStatusLabels"
import { problemStatuses } from "../../../../utils/enums/problemStatuses"
import { useGetDetailedProblem } from "../../services/queries"
import Chat from "../../../../common/chat/Chat"
import { useAttachProblem, useSendMessage } from "../../services/mutations"
import Images from "./images/Images"
import Form from "./form/Form"
import { ProblemAttachFormFieldsModel } from "../../utils/models/problemAttachFormFieldsModel"
import { parseUnix } from "../../../../utils/helpers/parseUnix"
import { isEmptyArray } from "../../../../utils/helpers/isEmptyArray"
import MarkerIcon from "../../../../assets/icons/MarkerIcon"
import { problemTypes } from "../../../../utils/enums/problemTypes"
import { dayjsFormats } from "../../../../utils/constants/dayjsFormats"
import { mainOrganizationProblemReducerActions } from "../../../../store/reducers/mainOrganizationProblemReducer"

import styles from "./problemModal.module.scss"
import { downloadPdf } from "../../../../utils/helpers/downloadPdf"
import DownloadPdfIcon from "../../../../assets/icons/DownloadPdfIcon"

const ProblemModal: React.FC = () => {
  const pdfRef = useRef<any>()
  const [form] = AntForm.useForm()
  const dispatch = useAppDispatch()
  const { setModalData, setRejectModalData, setCompleteModalData } =
    mainOrganizationProblemReducerActions
  const { modalData } = useAppSelector(
    (state) => state.mainOrganizationProblemReducer
  )
  const { data, isLoading } = useGetDetailedProblem(modalData?.id)
  const sendMessage = useSendMessage()
  const attachProblem = useAttachProblem()

  // initial form fields
  useEffect(() => {
    if (data?.organization_user && data?.deadline) {
      form.setFieldsValue({
        organization_user_id: data.organization_user?.id,
        deadline: dayjs.unix(data?.deadline),
      })
    }
  }, [data?.organization_user, data?.deadline])

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // reject
  const handleReject = () => {
    dispatch(
      setRejectModalData({
        visible: true,
        id: modalData?.id,
      })
    )
    handleCancel()
  }

  // complete
  const handleComplete = () => {
    dispatch(
      setCompleteModalData({
        visible: true,
        id: modalData?.id,
      })
    )
    handleCancel()
  }

  // send message
  const handleSendMessage = (message: string) => {
    if (message && data?.id) {
      return sendMessage.mutateAsync({ message, trouble_id: data?.id })
    }
  }

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // finish
  const onFinish = (fields: ProblemAttachFormFieldsModel) => {
    const req = {
      main_organization_user_id: fields.main_organization_user_id,
      organization_id: fields.organization_id,
      deadline: dayjs(fields.deadline).unix(),
      id: modalData?.id as number,
      confirmed: fields.confirmed ? 1 : 0,
    }
    let reqKey: keyof typeof req

    for (reqKey in req) {
      if (!req[reqKey]) delete req[reqKey]
    }

    if (modalData?.id) {
      attachProblem.mutateAsync(req).then(handleCancel)
    }
  }

  // is actionable
  const isActionable = () => {
    return (
      data?.type === problemTypes.MAIN_ORGANIZATION &&
      data?.status !== problemStatuses.CLOSED &&
      data?.status !== problemStatuses.COMPLETED &&
      data?.status !== problemStatuses.DELETED
    )
  }

  // is completable
  const isCompletable = useMemo(() => {
    return data?.status === problemStatuses.PROCESS
  }, [data?.status])

  // is editable
  const isEditable = useMemo(() => {
    return (
      data?.type === problemTypes.MAIN_ORGANIZATION &&
      (data?.status === problemStatuses.NEW ||
        data?.status === problemStatuses.REJECTED ||
        data?.status === problemStatuses.USER_REJECTED)
    )
  }, [data?.status])

  // is rejectable
  const isRejectable = useMemo(() => {
    return (
      data?.status === problemStatuses.NEW ||
      data?.status === problemStatuses.REJECTED ||
      data?.status === problemStatuses.USER_REJECTED
    )
  }, [data?.status])

  return (
    <Modal
      centered
      width={1162}
      footer={false}
      onCancel={handleCancel}
      open={modalData?.visible}
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      className={styles.modal_root}
    >
      <Spin spinning={isLoading}>
        <div className={styles.modal} ref={pdfRef}>
          <div className={styles.modal_header}>
            <p>{data?.problem?.name}</p>
            {/* <span className={`status_${data?.status}`}>{problemStatusLabels[data?.status as problemStatuses]}</span> */}
            <div
              className={styles.modal_download}
              onClick={() => downloadPdf(pdfRef.current, data?.problem?.name || "")}
            >
              <span>Yuklab olish</span>
              <DownloadPdfIcon />
            </div>
          </div>
          <div className={styles.modal_body}>
            <div className={styles.modal_body_left}>
              <div className={styles.info}>
                <div className={styles.info_item}>
                  <span>Yaratilgan vaqti</span>
                  <p>{data?.created_at ? parseUnix(data?.created_at) : "-"}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Fuqaro</span>
                  <p>{data?.citizen?.full_name}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Telefon raqami</span>
                  <p>{data?.citizen?.phone}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Manzil</span>
                  {data?.lat && data?.long ? (
                    <p>
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/place/${data?.lat},${data?.long}`}
                      >
                        {data?.address}
                        <MarkerIcon />
                      </a>
                    </p>
                  ) : (
                    <p>{data?.address}</p>
                  )}
                </div>
                <div className={styles.info_item}>
                  <span>Izoh</span>
                  <p>{data?.text}</p>
                </div>
                {!isEmptyArray(data?.images) && <Images images={data?.images} />}
              </div>
              {isEditable ? (
                <Form
                  formInstance={form}
                  onFinish={onFinish}
                  createdAt={data?.created_at}
                />
              ) : (
                <>
                  <div className={styles.info}>
                    <div className={styles.info_item}>
                      <span>Ijrochi</span>
                      <span>
                        {data?.organization_user?.full_name ? (
                          <>
                            <p>{data?.organization_user?.full_name}</p>
                            <p>{data?.organization_user?.phone}</p>
                          </>
                        ) : data?.subsidiary_user?.full_name ? (
                          <>
                            <p>{data?.subsidiary_user?.full_name}</p>
                            <p>{data?.subsidiary_user?.phone}</p>
                          </>
                        ) : data?.main_organization_user ? (
                          <>
                            <p>{data?.main_organization_user?.full_name}</p>
                            <p>{data?.main_organization_user?.phone}</p>
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>
                    <div className={styles.info_item}>
                      <span>Tasdiqlagan shaxs</span>
                      <p>{data?.confirmed_user?.full_name ?? "-"}</p>
                    </div>
                    {data?.deadline && (
                      <div className={styles.info_item}>
                        <span>Muddat</span>
                        <p>
                          {dayjs
                            .unix(data?.deadline)
                            .format(`${dayjsFormats.DATE} ${dayjsFormats.TIME}`)}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.info}>
                    <div className={styles.status}>
                      <p>Holat</p>
                      <span className={`status_${data?.status}`}>
                        {problemStatusLabels[data?.status as problemStatuses]}
                      </span>
                    </div>
                    {data?.comment && (
                      <div className={styles.info_item}>
                        <span>Izoh</span>
                        <p>{data?.comment}</p>
                      </div>
                    )}
                    {!isEmptyArray(data?.result_images) && (
                      <Images images={data?.result_images} />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.modal_body_right}>
              <Chat
                problemId={modalData?.id}
                chats={data?.chats}
                events={data?.events}
                onSendMessage={handleSendMessage}
                isLoadingSendMessage={sendMessage.isLoading}
              />
            </div>
          </div>
          {isActionable() && (
            <div className={styles.modal_footer}>
              {isRejectable && (
                <Button size="large" onClick={handleReject}>
                  Qayta yo'naltirish
                </Button>
              )}
              {isCompletable && (
                <Button size="large" onClick={handleComplete}>
                  Yakunlash
                </Button>
              )}
              {isEditable && (
                <Button
                  type="primary"
                  size="large"
                  onClick={handleSubmit}
                  loading={attachProblem.isLoading}
                >
                  Saqlash
                </Button>
              )}
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default ProblemModal
