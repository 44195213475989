type IProps = {
  onClick?: () => void
  className?: string
}

const DeleteIcon: React.FC<IProps> = ({onClick, className}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M13.3333 5.49996V4.83329C13.3333 3.89987 13.3333 3.43316 13.1517 3.07664C12.9919 2.76304 12.7369 2.50807 12.4233 2.34828C12.0668 2.16663 11.6001 2.16663 10.6667 2.16663H9.33333C8.39991 2.16663 7.9332 2.16663 7.57668 2.34828C7.26308 2.50807 7.00811 2.76304 6.84832 3.07664C6.66667 3.43316 6.66667 3.89987 6.66667 4.83329V5.49996M8.33333 10.0833V14.25M11.6667 10.0833V14.25M2.5 5.49996H17.5M15.8333 5.49996V14.8333C15.8333 16.2334 15.8333 16.9335 15.5608 17.4683C15.3212 17.9387 14.9387 18.3211 14.4683 18.5608C13.9335 18.8333 13.2335 18.8333 11.8333 18.8333H8.16667C6.76654 18.8333 6.06647 18.8333 5.53169 18.5608C5.06129 18.3211 4.67883 17.9387 4.43915 17.4683C4.16667 16.9335 4.16667 16.2334 4.16667 14.8333V5.49996"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DeleteIcon
