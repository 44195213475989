import React from "react"
import { Modal, Space } from "antd"
import { UseMutationResult } from "@tanstack/react-query"

import TagItem from "./TagItem"
import CloseIcon from "../../assets/icons/CloseIcon"
import { ErrorRes, SuccessRes } from "../../utils/models/responseType"


type Props = {
  tags: { id: number, name: string }[] | undefined
  visible: boolean
  setVisible: (visible: boolean) => void
  useDeleteTag: () => UseMutationResult<SuccessRes, ErrorRes, { id: number }>
}

const TagsModal: React.FC<Props> = ({ tags, setVisible, visible, useDeleteTag }) => {

  // cancel
  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      centered
      open={visible}
      okText="Saqlash"
      onCancel={handleCancel}
      title="Teglar"
      footer={null}
      closeIcon={<CloseIcon />}
    >
      <Space size={[0, 8]} wrap>
        {tags?.map(item => (
          <TagItem key={item.id} {...item} useDeleteTag={useDeleteTag} />
        ))}
      </Space>
    </Modal>
  )
}

export default TagsModal