import queryString from "query-string"
import { useLocation } from "react-router-dom"

import { isEmptyObject } from "./isEmptyObject"

export const addQueryParams = (url: string, extraQuery?: object) => {
  const location = useLocation()
  const query = { ...queryString.parse(location.search), ...extraQuery }

  if (!isEmptyObject(query)) {
    url += `?${queryString.stringify(query)}`
  }

  return url
}