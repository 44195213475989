import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { OrganizationAdminsModel } from "../utils/models/organizationAdminsModels"
import { SubsidiariesModel } from "../utils/models/subsidiariesModel"
import { Pagination } from "../../../utils/models/paginationType"
import { ErrorRes } from "../../../utils/models/responseType"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

export function useOrganizationEmployees() {
  const url = addQueryParams(endpoints.ORGANIZATION_ADMIN)

  return useQuery<Pagination<OrganizationAdminsModel>, ErrorRes>(
    [queryKeys.ORGANIZATION_ADMIN, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    }, {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    })
}

export function useSubsidiarys() {
  return useQuery<Pagination<SubsidiariesModel>, ErrorRes>(
    [queryKeys.SUBSIDIARY],
    async () => {
      const res = await $api.get(endpoints.SUBSIDIARIES_IDS)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    })
}