import { Checkbox, Form, Input, Modal } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { CheckboxChangeEvent } from "antd/es/checkbox"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { regionMainOrganizationReducerActions } from "../../../../store/reducers/regionMainOrganizationReducer"
import { useCreateAdmin, useUpdateAdmin } from "../../services/mutation"
import { AdminFormFields } from "../../utils/models/adminFormFields"
import FakePassword from "../../../../common/fake-password/FakePassword"

import styles from "./adminModal.module.scss"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"

const AdminModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const createAdmin = useCreateAdmin()
  const updateAdmin = useUpdateAdmin()
  const { setAdminModalData: setModalData } = regionMainOrganizationReducerActions
  const { mainOrganizationAdminModalData: modalData } = useAppSelector(
    (state) => state.regionMainOrganizationReducer,
  )
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  // is updating
  const isUpdating = useMemo(() => {
    return !!modalData.data
  }, [modalData.data])

  // initial form fields
  useEffect(() => {
    if (modalData.data) {
      form.setFieldsValue({ ...modalData.data })
    }
  }, [modalData, form])

  // cancel
  const onCancel = () => {
    dispatch(
      setModalData({
        visible: false,
        main_organization_id: null,
      }),
    )
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    setIsChangingPassword(false)
  }

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // finish
  const onFinish = (values: AdminFormFields) => {
    const req = { ...values, phone: parsePhoneNumber(values.phone) }

    if (modalData.data) {
      updateAdmin
        .mutateAsync({ ...req, id: modalData.data.id })
        .then(onCancel)
    } else {
      createAdmin
        .mutateAsync({
          ...req,
          main_organization_id: modalData.main_organization_id as number,
        })
        .then(onCancel)
    }
  }

  // change is updating password
  const handleChangeIsUpdatingPassword = (e: CheckboxChangeEvent) => {
    setIsChangingPassword(e.target.checked)
  }

  // title
  const title = useMemo(() => {
    if (modalData.data) return "Admin tahrirlash"
    return "Admin qo'shish"
  }, [modalData.data])

  return (
    <Modal
      centered
      open={modalData.visible}
      title={title}
      closeIcon={<CloseIcon />}
      onCancel={onCancel}
      afterClose={handleAfterClose}
      okText="Saqlash"
      cancelText="Bekor qilish"
      className={styles.modal}
      okButtonProps={{
        onClick: handleSubmit,
        loading: createAdmin.isLoading || updateAdmin.isLoading,
      }}
    >
      <Form layout="vertical" onFinish={onFinish} form={form} autoComplete="off">
        <FakePassword />
        <Form.Item
          name="full_name"
          label="To'liq ismi"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <PhoneInput name="phone" label="Telefon raqami" required={true} />
        <div className={styles.btns}>
          <Form.Item
            label="Login"
            name="login"
            rules={[{ required: true, message: "" }]}
          >
            <Input id="login" />
          </Form.Item>
          {(isChangingPassword || !isUpdating) && (
            <Form.Item
              name="password"
              label="Parol"
              rules={[{ required: true, message: "" }]}
            >
              <Input.Password id="password" />
            </Form.Item>
          )}
        </div>
        {isUpdating && (
          <Form.Item>
            <Checkbox checked={isChangingPassword} onChange={handleChangeIsUpdatingPassword}>Parolni
              yangilash</Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default AdminModal
