import { ConfigProvider } from "antd"
import dayjs from "dayjs"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "dayjs/locale/en-gb"
import en_GB from "antd/locale/en_GB"

import { setupStore } from "./store/store"
import RootRouter from "./routing/root/RootRouter"
import { colors } from "./utils/constants/colors"
import { dayjsLocales } from "./utils/constants/dayjsLocales"

import "./styles/common.scss"
import "./styles/main.scss"

dayjs.locale(dayjsLocales.EN_GB)

const queryClient = new QueryClient()

const App = () => {
  const store = setupStore()

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          locale={en_GB}
          theme={{
            token: {
              colorPrimary: colors.primary,
            },
            components: {
              Button: {
                colorBorder: "#D0D5DD",
                colorText: "#344054",
                // lg
                borderRadiusLG: 8,
                controlHeightLG: 44,
                fontSizeLG: 16,
                // md
                borderRadius: 8,
                controlHeight: 40,
                fontSize: 14,
                // sm
                borderRadiusSM: 6,
                controlHeightSM: 32,
                fontSizeSM: 8,
              },
              Input: {
                controlHeight: 44,
                borderRadius: 8,
                colorBorder: "#D0D5DD",
              },
              Form: {
                margin: 20,
              },
              Select: {
                controlHeight: 44,
                borderRadius: 8,
                colorBorder: "#D0D5DD",
              },
            },
          }}
        >
          <BrowserRouter>
            <RootRouter />
          </BrowserRouter>
        </ConfigProvider>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
