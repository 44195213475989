import React, { useMemo } from "react"
import { Button } from "antd"

import { ProblemModel } from "../../utils/models/problemModel"
import DotsIcon from "../../../../assets/icons/DotsIcon"
import CheckSquareIcon from "../../../../assets/icons/CheckSquareIcon"
import RejectIcon from "../../../../assets/icons/RejectIcon"
import EditSmallIcon from "../../../../assets/icons/EditSmallIcon"
import { useAppDispatch } from "../../../../hooks/redux"
import { subsidiaryProblemReducerActions } from "../../../../store/reducers/subsidiaryProblemReducer"
import PopoverActions from "../../../../common/popover-actions/PopoverActions"
import { problemStatuses } from "../../../../utils/enums/problemStatuses"

import styles from "../../../../styles/actions-popover/actionsPopover.module.scss"

const ProblemActions: React.FC<ProblemModel> = (props) => {
  const { id, status } = props
  const dispatch = useAppDispatch()
  const { setCompleteModalData, setRejectModalData, setModalData } = subsidiaryProblemReducerActions
  // const deleteProblem = useDeleteProblem()

  // update
  const handleUpdate = () => {
    dispatch(setModalData({
      visible: true,
      id,
    }))
  }

  // delete
  // const handleDelete = () => {
  //   return deleteProblem.mutateAsync(id)
  // }

  // complete
  const handleComplete = () => {
    dispatch(setCompleteModalData({
      visible: true,
      id,
    }))
  }

  // reject
  const handleReject = () => {
    dispatch(setRejectModalData({
      visible: true,
      id,
    }))
  }

  // is completable
  const isCompletable = useMemo(() => {
    return status === problemStatuses.PROCESS
  }, [status])

  // is editable
  const isEditable = useMemo(() => {
    return (
      status === problemStatuses.NEW ||
      status === problemStatuses.REJECTED ||
      status === problemStatuses.USER_REJECTED
    )
  }, [status])

  // is rejectable
  const isRejectable = useMemo(() => {
    return (
      status === problemStatuses.NEW ||
      status === problemStatuses.REJECTED ||
      status === problemStatuses.USER_REJECTED
    )
  }, [status])

  // is deletable
  // const isDeletable = useMemo(() => {
  //   return (
  //     status === problemStatuses.NEW ||
  //     status === problemStatuses.REJECTED ||
  //     status === problemStatuses.USER_REJECTED
  //   )
  // }, [status])

  // content
  const content = (
    <div className={styles.popover_content}>
      {isCompletable && (
        <Button type="link" onClick={handleComplete}>
          <CheckSquareIcon />
          <span>Yakunlash</span>
        </Button>
      )}
      {isRejectable && (
        <Button type="link" onClick={handleReject}>
          <RejectIcon />
          <span>Qayta yo'naltirish</span>
        </Button>
      )}
      {isEditable && (
        <Button type="link" onClick={handleUpdate}>
          <EditSmallIcon />
          <span>Tahrirlash</span>
        </Button>
      )}
      {/*{isDeletable && (*/}
      {/*  <DeleteConfirm*/}
      {/*    text={`${problem.name} nomli muammoni o'chirmoqchimisiz?`}*/}
      {/*    onConfirm={handleDelete}*/}
      {/*    isLoading={deleteProblem.isLoading}*/}
      {/*  >*/}
      {/*    <Button type="link">*/}
      {/*      <DeleteIcon />*/}
      {/*      <span>O’chirish</span>*/}
      {/*    </Button>*/}
      {/*  </DeleteConfirm>*/}
      {/*)}*/}
    </div>
  )

  return (
    <PopoverActions content={content}>
      <DotsIcon />
    </PopoverActions>
  )
}

export default ProblemActions