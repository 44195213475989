export const parseTags: (tags: string[]) => number[] = (tags) => {
  const parsedTags: number[] = []

  tags?.forEach(tag => {
    const parsed = JSON.parse(tag)

    parsedTags.push(Number(parsed.value))
  })

  return parsedTags
}