import React, { useState } from "react"
import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"

import BodyLayout from "../../common/body-layout/BodyLayout"
import { useGetFeedbacks } from "./services/queries"
import { FeedbackModel } from "./utils/models/feedbackModel"
import { dayjsFormats } from "../../utils/constants/dayjsFormats"
import FeedbackModal from "./components/FeedbackModal"

import styles from "./index.module.scss"

const Index = () => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [feedbackId, setFeedbackId] = useState<number | null>(null)
  const { data, isLoading } = useGetFeedbacks()

  // columns
  const columns: ColumnsType<FeedbackModel> = [
    {
      title: "Feedback",
      ellipsis: true,
      render: (record) => <span className={`${styles.name} ${record?.new ? styles.new : ""}`}>{record?.message}</span>,
    },
    {
      title: "F.I.O",
      render: (record) => record?.user?.full_name,
    },
    {
      title: "Telefon raqam",
      align: "center",
      render: (record) => record?.user?.phone,
    },
    {
      title: "Sana",
      align: "center",
      dataIndex: "created_at",
      render: (created_at) => dayjs.unix(created_at).format(`${dayjsFormats.DATE} ${dayjsFormats.TIME}`),
    },
  ]

  // on row
  const handleOnRow = (record: FeedbackModel) => {
    return {
      onClick: () => {
        setFeedbackId(record?.id)
        setVisibleModal(true)
      },
    }
  }


  return (
    <>
      <BodyLayout
        title="Feedback"
        pagination={{
          total: data?.total,
        }}
      >
        <Table
          loading={isLoading}
          className={styles.table}
          columns={columns}
          dataSource={data?.data}
          pagination={false}
          onRow={handleOnRow}
          rowKey={record => record.id}
        />
      </BodyLayout>
      <FeedbackModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        feedbackId={feedbackId}
        setFeedbackId={setFeedbackId}
      />
    </>
  )
}

export default Index