import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constaints/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constaints/endpoints"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"
import { TroubleModel } from "../utils/models/troubleModel"
import { Pagination } from "../../../utils/models/paginationType"
import { ErrorRes } from "../../../utils/models/responseType"
import { DetailedTroubleModel } from "../utils/models/detailedTroubleModel"


// get trouble
export const useGetTroubles = () => {
  const url = addQueryParams(endpoints.REGION_TROUBLE_VIEW)

  return useQuery<Pagination<TroubleModel>, ErrorRes>(
    [queryKeys.REGION_TROUBLES, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get detailed trouble
export function useGetDetailedTrouble(id: number | undefined) {
  return useQuery<DetailedTroubleModel, ErrorRes>(
    [queryKeys.REGION_DETAILED_TROUBLE, id],
    async () => {
      const res = await $api.get(`${endpoints.REGION_TROUBLE_VIEW}?id=${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: errorHandler,
    },
  )
}