import React from "react"
import { PieChart as ReactPieChart, Pie, Cell } from "recharts"

import styles from "./piechart.module.scss"

type Props = {
  data: {
    value: number | undefined
    color: string
  }[]
  total: number | undefined
}

const PieChart: React.FC<Props> = ({ data, total }) => {

  return (
    <div className={styles.pie_chart}>
      <span className={styles.total}>{total}</span>
      <ReactPieChart width={230} height={230}>
        <Pie
          width="100%"
          height="100%"
          data={data}
          innerRadius={60}
          outerRadius={115}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((item, index) => (
            <Cell key={index} fill={item.color} />
          ))}
        </Pie>
      </ReactPieChart>
    </div>
  )
}

export default PieChart