import { Pagination, PaginationProps } from "antd"
import React, { useMemo } from "react"
import queryString from "query-string"
import { useLocation, useNavigate } from "react-router-dom"

import JumpNextIcon from "../../assets/icons/JumpNextIcon"
import JumpPrevIcon from "../../assets/icons/JumpPrevIcon"
import NextIcon from "../../assets/icons/NextIcon"
import PrevIcon from "../../assets/icons/PrevIcon"
import { PAGE } from "../../utils/constants/queryParamsKeys"

type Props = {
  stateData?: {
    setPage: (page: number) => void
    page: number
  }
}

const CustomPagination: React.FC<PaginationProps & Props> = (props) => {
  const { stateData } = props
  const navigate = useNavigate()
  const location = useLocation()

  // change pagination
  const handleChangePagination = (page: number) => {
    if (stateData) {
      stateData.setPage(page)
    } else {
      navigate({ search: queryString.stringify({ ...queryString.parse(location.search), page }) })
    }
  }

  // current page
  const currentPage = useMemo(() => {
    return queryString.parse(location.search)[PAGE] || 1
  }, [queryString.parse(location.search)[PAGE]])

  return (
    <Pagination
      {...props}
      showTitle={false}
      prevIcon={<PrevIcon />}
      nextIcon={<NextIcon />}
      jumpPrevIcon={<JumpPrevIcon />}
      jumpNextIcon={<JumpNextIcon />}
      current={stateData ? stateData.page : +currentPage as number}
      onChange={handleChangePagination}
      hideOnSinglePage={true}
    />
  )
}

export default CustomPagination
