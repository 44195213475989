import { useEffect, useMemo, useState } from "react"
import { Form, Input, Modal, Select } from "antd"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { regionCityReducerActions } from "../../../../store/reducers/regionCityReducer"
import { useCreateCity, useUpdateCity } from "../../services/mutation"
import { CityFormFields } from "../../utils/models/adminFormFields"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useGetDistrictSelect, useGetRegionSelect } from "../../../app/services/queries"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"
import FakePassword from "../../../../common/fake-password/FakePassword"

import styles from "../admin-modal/adminModal.module.scss"

const { Option } = Select

const CityModal = () => {
  const [form] = Form.useForm()
  const [activeRegion, setActiveRegion] = useState<number | undefined>()
  const dispatch = useAppDispatch()
  const { setCityModalData } = regionCityReducerActions
  const { cityModalData: modalData } = useAppSelector((state) => state.regionCityReducer)
  const { data: regions } = useGetRegionSelect(modalData?.visible)
  const { data: districts } = useGetDistrictSelect(activeRegion)
  const createCity = useCreateCity()
  const updateCity = useUpdateCity()

  // is updating
  const isUpdating = useMemo(() => {
    return !!modalData?.data
  }, [modalData?.data])

  // initial form fields
  useEffect(() => {
    if (isUpdating) {
      form.setFieldsValue({
        ...modalData.data,
      })
      setActiveRegion(modalData?.data?.region_id)
    }
  }, [isUpdating])

  // change region
  const handleChangeRegion = (value: number) => {
    setActiveRegion(value)
    form.resetFields(["district_id"])
  }

  // cancel
  const handleCancel = () => {
    dispatch(
      setCityModalData({
        visible: false,
      }),
    )
  }

  // finish
  const onFinish = (values: CityFormFields) => {
    const req = { ...values, phone: parsePhoneNumber(values.phone) }

    if (modalData?.data) {
      updateCity.mutateAsync({
        ...req,
        id: modalData?.data?.id,
      }).then(handleCancel)
    } else {
      createCity.mutateAsync({
        ...req,
      }).then(handleCancel)
    }
  }

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    setActiveRegion(undefined)
  }

  // title
  const title = useMemo(() => {
    if (modalData.data) return "Shahar tahrirlash"
    return "Shahar qo'shish"
  }, [modalData.data])

  return (
    <Modal
      centered
      open={modalData.visible}
      onCancel={handleCancel}
      title={title}
      closeIcon={<CloseIcon />}
      okText="Saqlash"
      cancelText="Bekor qilish"
      onOk={handleSubmit}
      okButtonProps={{
        loading: createCity.isLoading || updateCity.isLoading,
      }}
      afterClose={handleAfterClose}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <FakePassword />
        <Form.Item label="Viloyat" name="region_id" rules={[{ required: true, message: "" }]}>
          <Select suffixIcon={<SelectSuffixIcon />} onChange={handleChangeRegion}>
            {regions?.map((item) => (
              <Option value={item?.id} key={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tuman/shahar"
          rules={[{ required: true, message: "" }]}
          name="district_id"
        >
          <Select suffixIcon={<SelectSuffixIcon />}>
            {districts?.map((item) => (
              <Option key={item?.id} value={item?.id}>{item?.name}</Option>
            ))}
          </Select>
        </Form.Item>
        {!isUpdating && (
          <>
            <p>Admin ma'lumotlari</p>
            <Form.Item
              name="full_name"
              label="To'liq ismi"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <PhoneInput name="phone" label="Telefon raqami" required={true} />
            <div className={styles.btns}>
              <Form.Item
                label="Login"
                name="login"
                rules={[{ required: true, message: "" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Parol"
                rules={[{ required: true, message: "" }]}
              >
                <Input.Password />
              </Form.Item>
            </div>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default CityModal
