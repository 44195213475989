import React from "react"

import AuthForm from "./components/auth-form/AuthForm"
import bg from "./assets/images/authBackground.png"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  return (
    <div className={styles.auth}>
      <p className={styles.copyright}>© Softex 2023</p>
      <AuthForm />
      <img className={styles.bg} src={bg} draggable="false" alt="background" />
    </div>
  )
}

export default Index
