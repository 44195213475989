import { Collapse } from "antd"
import React from "react"

import CollapseExpandIcon from "../../../../assets/icons/CollapseExpandIcon"
import { OrganizationModel } from "../../utils/models/organizationModel"
import FolderIcon from "../../../../assets/icons/FolderIcon"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import { useAppDispatch } from "../../../../hooks/redux"
import { cityOrganizationReducerActions } from "../../../../store/reducers/cityOrganizationReducer"
import Employee from "../employee/Employee"
import OrganizationFolderActions from "./actions/OrganizationFolderActions"

import styles from "./organizationFolder.module.scss"

const OrganizationFolder: React.FC<OrganizationModel> = (props) => {
  const { id, name, admins, problem_classes, admins_count } = props
  const dispatch = useAppDispatch()
  const { setEmployeeModalData } = cityOrganizationReducerActions

  // open employee problem-accident-modal
  const handleOpenEmployeeModal = () => {
    dispatch(setEmployeeModalData({ visible: true, organizationId: id }))
  }

  return (
    <Collapse
      ghost
      className={styles.organization}
      expandIcon={() => <CollapseExpandIcon />}
    >
      <Collapse.Panel
        key={id}
        header={
          <div className={styles.organization_header}>
            <div className={styles.organization_header_left}>
              <FolderIcon />
              <p className={styles.name}>{name}</p>
              <div className={styles.classes}>
                {problem_classes?.map((item) => (
                  <div key={item.id}>
                    <span>&#x2022;</span>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.organization_header_right}>
              {admins_count === 0 && (
                <OrganizationFolderActions {...props} />
              )}
            </div>
          </div>
        }
      >
        {admins.map((item) => (
          <Employee {...item} key={item.id} />
        ))}
        <div className={styles.create} onClick={handleOpenEmployeeModal}>
          <PlusIcon />
          <p>Xodim biriktirish</p>
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}

export default OrganizationFolder
