export const endpoints = {
  REGION_CITY_VIEW: "/region/city/view",
  REGION_CITY_CREATE: "/region/city/create",
  REGION_CITY_UPDATE: "/region/city/update",
  REGION_CITY_DELETE: "/region/city/delete",

  REGION_ADMIN_CREATE: "region/admin/create",
  REGION_ADMIN_UPDATE: "region/admin/update",
  ADMIN_DELETE: "region/admin/delete",

  ADDRESS_REGION: "/api/address/region",
  ADDRESS_DISTRICT: "/api/address/district",
}
