import React from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

type Props = {
  size?: number | 24
}

const Spinner: React.FC<Props> = ({ size }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />

  return (
    <Spin indicator={antIcon} />
  )
}

export default Spinner