import React from "react"

import BodyLayout from "../../common/body-layout/BodyLayout"
import FolderLoading from "../../common/folder-loading/FolderLoading"
import ProblemClass from "./components/problem-class/ProblemClass"
import { useGetProblemClasses } from "./services/queries"
import ProblemClassCreate from "./components/problem-class/create/ProblemClassCreate"
import ProblemModal from "./components/problem/modal/ProblemModal"
import ProblemTypeModal from "./components/problem-type/modal/ProblemTypeModal"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  const { data, isLoading } = useGetProblemClasses()

  return (
    <BodyLayout title="Muammo sinfi" pagination={{ total: data?.total }}>
      <div className={styles.problems}>
        {isLoading ? (
          <FolderLoading />
        ) : (
          data?.data?.map((item) => <ProblemClass {...item} key={item.id} />)
        )}
        <ProblemClassCreate isLoading={isLoading} />
        <ProblemTypeModal />
        <ProblemModal />
      </div>
    </BodyLayout>
  )
}

export default Index
