import { Form, Input, Modal, Switch } from "antd"
import React, { useEffect, useMemo } from "react"

import CloseIcon from "../../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../../store/reducers/regionProblemReducer"
import { useCreateProblem, useUpdateProblem } from "../../../services/mutations"

import styles from "./problemModal.module.scss"

const ProblemModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const createProblem = useCreateProblem()
  const updateProblem = useUpdateProblem()
  const { setProblemModalData } = regionProblemReducerActions
  const { problemModalData: modalData } = useAppSelector(
    (state) => state.regionProblemReducer,
  )

  // initial form fields
  useEffect(() => {
    if (modalData.data) {
      form.setFieldsValue({
        ...modalData.data,
        image_upload_mandatory: Boolean(modalData.data.image_upload_mandatory),
      })
    }
  }, [modalData.data, form])

  // cancel
  const handleCancel = () => {
    dispatch(
      setProblemModalData({
        visible: false,
      }),
    )
  }

  // ok
  const handleOk = () => {
    form.submit()
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // finish
  const onFinish = (fields: {
    name_uz: string,
    name_ru: string,
    image_upload_mandatory: number
  }) => {
    if (modalData.data) {
      updateProblem
        .mutateAsync({
          ...fields,
          image_upload_mandatory: Number(fields.image_upload_mandatory),
          id: modalData?.data?.id as number,
          problem_type_id: modalData?.typeId as number,
        })
        .then(handleCancel)
    } else {
      createProblem
        .mutateAsync({
          ...fields,
          image_upload_mandatory: Number(fields.image_upload_mandatory),
          problem_type_id: modalData?.typeId as number,
        })
        .then(handleCancel)
    }
  }

  // title
  const title = useMemo(() => {
    if (modalData.data) return "Muammo tahrirlash"
    return "Muammo yaratish"
  }, [modalData.data])

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title={title}
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      className={styles.modal}
      okButtonProps={{
        size: "large",
        loading: createProblem.isLoading || updateProblem.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name_uz"
          label="O'zbekcha nomi"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name_ru"
          label="Ruscha nomi"
          rules={[{ required: true, message: "" }]}
        >
          <Input /> 
        </Form.Item>
        <Form.Item
          className={styles.form_item_checkbox}
          name="image_upload_mandatory"
          label="Rasm qo’shish majburiy"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ProblemModal
