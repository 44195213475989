import React, { useMemo } from "react"
import { Spin } from "antd"

import PieChart from "../pie-chart/PieChart"
import { StatisticsProblemsModel } from "../../../utils/models/statisticsProblemsModel"

import styles from "./statisticsProblems.module.scss"

type Props = {
  problems: StatisticsProblemsModel | undefined
  isLoading: boolean
}

const StatisticsProblems: React.FC<Props> = ({ problems, isLoading }) => {
  // pie chart data
  const pieChartData = useMemo(
    () => [
      { value: problems?.new, color: "#89CFF0" },
      { value: problems?.closed, color: "#6938EF" },
      { value: problems?.process, color: "#F79009" },
      { value: problems?.completed, color: "#12B76A" },
      { value: problems?.rejected, color: "#F04438" },
      { value: problems?.user_rejected, color: "#002244" },
    ],
    [problems]
  )

  // pie chart total
  const pieChartTotal = useMemo(
    () => Object.values(problems ?? {})?.reduce((a, b) => a + b, 0),
    [problems]
  )

  return (
    <Spin spinning={isLoading}>
      <div className={styles.problems}>
        <div className={styles.header}>
          <h2>Muammolar</h2>
          <p>Muammolar holati bo’yicha </p>
        </div>
        <div className={styles.body}>
          <PieChart data={pieChartData} total={pieChartTotal} />
          <div className={styles.legend}>
            <div className={styles.legend_item}>
              <div className={styles.label}>
                <span className={styles.new}></span>
                <p>Yangi</p>
              </div>
              <p className={styles.value}>{problems?.new}</p>
            </div>
            <div className={styles.legend_item}>
              <div className={styles.label}>
                <span className={styles.progress}></span>
                <p>Jarayonda</p>
              </div>
              <p className={styles.value}>{problems?.process}</p>
            </div>
            <div className={styles.legend_item}>
              <div className={styles.label}>
                <span className={styles.completed}></span>
                <p>Yakunlandi</p>
              </div>
              <p className={styles.value}>{problems?.completed}</p>
            </div>
            <div className={styles.legend_item}>
              <div className={styles.label}>
                <span className={styles.closed}></span>
                <p>Bajarildi</p>
              </div>
              <p className={styles.value}>{problems?.closed}</p>
            </div>

            <div className={styles.legend_item}>
              <div className={styles.label}>
                <span className={styles.rejected}></span>
                <p>Rad etilgan</p>
              </div>
              <p className={styles.value}>{problems?.rejected}</p>
            </div>
            <div className={styles.legend_item}>
              <div className={styles.label}>
                <span className={styles.userRejected}></span>
                <p>Qayta yo'naltirilgan</p>
              </div>
              <p className={styles.value}>{problems?.user_rejected}</p>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default StatisticsProblems
