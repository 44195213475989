export const endpoints = {
  REGION_ORGANIZATION_VIEW: "/region/organization/view",
  REGION_ORGANIZATION_CREATE: "/region/organization/create",
  REGION_ORGANIZATION_UPDATE: "/region/organization/update",
  REGION_ORGANIZATION_DELETE: "/region/organization/delete",
  REGION_ORGANIZATION_PROBLEMS_TREE_SELECT: "/region/organization/select/problem",
  REGION_ORGANIZATION_NEIGHBORHOODS_TREE_SELECT: "/region/organization/select/address",
  REGION_TAG_VIEW: "/region/tag/view",
  REGION_TAG_CREATE: "/region/tag/create",
  REGION_TAG_DELETE: "/region/tag/delete",
}