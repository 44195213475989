import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"
import { EmployeeModel } from "../../features/organization-subsidiaries/utils/models/employeeModel"


const initialState: {
  employeeModalData: {
    visible: boolean
    subsidiaryId?: number
    data?: EmployeeModel
  }
  subsidiaryModalData: {
    visible: boolean
    id?: number
  }
} = {
  employeeModalData: {
    visible: false,
  },
  subsidiaryModalData: {
    visible: false,
  },
}

const organizationSubsidiaryReducer = createSlice({
  name: sliceNames.ORGANIZATION_SUBSIDIARY,
  initialState,
  reducers: {
    setSubsidiaryModalData: (state, action: PayloadAction<{ visible: boolean, id?: number }>) => {
      state.subsidiaryModalData = action.payload
    },
    setEmployeeModalData: (state, action: PayloadAction<{
      visible: boolean,
      subsidiaryId?: number,
      data?: EmployeeModel
    }>) => {
      state.employeeModalData = action.payload
    },
  },
})

export default organizationSubsidiaryReducer.reducer
export const organizationSubsidiaryReducerActions = organizationSubsidiaryReducer.actions
