import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LocalStorage } from "../../services/LocalStorage"
import { USER } from "../../utils/constants/localStorageKeys"
import { sliceNames } from "../../utils/constants/sliceNames"
import { UserModel } from "../../utils/models/userModel"

const initialState: { user: UserModel | null, isAuth: boolean } = {
  user: LocalStorage.get<UserModel>(USER) ?? null,
  isAuth: Boolean(LocalStorage.get(USER)),
}

const userReducer = createSlice({
  name: sliceNames.USER,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserModel | null>) => {
      state.user = action.payload
      LocalStorage.set(USER, action.payload)
    },
    logout: (state) => {
      state.isAuth = false
      LocalStorage.clear()
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
  },
})

export default userReducer.reducer
export const userReducerActions = userReducer.actions
