import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { OrganizationAdminsModel } from "../utils/models/organizationAdminsModels"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

export function useCreateOrganizationAdmins() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, OrganizationAdminsModel>(async (req) => {
    const res = await $api.post(endpoints.ORGANIZATION_ADMIN_CREATE, req)
    return res.data
  }, {
    onSuccess: (res) => {
      showMessage(res.message.uz, messageTypes.SUCCESS)
      qc.invalidateQueries([queryKeys.ORGANIZATION_ADMIN])
    },
    onError: errorHandler,
  })
}

export function useUpdateOrganizationAdmin() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, OrganizationAdminsModel>(async (req) => {
    const res = await $api.put(endpoints.ORGANIZATION_ADMIN_UPDATE, req)
    return res.data
  }, {
    onSuccess: (res) => {
      showMessage(res.message.uz, messageTypes.SUCCESS)
      qc.invalidateQueries([queryKeys.ORGANIZATION_ADMIN])
    },
    onError: errorHandler,
  })
}

export function useDeleteOrganizationAdmins() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(`${endpoints.ORGANIZATION_ADMIN_DELETE}?id=${id}`)
    return res.data
  }, {
    onSuccess: (res) => {
      showMessage(res.message.uz, messageTypes.SUCCESS)
      qc.invalidateQueries([queryKeys.ORGANIZATION_ADMIN])
    },
    onError: errorHandler,
  })
}