import React from "react"
import { Table, TableColumnsType } from "antd"

import { StatisticsEmployeeModel } from "../../utils/models/statisticsEmployeeModel"

import styles from "./statisticsEmployees.module.scss"

type Props = {
  employees: StatisticsEmployeeModel[] | undefined
  isLoading: boolean
}

const StatisticsEmployees: React.FC<Props> = ({ employees, isLoading }) => {
  // columns
  const columns: TableColumnsType<StatisticsEmployeeModel> = [
    {
      title: "№",
      width: 66,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Xodimlar",
      render: (_, payload) => payload.full_name,
    },
    {
      title: "Yangi",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_new">{payload.troubles?.new}</span>
      ),
    },
    {
      title: "Jarayonda",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_completed">{payload.troubles?.process}</span>
      ),
    },
    {
      title: "Yakunlandi",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_confirmed">{payload.troubles?.completed}</span>
      ),
    },
    {
      title: "Bajarildi",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_confirmed">{payload.troubles?.closed}</span>
      ),
    },
    {
      title: "Rad etilgan",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_rejected">{payload.troubles?.rejected}</span>
      ),
    },
    {
      title: "Qayta yo'naltirilgan",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_rejected">{payload.troubles?.user_rejected}</span>
      ),
    },
  ]

  return (
    <div className={styles.employees}>
      <div className={styles.header}>
        <h2>Xodimlar statistikasi</h2>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={employees}
        pagination={false}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
    </div>
  )
}

export default StatisticsEmployees
