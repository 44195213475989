import { Form, Input, Modal } from "antd"
import React, { useEffect, useMemo } from "react"

import CloseIcon from "../../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../../store/reducers/regionProblemReducer"
import {
  useCreateProblemType,
  useUpdateProblemType,
} from "../../../services/mutations"

const ProblemTypeModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const createProblemType = useCreateProblemType()
  const updateProblemType = useUpdateProblemType()
  const { setProblemTypeModalData } = regionProblemReducerActions
  const { problemTypeModalData: modalData } = useAppSelector(
    (state) => state.regionProblemReducer,
  )

  // initial form fields
  useEffect(() => {
    if (modalData.data) {
      form.setFieldsValue({
        ...modalData.data,
        
      })
      console.log(modalData.data)
    }
  }, [modalData.data, form])

  // cancel
  const handleCancel = () => {
    dispatch(
      setProblemTypeModalData({
        visible: false,
      }),
    )
  }

  // ok
  const handleOk = () => {
    form.submit()
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // finish
  const onFinish = (fields: { name_uz: string, name_ru: string }) => {
    if (modalData.data) {
      updateProblemType
        .mutateAsync({
          ...fields,
          id: modalData?.classId as number,
        })
        .then(handleCancel)
    } else {
      createProblemType
        .mutateAsync({
          ...fields,
          problem_class_id: modalData?.classId as number,
        })
        .then(handleCancel)
    }
  }

  // title
  const title = useMemo(() => {
    if (modalData.data) return "Muammo turi tahrirlash"
    return "Muammo turi yaratish"
  }, [modalData.data])

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title={title}
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      okButtonProps={{
        size: "large",
        loading: createProblemType.isLoading || updateProblemType.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name_uz"
          label="O'zbekcha nomi"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name_ru"
          label="Ruscha nomi"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ProblemTypeModal
