import React, { useEffect, useMemo, useRef } from "react"
import { Button, Form, Input } from "antd"

import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { ChatModel } from "../../utils/models/chatModel"
import { isEmptyArray } from "../../utils/helpers/isEmptyArray"
import { chatReducerActions } from "../../store/reducers/chatReducer"
import { parseUnix } from "../../utils/helpers/parseUnix"
import AvatarIcon from "../../assets/icons/AvatarIcon"
import SendIcon from "../../assets/icons/SendIcon"
import { SuccessRes } from "../../utils/models/responseType"
import { chatTypes } from "../../utils/enums/chatTypes"

import styles from "./chat.module.scss"

type EventModel = {
  comment: null
  created_at: number
  id: number
  text: string
}

type Props = {
  problemId: number | undefined
  chats: ChatModel[] | undefined
  events: EventModel[] | undefined
  onSendMessage: (message: string) => Promise<SuccessRes> | undefined
  isLoadingSendMessage: boolean
}

const Chat: React.FC<Props> = (props) => {
  const [form] = Form.useForm()
  const chatRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()
  const { setChats, appendChat } = chatReducerActions
  const { pusher } = useAppSelector(state => state.pusherReducer)
  const { chats } = useAppSelector(state => state.chatReducer)
  const { user } = useAppSelector(state => state.userReducer)

  // scroll bottom
  const handleScrollBottom = () => {
    chatRef.current?.scrollTo({
      top: chatRef.current?.scrollHeight,
      behavior: "smooth",
    })
  }

  // set chats
  useEffect(() => {
    if (props.chats) {
      dispatch(setChats(props.chats))
    }

    return () => {
      dispatch(setChats([]))
    }
  }, [props.chats])

  // starting from bottom
  useEffect(() => {
    if (!isEmptyArray(chats)) {
      handleScrollBottom()
    }
  }, [isEmptyArray(chats), chats.length])


  // pusher subscribe
  useEffect(() => {
    if (props.problemId) {
      const channel = pusher?.subscribe("chat_id_" + String(props.problemId))

      channel?.bind("send-message", (res: {
        data: ChatModel,
        user: { id: number, full_name: string, image: string }
      }) => {
        dispatch(appendChat({ ...res.data, user: res.user, mine: res.user.id === user?.id }))
      })

      return () => {
        pusher?.unsubscribe("chat_id_" + String(props.problemId))
        channel?.unbind("send-message")
      }
    }
  }, [props.problemId])


  // finish
  const onFinish = (fields: { message: string }) => {
    props.onSendMessage(fields?.message)?.then(() => {
      form.resetFields()
    })
  }

  // data
  const data = useMemo(() => {
    const events: {
      type: chatTypes.EVENT,
      data: EventModel
    }[] | undefined = props?.events?.map(event => {
      return { data: event, type: chatTypes.EVENT }
    })
    const messages: {
      type: chatTypes.MESSAGE,
      data: ChatModel
    }[] | undefined = chats?.map(message => {
      return { data: message, type: chatTypes.MESSAGE }
    })

    const data: { type: chatTypes, data: EventModel | ChatModel }[] | undefined = [...events ?? [], ...messages ?? []]

    return data?.sort((a, b) => {
      if (a?.data?.created_at > b?.data?.created_at) return 1
      return -1
    })
  }, [props.events, props.chats, chats])


  // data object
  const dataObject = {
    [chatTypes.EVENT]: (data: EventModel) => (
      <div className={styles.event} key={data?.id}>
        <span>{data?.text}</span>
        <span>{parseUnix(data?.created_at)}</span>
      </div>
    ),
    [chatTypes.MESSAGE]: (data: ChatModel) => data?.mine ? (
      <div key={data?.id} className={styles.mine}>
        <div className={styles.info}>
          <p>Siz</p>
          <span>{parseUnix(data?.created_at)}</span>
        </div>
        <div className={styles.text}>
          <p>{data?.message}</p>
        </div>
      </div>
    ) : (
      <div key={data?.id} className={styles.others}>
        <div className={styles.others_left}>
          {data?.user?.image ? (
            <img alt="" src={data?.user.image} />
          ) : (
            <AvatarIcon />
          )}
        </div>
        <div className={styles.others_right}>
          <div className={styles.info}>
            <p>{data?.user?.full_name}</p>
            <span>{parseUnix(data?.created_at)}</span>
          </div>
          <div className={styles.text}>
            <p>{data?.message}</p>
          </div>
        </div>
      </div>
    ),
  }

  return (
    <div className={styles.chat}>
      <div className={styles.chat_top} ref={chatRef}>
        <div className={styles.chats}>
          {data?.map(item => dataObject[item.type](item.data as ChatModel & EventModel))}
        </div>
      </div>
      <Form className={styles.chat_bottom} form={form} onFinish={onFinish} autoComplete="off">
        <Form.Item name="message" className={styles.item}>
          <Input placeholder="Xabar" />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={props.isLoadingSendMessage}>
          <SendIcon />
        </Button>
      </Form>
    </div>
  )
}

export default Chat