import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { Pagination } from "../../../utils/models/paginationType"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { OrganizationModel } from "../utils/models/organizationModel"
import { DetailedOrganizationModel } from "../utils/models/detailedOrganizationModel"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

// get organizations
export function useGetOrganizations() {
  const url = addQueryParams(endpoints.REGION_ORGANIZATION_VIEW)

  return useQuery<Pagination<OrganizationModel>, ErrorRes>(
    [queryKeys.REGION_ORGANIZATIONS, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get detailed organization
export function useGetDetailedOrganization(id: number | undefined) {
  return useQuery<DetailedOrganizationModel, ErrorRes>(
    [queryKeys.REGION_DETAILED_ORGANIZATION, id],
    async () => {
      const res = await $api.get(`${endpoints.REGION_ORGANIZATION_VIEW}?id=${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: errorHandler,
    },
  )
}

// get tags
export const useGetTags = () => {
  return useQuery<{ id: number, name: string }[], ErrorRes>(
    [queryKeys.REGION_TAGS],
    async () => {
      const res = await $api.get(endpoints.REGION_TAG_VIEW)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}


