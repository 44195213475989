import { combineReducers, configureStore } from "@reduxjs/toolkit"

import regionCityReducer from "./reducers/regionCityReducer"
import regionProblemReducer from "./reducers/regionProblemReducer"
import userReducer from "./reducers/userReducer"
import regionOrganizationReducer from "./reducers/regionOrganizationReducer"
import subsidiaryReducer from "./reducers/subsidiaryReducer"
import organizationAdminsReducer from "./reducers/organizationAdminsReducer"
import cityEmployeeReducer from "./reducers/cityEmployeeReducer"
import cityProblemReducer from "./reducers/cityProblemReducer"
import cityOrganizationReducer from "./reducers/cityOrganizationReducer"
import organizationSubsidiaryReducer from "./reducers/organizationSubsidiaryReducer"
import subsidiaryProblemReducer from "./reducers/subsidiaryProblemReducer"
import organizationProblemReducer from "./reducers/organizationProblemReducer"
import pusherReducer from "./reducers/pusherReducer"
import chatReducer from "./reducers/chatReducer"
import organizationAccidentReducer from "./reducers/organizationAccidentReducer"
import cityAccidentReducer from "./reducers/cityAccidentReducer"
import regionTroubleReducer from "./reducers/regionTroubleReducer"
import regionStatisticsReducer from "./reducers/regionStatisticsReducer"
import cityStatisticsReducer from "./reducers/cityStatisticsReducer"
import regionMainOrganizationReducer from "./reducers/regionMainOrganizationReducer"
import mainOrganizationProblemReducer from "./reducers/mainOrganizationProblemReducer"
import mainOrganizationEmployeeReducer from "./reducers/mainOrganizationEmployeeReducer"
import mainOrganizationOrganizationReducer from "./reducers/mainOrganizationOrganizationReducer"

const rootReducer = combineReducers({
  regionProblemReducer,
  regionCityReducer,
  regionOrganizationReducer,
  cityOrganizationReducer,
  userReducer,
  subsidiaryReducer,
  organizationAdminsReducer,
  cityEmployeeReducer,
  cityProblemReducer,
  organizationSubsidiaryReducer,
  subsidiaryProblemReducer,
  organizationProblemReducer,
  pusherReducer,
  chatReducer,
  organizationAccidentReducer,
  cityAccidentReducer,
  regionTroubleReducer,
  regionStatisticsReducer,
  cityStatisticsReducer,
  regionMainOrganizationReducer,
  mainOrganizationProblemReducer,
  mainOrganizationEmployeeReducer,
  mainOrganizationOrganizationReducer
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["pusher/setPusher"],
        ignoredPaths: ["pusherReducer.pusher"],
      },
    }),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
