import { Form, Input, Modal, Select } from "antd"
import React, { useEffect, useMemo } from "react"
import { LoadingOutlined } from "@ant-design/icons"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { regionMainOrganizationReducerActions } from "../../../../store/reducers/regionMainOrganizationReducer"
import {
  useGetRegionSelect,
} from "../../../app/services/queries"
import {
  useCreateMainOrganization,
  useCreateTag,
  useDeleteTag,
  useUpdateMainOrganization,
} from "../../services/mutation"
import TreeSelectProblems from "../../../../common/tree-select-problems/TreeSelectProblems"
import { MainOrganizationFormFieldsModel } from "../../utils/models/mainOrganizationFormFieldsModel"
import { useGetDetailedMainOrganization, useGetTags } from "../../services/queries"
import { MainOrganizationBodyModel } from "../../utils/models/mainOrganizationBodyModel"
import { parseProblemsTreeData } from "../../../app/utils/helpers/parseProblemsTreeData"
import { problemsToFormFields } from "../../../app/utils/helpers/problemsToFormFields"
import { endpoints } from "../../utils/constants/endPoints"
import Tags from "../../../../common/tags/Tags"
import { parseTags } from "../../../app/utils/helpers/parseTags"

import styles from "./mainOrganizationModal.module.scss"
import { queryKeys } from "../../utils/constants/queryKeys"

const MainOrganizationModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setMainOrganizationModalData: setModalData } = regionMainOrganizationReducerActions
  const { mainOrganizationModalData: modalData } = useAppSelector(
    (state) => state.regionMainOrganizationReducer,
  )
  const { data: regions, isLoading: regionsIsLoading } = useGetRegionSelect(
    modalData.visible,
  )
  const [form] = Form.useForm()
  const createMainOrganization = useCreateMainOrganization()
  const updateMainOrganization = useUpdateMainOrganization()
  const { data, isLoading: dataIsLoading, isFetching: dataIsFetching } = useGetDetailedMainOrganization(modalData.data?.id)

  // initial form fields
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        region_id: data.region_id,
      })
    }
  }, [data, form])

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(
      setModalData({
        visible: false,
      }),
    )
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // finish
  const onFinish = (fields: MainOrganizationFormFieldsModel) => {

    // req
    const req: MainOrganizationBodyModel & {
      problems?: string[],
    } = {
      ...fields,
      ...parseProblemsTreeData(fields.problems),
      tag_ids: parseTags(fields.tag_ids),
    }
    delete req.problems

    if (modalData.data?.id) {
      updateMainOrganization.mutateAsync({
        ...req,
        id: modalData.data?.id,
      }).then(handleCancel)
    } else {
      createMainOrganization.mutateAsync(req).then(handleCancel)
    }
  }

  // initial tree problems
  const initialTreeProblems = useMemo(() => {
    return data && problemsToFormFields(data?.problem_classes)
  }, [data])

  // title
  const title = useMemo(() => {
    if (modalData.data?.id) return "Katta tashkilot tahrirlash"
    return "Katta tashkilot yaratish"
  }, [modalData.data?.id])

  return (
    <Modal
      centered
      open={modalData.visible}
      okText="Saqlash"
      onCancel={handleCancel}
      title={
        <>
          {title}
          &nbsp;
          {modalData.data?.id && (dataIsLoading || dataIsFetching) && <LoadingOutlined spin />}
        </>
      }
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      className={styles.modal}
      afterClose={handleAfterClose}
      cancelButtonProps={{ size: "large" }}
      okButtonProps={{
        size: "large",
        onClick: handleSubmit,
        loading: createMainOrganization.isLoading || updateMainOrganization.isLoading,
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Katta tashkilot nomi"
          name="name"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <TreeSelectProblems
          enabled={modalData.visible}
          formInstance={form}
          endpoint={endpoints.REGION_ORGANIZATION_PROBLEMS_TREE_SELECT}
          initialData={initialTreeProblems}
        />
        <Form.Item
          label="Viloyat"
          name="region_id"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            loading={regionsIsLoading}
            suffixIcon={<SelectSuffixIcon />}
          >
            {regions?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Tags
          initialData={data?.tags}
          formInstance={form}
          useGetTags={useGetTags}
          useCreateTag={useCreateTag}
          useDeleteTag={useDeleteTag}
          queryKey={queryKeys.REGION_TAGS}
        />
      </Form>
    </Modal>
  )
}

export default MainOrganizationModal
