import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"


const initialState: {
  modalData: {
    visible: boolean
    id?: number
  }
} = {
  modalData: { visible: false },
}

const regionTroubleReducer = createSlice({
  name: sliceNames.REGION_TROUBLE,
  initialState,
  reducers: {
    setModalData: (state, action: PayloadAction<{ visible: boolean, id?: number }>) => {
      state.modalData = action.payload
    },
  },
})

export default regionTroubleReducer.reducer
export const regionTroubleReducerActions = regionTroubleReducer.actions