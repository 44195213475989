import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { MainOrganizationEmployeeModel } from "../utils/models/MainOrganizationEmployeeModel"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// create admin
export function useCreateMainOrganizationAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, MainOrganizationEmployeeModel>(
    async (req) => {
      const res = await $api.post(endpoints.MAIN_ORGANIZATION_ADMIN_CREATE, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.MAIN_ORGANIZATION_ADMIN_VIEW])
      },
      onError: errorHandler,
    })
}

// update admin
export function useUpdateMainOrganizationAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, MainOrganizationEmployeeModel>(
    async (req) => {
      const res = await $api.put(endpoints.MAIN_ORGANIZATION_ADMIN_UPDATE, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.MAIN_ORGANIZATION_ADMIN_VIEW])
      },
      onError: errorHandler,
    })
}

// delete admin
export function useDeleteMainOrganizationAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(
        `${endpoints.MAIN_ORGANIZATION_ADMIN_DELETE}?id=${id}`,
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.MAIN_ORGANIZATION_ADMIN_VIEW])
      },
      onError: errorHandler,
    },
  )
}