import { Form, Modal, Select } from "antd"
import React from "react"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useGetOrganizationsSelect } from "../../services/queries"
import { useAttachOrganization } from "../../services/mutations"
import SmallCheckIcon from "../../../../assets/icons/SmallCheckIcon"
import { mainOrganizationOrganizationReducerActions } from "../../../../store/reducers/mainOrganizationOrganizationReducer"

import styles from "./organizationAttachModal.module.scss"

const OrganizationAttachModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setVisibleAttachModal } =
    mainOrganizationOrganizationReducerActions
  const { visibleAttachModal } = useAppSelector(
    (state) => state.mainOrganizationOrganizationReducer,
  )
  const { data: organizations, isLoading: organizationsIsloading } = useGetOrganizationsSelect(visibleAttachModal)
  const attachOrganization = useAttachOrganization()

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setVisibleAttachModal(false))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // finish
  const onFinish = (fields: { organization_id: number }) => {
    attachOrganization.mutateAsync({ organization_id: fields?.organization_id }).then(handleCancel)
  }

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title="Tashkilot biriktirish"
      onCancel={handleCancel}
      open={visibleAttachModal}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      okButtonProps={{
        size: "large",
        loading: attachOrganization.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Tashkilot"
          name="organization_id"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            loading={organizationsIsloading}
            popupClassName={styles.organization_popup}
            suffixIcon={<SelectSuffixIcon />}
          >
            {organizations?.filter(organization => !organization.my_organization).map((item) => (
              <Select.Option
                key={item.id}
                value={item.id}
                disabled={item.my_organization}
              >
                <div className="d-f ai-c jc-sb">
                  <span>{item.name}</span>
                  {item.my_organization && <SmallCheckIcon />}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OrganizationAttachModal
