import React, { useMemo } from "react"
import { Button } from "antd"

import CheckSquareIcon from "../../../../assets/icons/CheckSquareIcon"
import RejectIcon from "../../../../assets/icons/RejectIcon"
import EditSmallIcon from "../../../../assets/icons/EditSmallIcon"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import DotsIcon from "../../../../assets/icons/DotsIcon"
import { ProblemModel } from "../../utils/models/problemModel"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { useDeleteProblem } from "../../services/mutations"
import { organizationProblemReducerActions } from "../../../../store/reducers/organizationProblemReducer"
import { problemStatuses } from "../../../../utils/enums/problemStatuses"
import PopoverActions from "../../../../common/popover-actions/PopoverActions"
import { roles } from "../../../../utils/enums/roles"
import UnsuccessfullyCompleteIcon from "../../../../assets/icons/UnsuccessfullyCompleteIcon"

import styles from "../../../../styles/actions-popover/actionsPopover.module.scss"

const ProblemActions: React.FC<ProblemModel> = (props) => {
  const { id, status } = props
  const dispatch = useAppDispatch()
  const { setCompleteModalData, setRejectModalData, setModalData } = organizationProblemReducerActions
  const deleteProblem = useDeleteProblem()
  const { user } = useAppSelector(state => state.userReducer)


  // update
  const handleUpdate = () => {
    dispatch(setModalData({
      visible: true,
      id: props.id,
    }))
  }

  // delete
  const handleDelete = (comment: string) => {
    return deleteProblem.mutateAsync({
      id,
      comment,
    })
  }

  // complete
  const handleComplete = () => {
    dispatch(setCompleteModalData({
      visible: true,
      id,
    }))
  }

  // reject
  const handleReject = () => {
    dispatch(setRejectModalData({
      visible: true,
      id,
    }))
  }

  // is completable
  const isCompletable = useMemo(() => {
    return status === problemStatuses.PROCESS
  }, [status])

  // is editable
  const isEditable = useMemo(() => {
    return (
      status === problemStatuses.NEW ||
      status === problemStatuses.REJECTED ||
      status === problemStatuses.USER_REJECTED
    )
  }, [status])

  // is rejectable
  const isRejectable = useMemo(() => {
    return (
      status === problemStatuses.NEW ||
      status === problemStatuses.REJECTED ||
      status === problemStatuses.USER_REJECTED
    )
  }, [status])

  // is deletable
  const isDeletable = useMemo(() => {
    return user?.role === roles.ORGANIZATION_SUPER_ADMIN && (
      status === problemStatuses.NEW ||
      status === problemStatuses.REJECTED ||
      status === problemStatuses.USER_REJECTED
    )
  }, [status])

  // content
  const content = (
    <div className={styles.popover_content}>
      {isCompletable && (
        <Button type="link" onClick={handleComplete}>
          <CheckSquareIcon />
          <span>Yakunlash</span>
        </Button>
      )}
      {isRejectable && (
        <Button type="link" onClick={handleReject}>
          <RejectIcon />
          <span>Qayta yo'naltirish</span>
        </Button>
      )}
      {isEditable && (
        <Button type="link" onClick={handleUpdate}>
          <EditSmallIcon />
          <span>Tahrirlash</span>
        </Button>
      )}
      {isDeletable && (
        <DeleteConfirm
          text={"Muvaffaqiyatsiz yakunlash"}
          onConfirm={handleDelete}
          isLoading={deleteProblem.isLoading}
        >
          <Button type="link">
            <UnsuccessfullyCompleteIcon />
            <span>Muvaffaqiyatsiz yakunlash</span>
          </Button>
        </DeleteConfirm>
      )}
    </div>
  )

  return (
    <PopoverActions content={content}>
      <DotsIcon />
    </PopoverActions>
  )
}

export default ProblemActions