import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { StatisticsModel } from "../utils/models/statisticsModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { StatisticsOrganizationsModel } from "../utils/models/statisticsOrganizationsModel"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"
import { Pagination } from "../../../utils/models/paginationType"

// get statistics
export const useGetStatistics = () => {
  const url = addQueryParams(endpoints.CITY_STATISTICS)

  return useQuery<StatisticsModel, ErrorRes>(
    [queryKeys.CITY_STATISTICS, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get statistics
export const useGetStatisticsOrganizations = (tags: string[]) => {
  const url = addQueryParams(
    endpoints.CITY_STATISTICS_ORGANIZATION,
    {
      "tag_ids[]": tags.map(item => JSON.parse(item)?.value),
    },
  )

  return useQuery<Pagination<StatisticsOrganizationsModel>, ErrorRes>(
    [queryKeys.CITY_STATISTICS_ORGANIZATIONS, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}