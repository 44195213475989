import React from "react"

const EditSmallIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_629_24793)">
        <path
          d="M7.33325 2.66666H4.53325C3.41315 2.66666 2.85309 2.66666 2.42527 2.88464C2.04895 3.07639 1.74299 3.38235 1.55124 3.75867C1.33325 4.1865 1.33325 4.74655 1.33325 5.86666V11.4667C1.33325 12.5868 1.33325 13.1468 1.55124 13.5746C1.74299 13.951 2.04895 14.2569 2.42527 14.4487C2.85309 14.6667 3.41315 14.6667 4.53325 14.6667H10.1333C11.2534 14.6667 11.8134 14.6667 12.2412 14.4487C12.6176 14.2569 12.9235 13.951 13.1153 13.5746C13.3333 13.1468 13.3333 12.5868 13.3333 11.4667V8.66666M5.33323 10.6667H6.4496C6.77572 10.6667 6.93878 10.6667 7.09223 10.6298C7.22828 10.5972 7.35834 10.5433 7.47763 10.4702C7.61219 10.3877 7.72749 10.2724 7.95809 10.0418L14.3333 3.66666C14.8855 3.11437 14.8855 2.21894 14.3333 1.66666C13.781 1.11437 12.8855 1.11437 12.3333 1.66665L5.95808 8.04182C5.72747 8.27242 5.61217 8.38772 5.52971 8.52228C5.45661 8.64157 5.40274 8.77163 5.37007 8.90768C5.33323 9.06113 5.33323 9.22419 5.33323 9.55031V10.6667Z"
          stroke="#344054"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_629_24793">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditSmallIcon
