import { problemStatuses } from "../enums/problemStatuses"

const { NEW, PROCESS, CLOSED, DELETED, REJECTED, COMPLETED, USER_REJECTED } = problemStatuses
export const problemStatusLabels = {
  [NEW]: "Yangi",
  [PROCESS]: "Jarayonda",
  [REJECTED]: "Rad etildi",
  [COMPLETED]: "Yakunlandi",
  [DELETED]: "Muvaffaqiyatsiz",
  [CLOSED]: "Yopildi",
  [USER_REJECTED]: "Tasdiqlanmagan",
}