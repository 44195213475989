import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"

import { useAppSelector } from "../../hooks/redux"
import { initialRoute } from "../../utils/constants/initialRoute"

const AvoidAuth: React.FC = () => {
  const { isAuth, user } = useAppSelector((state) => state.userReducer)
  const location = useLocation()

  return !isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={initialRoute[user?.role as keyof typeof initialRoute] + location.search} />
  )
}

export default AvoidAuth
