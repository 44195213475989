import { Route } from "react-router"
import { Routes } from "react-router-dom"
import Pusher from "pusher-js"
import { useEffect, useMemo } from "react"

import MainLayout from "../../common/main-layout/MainLayout"
import Auth from "../../features/auth/Index"
import { exactRouteFixer } from "../../utils/helpers/exactRouteFixer"
import { rootPaths } from "./rootPaths"
import { rootRoutes } from "./rootRoutes"
import { useAppDispatch } from "../../hooks/redux"
import { pusherReducerActions } from "../../store/reducers/pusherReducer"
import RequireAuth from "../../common/require-auth/RequireAuth"
import AvoidAuth from "../../common/avoid-auth/AvoidAuth"

const RootRouter = () => {
  const dispatch = useAppDispatch()
  const { setPusher } = pusherReducerActions

  // pusher
  const pusher = useMemo(() => {
    return new Pusher(process.env.REACT_APP_PUSHER_APP_KEY as string, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
    })
  }, [])

  // connect and set pusher
  useEffect(() => {
    dispatch(setPusher(pusher))
    pusher.connect()
  }, [])


  return (
    <Routes>
      <Route element={<AvoidAuth />}>
        <Route path={rootPaths.AUTH} element={<Auth />} />
      </Route>
      <Route element={<RequireAuth />}>
        <Route element={<MainLayout />}>
          {rootRoutes.map((item, index) => (
            <Route
              key={index}
              element={<item.element />}
              path={exactRouteFixer(item.path, item.isExact)}
            />
          ))}
          <Route path="*" element={<div>Not Found</div>} />
        </Route>
      </Route>
    </Routes>
  )
}

export default RootRouter
