const FolderIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.705811 4.34816V19.63C0.705811 20.2831 1.23529 20.8126 1.88844 20.8126H22.1114C22.7646 20.8126 23.294 20.2831 23.294 19.63V6.75992C23.294 6.10677 22.7646 5.57729 22.1114 5.57729H11.4971C11.0374 5.57729 10.6209 5.30895 10.3804 4.9171C9.94217 4.20306 9.15189 3.16553 8.25986 3.16553H1.88659C1.23344 3.16553 0.705811 3.69501 0.705811 4.34816Z"
        fill="#30C584"
      />
      <path
        d="M23.2942 7.21587V19.2329C23.2942 19.8861 22.7647 20.4156 22.1116 20.4156H1.88858C1.23543 20.4156 0.705954 19.8861 0.705954 19.2329V8.41315C0.705954 7.76 1.23369 7.23052 1.88684 7.23052H7.86087C9.87134 7.23052 9.59046 6.03314 10.9653 6.0332C14.7163 6.03338 19.833 6.03329 22.1136 6.03323C22.7667 6.03322 23.2942 6.56271 23.2942 7.21587Z"
        fill="url(#paint0_linear_7_13418)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7_13418"
          x1="23.2942"
          y1="6.0332"
          x2="1.19803"
          y2="21.1355"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.234375" stopColor="#7DF7C2" />
          <stop offset="1" stopColor="#30C584" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default FolderIcon
