import React, { useEffect, useMemo } from "react"
import { Form, Input, Modal } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { cityOrganizationReducerActions } from "../../../../store/reducers/cityOrganizationReducer"
import TreeSelectProblems from "../../../../common/tree-select-problems/TreeSelectProblems"
import { endpoints } from "../../utils/constants/endpoints"
import { useCreateOrganization, useCreateTag, useDeleteTag, useUpdateOrganization } from "../../services/mutations"
import { parseProblemsTreeData } from "../../../app/utils/helpers/parseProblemsTreeData"
import { parseNeighborhoodsTreeData } from "../../../app/utils/helpers/parseNeighborhoodsTreeData"
import TreeSelectNeighborhoods from "../../../../common/tree-select-neighborhoods/TreeSelectNeighborhoods"
import { neighborhoodsToFormFields } from "../../../app/utils/helpers/neighborhoodsToFormFields"
import { useGetDetailedOrganization, useGetTags } from "../../services/queries"
import { problemsToFormFields } from "../../../app/utils/helpers/problemsToFormFields"
import { OrganizationBodyModel } from "../../utils/models/organizationBodyModel"
import Tags from "../../../../common/tags/Tags"
import { queryKeys } from "../../utils/constants/queryKeys"
import { parseTags } from "../../../app/utils/helpers/parseTags"

const OrganizationModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setOrganizationModalData } = cityOrganizationReducerActions
  const { organizationModalData: modalData } = useAppSelector(
    (state) => state.cityOrganizationReducer,
  )
  const createOrganization = useCreateOrganization()
  const updateOrganization = useUpdateOrganization()
  const { data, isLoading: dataIsLoading } = useGetDetailedOrganization(modalData.organizationId)

  // initial fields
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
      })
    }
  }, [data, form])

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setOrganizationModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // on finish
  const onFinish = (fields: { name: string, problems: string[], neighborhoods: string[], tag_ids: string[] }) => {

    // req
    const req: OrganizationBodyModel = {
      ...parseProblemsTreeData(fields.problems),
      ...parseNeighborhoodsTreeData(fields.neighborhoods),
      name: fields.name,
      tag_ids: parseTags(fields.tag_ids),
    }

    if (modalData.organizationId) {
      updateOrganization.mutateAsync({ ...req, id: modalData.organizationId }).then(handleCancel)
    } else {
      createOrganization.mutateAsync(req).then(handleCancel)
    }
  }

  // initial tree problems
  const initialTreeProblems = useMemo(() => {
    return data && problemsToFormFields(data?.problem_classes)
  }, [data])

  // initial tree neighborhoods
  const initialTreeNeighborhoods = useMemo(() => {
    return data && neighborhoodsToFormFields(data?.neighborhoods)
  }, [data])

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title={
        <>
          Tashkilot yaratish
          &nbsp;
          {modalData.organizationId && dataIsLoading && <LoadingOutlined spin />}
        </>
      }
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      okButtonProps={{
        size: "large",
        loading: createOrganization.isLoading || updateOrganization.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Tashkilot nomi"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <TreeSelectProblems
          endpoint={endpoints.PROBLEMS_TREE_SELECT}
          formInstance={form}
          enabled={modalData.visible}
          initialData={initialTreeProblems}
        />
        <TreeSelectNeighborhoods
          enabled={modalData.visible}
          districtIds={[0]}
          formInstance={form}
          endpoint={endpoints.NEIGHBORHOODS_TREE_SELECT}
          initialData={initialTreeNeighborhoods}
        />
        <Tags
          formInstance={form}
          useGetTags={useGetTags}
          useCreateTag={useCreateTag}
          useDeleteTag={useDeleteTag}
          queryKey={queryKeys.CITY_TAGS}
        />
      </Form>
    </Modal>
  )
}

export default OrganizationModal
