import { Button, Collapse, Space } from "antd"
import React from "react"

import AvatarIcon from "../../../../assets/icons/AvatarIcon"
import CollapseExpandIcon from "../../../../assets/icons/CollapseExpandIcon"
import DeleteIcon from "../../../../assets/icons/DeleteIcon"
import DotsIcon from "../../../../assets/icons/DotsIcon"
import EditIcon from "../../../../assets/icons/EditIcon"
import FolderIcon from "../../../../assets/icons/FolderIcon"
import MultiplyIcon from "../../../../assets/icons/MultiplyIcon"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import { useAppDispatch } from "../../../../hooks/redux"
import { regionMainOrganizationReducerActions } from "../../../../store/reducers/regionMainOrganizationReducer"
import { useDeleteAdmin, useDeleteMainOrganization } from "../../services/mutation"
import { AdminModel } from "../../utils/models/adminModel"
import { MainOrganizationModel } from "../../utils/models/mainOrganizationModel"
import PopoverActions from "../../../../common/popover-actions/PopoverActions"

import styles from "./mainOrganizationsFolder.module.scss"

const MainOrganizationsFolder: React.FC<MainOrganizationModel> = (props) => {
  const { admins, id, name, admins_count, status, problem_classes_count,problem_classes,region_id } = props
  const dispatch = useAppDispatch()
  const { setAdminModalData, setMainOrganizationModalData } = regionMainOrganizationReducerActions
  const deleteAdmin = useDeleteAdmin()
  const deleteMainOrganization = useDeleteMainOrganization()

  // open admin modal
  const handleOpenAdminModal = () => {
    dispatch(
      setAdminModalData({
        visible: true,
        main_organization_id: id,
      }),
    )
  }

  // update city
  const handleUpdateCity = () => {
    const mainOrganizationData = {
      id,
      name,
      status,
      admins_count,
      region_id,
      problem_classes,
      problem_classes_count
    }

    dispatch(setMainOrganizationModalData({
      visible: true,
      data: mainOrganizationData,
    }))
  }

  // delete city
  const handleDeleteCity = (id: number) => {
    return deleteMainOrganization.mutateAsync(id)
  }

  // update admin
  const handleUpdateAdmin = (admin: AdminModel) => {
    dispatch(
      setAdminModalData({
        visible: true,
        main_organization_id: id,
        data: admin,
      }),
    )
  }

  // delete admin
  const handleDeleteAdmin = (id: number) => {
    return deleteAdmin.mutateAsync(id)
  }

  // content
  const content = (
    <div className={styles.popover}>
      <Button type="link" onClick={handleUpdateCity}>
        <EditIcon />
        <p>Update</p>
      </Button>
      {admins_count === 0 && (
        <DeleteConfirm
          isLoading={deleteMainOrganization.isLoading}
          text={`${name} nomli shaharni o'chirmoqchimisiz?`}
          onConfirm={() => handleDeleteCity(id)}
        >
          <Button type="link">
            <DeleteIcon />
            <p>Delete</p>
          </Button>
        </DeleteConfirm>
      )}
    </div>
  )

  return (
    <Collapse
      ghost
      expandIcon={() => <CollapseExpandIcon />}
      className={styles.collapse}
    >
      <Collapse.Panel
        key={id}
        className={`${styles.folder_collapse} folder_collapse`}
        header={
          <div className={styles.collapsePanel_folder}>
            <div className={styles.collapsePanel_folder_left}>
              <FolderIcon />
              <p>{name}</p>
            </div>
            <div className={styles.collapsePanel_folder_right}>
              <span>{admins_count} xodim</span>
              <Space wrap>
                <PopoverActions content={content}>
                  <DotsIcon />
                </PopoverActions>
              </Space>
            </div>
          </div>
        }
      >
        {admins?.map((item, index) => {
          return (
            <Collapse.Panel
              key={index}
              header={
                <div className={styles.employee}>
                  <div className={styles.name}>
                    <AvatarIcon />
                    <p>{item?.full_name}</p>
                  </div>
                  <div className={styles.phone}>
                    <span>Telefon raqam:&nbsp;</span>
                    <p>{item?.phone}</p>
                  </div>
                  <div className={styles.login}>
                    <span>Login:&nbsp;</span>
                    <p>@{item?.login}</p>
                  </div>
                  <div className={styles.actions}>
                    <EditIcon onClick={() => handleUpdateAdmin(item)} />
                    <DeleteConfirm
                      onConfirm={() => handleDeleteAdmin(item.id)}
                      isLoading={deleteAdmin.isLoading}
                      text={`${item?.full_name} nomli xodimni o'chirmoqchimisiz?`}
                    >
                      <DeleteIcon />
                    </DeleteConfirm>
                  </div>
                </div>
              }
            ></Collapse.Panel>
          )
        })}
        <Collapse.Panel
          key={id}
          header={
            <div className={styles.addAdmin} onClick={handleOpenAdminModal}>
              <MultiplyIcon />
              <p>Admin qo'shish</p>
            </div>
          }
        ></Collapse.Panel>
      </Collapse.Panel>
    </Collapse>
  )
}

export default MainOrganizationsFolder
