import { rootPaths } from "../../routing/root/rootPaths"
import { roles } from "../enums/roles"

export const initialRoute = {
  [roles.REGION_ADMIN]: rootPaths.STATISTICS,
  [roles.CITY_SUPER_ADMIN]: rootPaths.STATISTICS,
  [roles.CITY_ADMIN]: rootPaths.ACCIDENTS,
  [roles.ORGANIZATION_ADMIN]: rootPaths.PROBLEMS,
  [roles.ORGANIZATION_SUPER_ADMIN]: rootPaths.STATISTICS,
  [roles.SUBSIDIARY_SUPER_ADMIN]: rootPaths.EMPLOYEES,
  [roles.SUBSIDIARY_ADMIN]: rootPaths.PROBLEMS,
  [roles.MAIN_ORGANIZATION_ADMIN]: rootPaths.PROBLEMS,
  [roles.MAIN_ORGANIZATION_SUPER_ADMIN]: rootPaths.PROBLEMS,
}
