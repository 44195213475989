import { Form, Input, Modal, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { regionOrganizationReducerActions } from "../../../../store/reducers/regionOrganizationReducer"
import {
  useGetDistrictSelect,
  useGetRegionSelect,
} from "../../../app/services/queries"
import { useCreateOrganization, useCreateTag, useDeleteTag, useUpdateOrganization } from "../../services/mutation"
import TreeSelectProblems from "../../../../common/tree-select-problems/TreeSelectProblems"
import { OrganizationFormFieldsModel } from "../../utils/models/organizationFormFieldsModel"
import TreeSelectNeighborhoods from "../../../../common/tree-select-neighborhoods/TreeSelectNeighborhoods"
import { useGetDetailedOrganization, useGetTags } from "../../services/queries"
import { OrganizationBodyModel } from "../../utils/models/organizationBodyModel"
import { parseProblemsTreeData } from "../../../app/utils/helpers/parseProblemsTreeData"
import { problemsToFormFields } from "../../../app/utils/helpers/problemsToFormFields"
import { neighborhoodsToFormFields } from "../../../app/utils/helpers/neighborhoodsToFormFields"
import { parseNeighborhoodsTreeData } from "../../../app/utils/helpers/parseNeighborhoodsTreeData"
import { endpoints } from "../../utils/constants/endpoints"
import Tags from "../../../../common/tags/Tags"
import { parseTags } from "../../../app/utils/helpers/parseTags"

import styles from "./organizationModal.module.scss"
import { queryKeys } from "../../utils/constants/queryKeys"

const OrganizationModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setModalData } = regionOrganizationReducerActions
  const [activeRegion, setActiveRegion] = useState<number | undefined>()
  const [activeDistrict, setActiveDistrict] = useState<number[] | undefined>()
  const { modalData } = useAppSelector(
    (state) => state.regionOrganizationReducer,
  )
  const { data: regions, isLoading: regionsIsLoading } = useGetRegionSelect(
    modalData.visible,
  )
  const { data: districts, isLoading: districtsIsLoading } =
    useGetDistrictSelect(activeRegion)
  const [form] = Form.useForm()
  const createOrganization = useCreateOrganization()
  const updateOrganization = useUpdateOrganization()
  const { data, isLoading: dataIsLoading, isFetching: dataIsFetching } = useGetDetailedOrganization(modalData.id)

  // initial form fields
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        region_id: data.region_id,
        district_ids: data.districts?.map((item) => item.id),
      })
      setActiveRegion(data.region_id)
      if (districtsIsLoading) {
        setActiveDistrict(data.districts?.map((item) => item.id))
      }
    }
  }, [data, form])


  // change region
  const handleChangeRegion = (value: number) => {
    setActiveRegion(value)
    form.resetFields(["district_id"])
    form.resetFields(["neighborhoods"])
  }

  // change district
  const handleChangeDistrict = (value: number[]) => {
    setActiveDistrict(value)
    form.resetFields(["neighborhoods"])
  }

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(
      setModalData({
        visible: false,
      }),
    )
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // finish
  const onFinish = (fields: OrganizationFormFieldsModel) => {

    // req
    const req: OrganizationBodyModel & {
      problems?: string[],
      neighborhoods?: string[]
    } = {
      ...fields,
      ...parseProblemsTreeData(fields.problems),
      ...parseNeighborhoodsTreeData((fields.neighborhoods)),
      tag_ids: parseTags(fields.tag_ids),
    }
    delete req.problems
    delete req.neighborhoods

    if (modalData.id) {
      updateOrganization.mutateAsync({
        ...req,
        id: modalData.id,
      }).then(handleCancel)
    } else {
      createOrganization.mutateAsync(req).then(handleCancel)
    }
  }

  // initial tree problems
  const initialTreeProblems = useMemo(() => {
    return data && problemsToFormFields(data?.problem_classes)
  }, [data])

  // initial tree neighborhoods
  const initialTreeNeighborhoods = useMemo(() => {
    return data && neighborhoodsToFormFields(data?.neighborhoods)
  }, [data])

  // title
  const title = useMemo(() => {
    if (modalData.id) return "Tashkilot tahrirlash"
    return "Tashkilot yaratish"
  }, [modalData.id])

  return (
    <Modal
      centered
      open={modalData.visible}
      okText="Saqlash"
      onCancel={handleCancel}
      title={
        <>
          {title}
          &nbsp;
          {modalData.id && (dataIsLoading || dataIsFetching) && <LoadingOutlined spin />}
        </>
      }
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      className={styles.modal}
      afterClose={handleAfterClose}
      cancelButtonProps={{ size: "large" }}
      okButtonProps={{
        size: "large",
        onClick: handleSubmit,
        loading: createOrganization.isLoading || updateOrganization.isLoading,
      }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Tashkilot nomi"
          name="name"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <TreeSelectProblems
          enabled={modalData.visible}
          formInstance={form}
          endpoint={endpoints.REGION_ORGANIZATION_PROBLEMS_TREE_SELECT}
          initialData={initialTreeProblems}
        />
        <Form.Item
          label="Viloyat"
          name="region_id"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            loading={regionsIsLoading}
            onChange={handleChangeRegion}
            suffixIcon={<SelectSuffixIcon />}
          >
            {regions?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Shahar / Tuman"
          name="district_ids"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            mode="multiple"
            loading={districtsIsLoading}
            onChange={handleChangeDistrict}
            suffixIcon={<SelectSuffixIcon />}
          >
            {districts?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <TreeSelectNeighborhoods
          enabled={modalData.visible}
          districtIds={activeDistrict}
          formInstance={form}
          endpoint={endpoints.REGION_ORGANIZATION_NEIGHBORHOODS_TREE_SELECT}
          initialData={initialTreeNeighborhoods}
        />
        <Tags
          initialData={data?.tags}
          formInstance={form}
          useGetTags={useGetTags}
          useCreateTag={useCreateTag}
          useDeleteTag={useDeleteTag}
          queryKey={queryKeys.REGION_TAGS}
        />
      </Form>
    </Modal>
  )
}

export default OrganizationModal
