import React from "react"

const UnsuccessfullyCompleteIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 6.66668V4.53334C13.3333 3.41324 13.3333 2.85319 13.1153 2.42536C12.9236 2.04904 12.6176 1.74308 12.2413 1.55133C11.8135 1.33334 11.2534 1.33334 10.1333 1.33334H5.86666C4.74656 1.33334 4.18651 1.33334 3.75868 1.55133C3.38236 1.74308 3.0764 2.04904 2.88465 2.42536C2.66666 2.85319 2.66666 3.41324 2.66666 4.53334V11.4667C2.66666 12.5868 2.66666 13.1468 2.88465 13.5747C3.0764 13.951 3.38236 14.2569 3.75868 14.4487C4.18651 14.6667 4.74656 14.6667 5.86666 14.6667H7M8.66666 7.33334H5.33333M7.33333 10H5.33333M10.6667 4.66668H5.33333M12.8333 11.3333V10.1667C12.8333 9.52234 12.311 9.00001 11.6667 9.00001C11.0223 9.00001 10.5 9.52234 10.5 10.1667V11.3333M10.4 14H12.9333C13.3067 14 13.4934 14 13.636 13.9273C13.7614 13.8634 13.8634 13.7614 13.9273 13.636C14 13.4934 14 13.3067 14 12.9333V12.4C14 12.0266 14 11.84 13.9273 11.6973C13.8634 11.5719 13.7614 11.4699 13.636 11.406C13.4934 11.3333 13.3067 11.3333 12.9333 11.3333H10.4C10.0266 11.3333 9.83994 11.3333 9.69734 11.406C9.5719 11.4699 9.46991 11.5719 9.40599 11.6973C9.33333 11.84 9.33333 12.0266 9.33333 12.4V12.9333C9.33333 13.3067 9.33333 13.4934 9.40599 13.636C9.46991 13.7614 9.5719 13.8634 9.69734 13.9273C9.83994 14 10.0266 14 10.4 14Z"
        stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default UnsuccessfullyCompleteIcon