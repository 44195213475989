import React from "react"
import { Table } from "antd"
import { ColumnsType } from "antd/es/table"


import BodyLayout from "../../common/body-layout/BodyLayout"
import AccidentModal from "./components/accident-modal/AccidentModal"
import { AccidentModel } from "./utils/models/accidentModel"
import { parseUnix } from "../../utils/helpers/parseUnix"
import { useAppDispatch } from "../../hooks/redux"
import { organizationAccidentReducerActions } from "../../store/reducers/organizationAccidentReducer"

import styles from "./index.module.scss"
import { useGetAccidents } from "./services/queries"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import DeleteIcon from "../../assets/icons/DeleteIcon"
import { useDeleteAccident } from "./services/mutations"

const Index: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setModalData } = organizationAccidentReducerActions
  const { data, isLoading } = useGetAccidents()
  const deleteAccident = useDeleteAccident()


  // create
  const handleCreate = () => {
    dispatch(setModalData({
      visible: true,
    }))
  }

  // update
  // const handleUpdate = (accident: AccidentModel) => {
  //   dispatch(setModalData({
  //     visible: true,
  //     data: accident,
  //   }))
  // }

  // delete
  const handleDelete = (id: number) => {
    return deleteAccident.mutateAsync(id)
  }

  // columns
  const columns: ColumnsType<AccidentModel> = [
    {
      title: "Boshlanish vaqti",
      dataIndex: "start_time",
      render: (start_time) => parseUnix(start_time),
    },
    {
      title: "Tugash vaqti",
      dataIndex: "start_time",
      render: (start_time) => parseUnix(start_time),
    },
    {
      title: "Turi",
      dataIndex: "problem_class",
      render: (problem_class) => problem_class?.name_uz,
    },
    {
      title: "Ta'siri",
      dataIndex: "streets_name",
      render: (streets) => streets,
    },
    {
      title: "Izoh",
      dataIndex: "comment",
      render: (comment) => comment,
    },
    {
      render: (record: AccidentModel) => (
        <div className={styles.actions}>
          {/*<div className="f_div" onClick={() => handleUpdate(record)}>*/}
          {/*  <EditIcon />*/}
          {/*</div>*/}
          <DeleteConfirm
            text={`Avariyani o'chirmoqchimisiz?`}
            onConfirm={() => handleDelete(record.id)}
            isLoading={deleteAccident.isLoading}
          >
            <div className="f_div">
              <DeleteIcon />
            </div>
          </DeleteConfirm>
        </div>
      ),
      width: 84,
    },
  ]

  // // active key
  // const activeKey = useMemo(() => {
  //   return queryString.parse(location.search)[STATUS] as string ?? ""
  // }, [queryString.parse(location.search)[STATUS]])

  return (
    <BodyLayout
      title="Avariyalar"
      createButton={{
        text: "Avariya yaratish",
        onCreate: handleCreate,
      }}
      pagination={{
        total: data?.total,
      }}
    >
      <Table
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        className={styles.accidents}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <AccidentModal />
    </BodyLayout>
  )
}

export default Index
