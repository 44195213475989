import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"

import { useAppSelector } from "../../hooks/redux"
import { rootPaths } from "../../routing/root/rootPaths"

const RequireAuth: React.FC = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer)
  const location = useLocation()

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={rootPaths.AUTH} state={{ from: location }} replace />
  )
}

export default RequireAuth
