import { ErrorRes, SuccessRes } from "./../../../utils/models/responseType"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { CityEmployeeModel } from "../utils/models/CityEmployeeModel"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// create admin
export function useCreateCityAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, CityEmployeeModel>(
    async (req) => {
      const res = await $api.post(endpoints.CITY_ADMIN_CREATE, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ADMIN_VIEW])
      },
      onError: errorHandler,
    })
}

// update admin
export function useUpdateCityAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, CityEmployeeModel>(
    async (req) => {
      const res = await $api.put(endpoints.CITY_ADMIN_UPDATE, req)
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ADMIN_VIEW])
      },
      onError: errorHandler,
    })
}

// delete admin
export function useDeleteCityAdmin() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(
        `${endpoints.CITY_ADMIN_DELETE}?id=${id}`,
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_ADMIN_VIEW])
      },
      onError: errorHandler,
    },
  )
}