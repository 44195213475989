import queryString from "query-string"

import { ProblemSelectModel } from "../models/ProblemSelectModel"

export const problemsToFormFields: (problems: ProblemSelectModel[]) => string[] = (problems) => {
  const data: string[] = []

  problems?.forEach(problem_class => {
    if (problem_class.selected) {
      data.push(queryString.stringify({
        name: problem_class.name_uz,
        key: "problem_class_ids",
        id: problem_class.id,
      }))
    }

    problem_class?.problem_types?.forEach(problem_type => {
      if (problem_type.selected) {
        data.push(queryString.stringify({
          name: problem_type.name,
          key: "problem_type_ids",
          id: problem_type.id,
        }))
      }

      problem_type?.problems?.forEach(item => {
        if (item.selected) {
          data.push(queryString.stringify({
            name: item.name,
            key: "problem_ids",
            id: item.id,
          }))
        }
      })
    })
  })

  return data
}