import React from "react"

import BodyLayout from "../../common/body-layout/BodyLayout"
import { useAppDispatch } from "../../hooks/redux"
import { cityOrganizationReducerActions } from "../../store/reducers/cityOrganizationReducer"
import OrganizationAttachModal from "./components/attach-modal/OrganizationAttachModal"
import OrganizationFolder from "./components/organization-folder/OrganizationFolder"
import EmployeeModal from "./components/employee-modal/EmployeeModal"
import { useGetOrganizations } from "./services/queries"
import FolderLoading from "../../common/folder-loading/FolderLoading"
import { mainOrganizationOrganizationReducerActions } from "../../store/reducers/mainOrganizationOrganizationReducer"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetOrganizations()
  const { setVisibleAttachModal } = mainOrganizationOrganizationReducerActions

  // create
  const handleCreate = () => {
    dispatch(setVisibleAttachModal(true))
  }

  return (
    <>
      <BodyLayout
        title="Tashkilotlar"
        createButton={{
          text: "Tashkilot biriktirish",
          onCreate: handleCreate,
        }}
        pagination={{
          total: data?.total,
        }}
      >
        <div className={styles.organizations}>
          {isLoading ? (
            <FolderLoading />
          ) : data?.data?.map((item) => (
            <OrganizationFolder {...item} key={item.id} />
          ))}
        </div>
      </BodyLayout>
      <OrganizationAttachModal />
      <EmployeeModal />
    </>
  )
}

export default Index
