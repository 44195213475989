import React, { useEffect, useMemo, useRef } from "react"
import { Button, Checkbox, Form, Modal, Select, Spin } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { DatePickerProps } from "antd/lib"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { subsidiaryProblemReducerActions } from "../../../../store/reducers/subsidiaryProblemReducer"
import { useAttachProblem, useSendMessage } from "../../services/mutations"
import Chat from "../../../../common/chat/Chat"
import { useGetDetailedProblem, useGetEmployeesSelect } from "../../services/queries"
import { problemStatusLabels } from "../../../../utils/constants/problemStatusLabels"
import { problemStatuses } from "../../../../utils/enums/problemStatuses"
import { dayjsFormats } from "../../../../utils/constants/dayjsFormats"
import Images from "./images/Images"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import DatePicker from "../../../../utils/helpers/datePicker"
import { isEmptyArray } from "../../../../utils/helpers/isEmptyArray"
import { roles } from "../../../../utils/enums/roles"
import MarkerIcon from "../../../../assets/icons/MarkerIcon"
import { dayjsUnits } from "../../../../utils/enums/dayjsUnits"
import { problemTypes } from "../../../../utils/enums/problemTypes"

import styles from "./problemModal.module.scss"
import DownloadPdfIcon from "../../../../assets/icons/DownloadPdfIcon"
import { downloadPdf } from "../../../../utils/helpers/downloadPdf"

const ProblemModal: React.FC = () => {
  const pdfRef = useRef<any>()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setModalData, setRejectModalData, setCompleteModalData } =
    subsidiaryProblemReducerActions
  const { modalData } = useAppSelector((state) => state.subsidiaryProblemReducer)
  const { user } = useAppSelector((state) => state.userReducer)
  const { data, isLoading } = useGetDetailedProblem(modalData?.id)
  const sendMessage = useSendMessage()
  const { data: employees, isLoading: employeesIsLoading } = useGetEmployeesSelect(
    user?.role
  )
  const attachProblem = useAttachProblem()

  // initial form fields
  useEffect(() => {
    if (data?.subsidiary_user && data?.deadline) {
      form.setFieldsValue({
        subsidiary_user_id: data.subsidiary_user?.id,
        deadline: dayjs.unix(data?.deadline),
      })
    }
  }, [data?.subsidiary_user, data?.deadline])

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // reject
  const handleReject = () => {
    dispatch(
      setRejectModalData({
        visible: true,
        id: modalData?.id,
      })
    )
    handleCancel()
  }

  // complete
  const handleComplete = () => {
    dispatch(
      setCompleteModalData({
        visible: true,
        id: modalData?.id,
      })
    )
    handleCancel()
  }

  // send message
  const handleSendMessage = (message: string) => {
    if (message && data?.id) {
      return sendMessage.mutateAsync({ message, trouble_id: data?.id })
    }
  }

  // submit
  const handleSubmit = () => {
    form.submit()
  }

  // finish
  const onFinish = (fields: {
    subsidiary_user_id: number
    deadline: Dayjs
    confirmed: boolean
  }) => {
    if (modalData?.id) {
      attachProblem
        .mutateAsync({
          subsidiary_user_id: fields.subsidiary_user_id,
          deadline: dayjs(fields.deadline).unix(),
          confirmed: fields.confirmed ? 1 : 0,
          id: modalData?.id,
        })
        .then(handleCancel)
    }
  }

  // disabled date
  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    const createdAt = data?.created_at

    if (createdAt) {
      return (
        current < dayjs.unix(createdAt).startOf(dayjsUnits.DAY) ||
        current > dayjs().add(14, dayjsUnits.DAY).endOf(dayjsUnits.DAY) ||
        current < dayjs().startOf(dayjsUnits.DAY)
      )
    }

    return false
  }

  // is actionable
  const isActionable = () => {
    return (
      data?.type === problemTypes.SUBSIDIARY &&
      data?.status !== problemStatuses.CLOSED &&
      data?.status !== problemStatuses.COMPLETED &&
      data?.status !== problemStatuses.DELETED
    )
  }

  // is completable
  const isCompletable = useMemo(() => {
    return data?.status === problemStatuses.PROCESS
  }, [data?.status])

  // is editable
  const isEditable = useMemo(() => {
    return (
      data?.type === problemTypes.SUBSIDIARY &&
      (data?.status === problemStatuses.NEW ||
        data?.status === problemStatuses.REJECTED ||
        data?.status === problemStatuses.USER_REJECTED)
    )
  }, [data?.status])

  // is rejectable
  const isRejectable = useMemo(() => {
    return (
      data?.status === problemStatuses.NEW ||
      data?.status === problemStatuses.REJECTED ||
      data?.status === problemStatuses.USER_REJECTED
    )
  }, [data?.status])

  // is super admin
  const isSuperAdmin = user?.role === roles.SUBSIDIARY_SUPER_ADMIN

  return (
    <Modal
      centered
      width={1162}
      footer={false}
      onCancel={handleCancel}
      open={modalData?.visible}
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      className={styles.modal_root}
    >
      <Spin spinning={isLoading}>
        <div className={styles.modal} ref={pdfRef}>
          <div className={styles.modal_header}>
            <p>{data?.problem?.name}</p>
            {/* <span className={`status_${data?.status}`}>{problemStatusLabels[data?.status as problemStatuses]}</span> */}
            <div
              className={styles.modal_download}
              onClick={() => downloadPdf(pdfRef.current, data?.problem?.name || "")}
            >
              <span>Yuklab olish</span>
              <DownloadPdfIcon />
            </div>
          </div>
          <div className={styles.modal_body}>
            <div className={styles.modal_body_left}>
              <div className={styles.info}>
                <div className={styles.info_item}>
                  <span>Yaratilgan vaqti</span>
                  <p>
                    {data?.created_at &&
                      dayjs
                        .unix(data?.created_at)
                        .format(`${dayjsFormats.DATE} ${dayjsFormats.TIME}`)}
                  </p>
                </div>
                <div className={styles.info_item}>
                  <span>Fuqaro</span>
                  <p>{data?.citizen?.full_name}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Telefon raqami</span>
                  <p>{data?.citizen?.phone}</p>
                </div>
                <div className={styles.info_item}>
                  <span>Manzil</span>
                  {data?.lat && data?.long ? (
                    <p>
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/place/${data?.lat},${data?.long}`}
                      >
                        {data?.address}
                        <MarkerIcon />
                      </a>
                    </p>
                  ) : (
                    <p>{data?.address}</p>
                  )}
                </div>
                <div className={styles.info_item}>
                  <span>Izoh</span>
                  <p>{data?.text}</p>
                </div>
                {!isEmptyArray(data?.images) && <Images images={data?.images} />}
              </div>
              {isEditable ? (
                <Form
                  className={styles.form}
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                >
                  {isSuperAdmin && (
                    <>
                      <Form.Item label="" valuePropName="checked" name="confirmed">
                        <Checkbox>Tasdiqlash</Checkbox>
                      </Form.Item>
                      <Form.Item
                        label="Xodim biriktirish"
                        name="subsidiary_user_id"
                        rules={[{ required: true, message: "" }]}
                      >
                        <Select
                          suffixIcon={<SelectSuffixIcon />}
                          loading={employeesIsLoading}
                        >
                          {employees?.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.full_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    label="Bajarish muddati"
                    name="deadline"
                    rules={[{ required: true, message: "" }]}
                  >
                    <DatePicker
                      format={dayjsFormats.DATE}
                      placeholder=""
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Form>
              ) : (
                <>
                  <div className={styles.info}>
                    <div className={styles.info_item}>
                      <span>Ijrochi</span>
                      <span>
                        {data?.organization_user?.full_name ? (
                          <>
                            <p>{data?.organization_user?.full_name}</p>
                            <p>{data?.organization_user?.phone}</p>
                          </>
                        ) : data?.subsidiary_user?.full_name ? (
                          <>
                            <p>{data?.subsidiary_user?.full_name}</p>
                            <p>{data?.subsidiary_user?.phone}</p>
                          </>
                        ) : data?.main_organization_user ? (
                          <>
                            <p>{data?.main_organization_user?.full_name}</p>
                            <p>{data?.main_organization_user?.phone}</p>
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>
                    <div className={styles.info_item}>
                      <span>Tasdiqlagan shaxs</span>
                      <p>{data?.confirmed_user?.full_name ?? "-"}</p>
                    </div>
                    {data?.deadline && (
                      <div className={styles.info_item}>
                        <span>Muddat</span>
                        <p>
                          {dayjs
                            .unix(data?.deadline)
                            .format(`${dayjsFormats.DATE} ${dayjsFormats.TIME}`)}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.info}>
                    <div className={styles.status}>
                      <p>Holat</p>
                      <span className={`status_${data?.status}`}>
                        {problemStatusLabels[data?.status as problemStatuses]}
                      </span>
                    </div>
                    {data?.comment && (
                      <div className={styles.info_item}>
                        <span>Izoh</span>
                        <p>{data?.comment}</p>
                      </div>
                    )}
                    {!isEmptyArray(data?.result_images) && (
                      <Images images={data?.result_images} />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.modal_body_right}>
              <Chat
                problemId={modalData?.id}
                chats={data?.chats}
                events={data?.events}
                onSendMessage={handleSendMessage}
                isLoadingSendMessage={sendMessage.isLoading}
              />
            </div>
          </div>
          {isActionable() && (
            <div className={styles.modal_footer}>
              {isRejectable && (
                <Button size="large" onClick={handleReject}>
                  Qayta yo'naltirish
                </Button>
              )}
              {isCompletable && (
                <Button size="large" onClick={handleComplete}>
                  Yakunlash
                </Button>
              )}
              {isEditable && (
                <Button
                  type="primary"
                  size="large"
                  onClick={handleSubmit}
                  loading={attachProblem.isLoading}
                >
                  Saqlash
                </Button>
              )}
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default ProblemModal
