import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import React from "react"
import DeleteIcon from "../../assets/icons/DeleteIcon"
import EditIcon from "../../assets/icons/EditIcon"
import BodyLayout from "../../common/body-layout/BodyLayout"
import CityEmployeeModal from "./components/modal/CityEmployeeModal"
import { useCityAdmins } from "./services/queries"
import { CityEmployeeModel } from "./utils/models/CityEmployeeModel"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import { useDeleteCityAdmin } from "./services/mutation"

import styles from "./cityEmployees.module.scss"
import { useAppDispatch } from "../../hooks/redux"
import { cityEmployeereducerActions } from "../../store/reducers/cityEmployeeReducer"

const Index: React.FC = () => {
  const { data, isLoading } = useCityAdmins()
  const deleteAdmin = useDeleteCityAdmin()
  const { setCityEmployeeModalData: setModalOpen } = cityEmployeereducerActions
  const dispatch = useAppDispatch()

  //handleOpenModal
  const handleOpenModal = () => {
    dispatch(
      setModalOpen({
        visible: true,
      }),
    )
  }

  //handleDeleteAdmin
  const handleDeleteCityAdmin = (id: number) => {
    return deleteAdmin.mutateAsync(id)
  }

  //handleUpdateAdmin

  const handleUpdateCityAdmin = (data: CityEmployeeModel) => {
    dispatch(
      setModalOpen({
        visible: true,
        data,
      }),
    )
  }

  const columns: ColumnsType<CityEmployeeModel> = [
    {
      title: "To'liq ismi",
      dataIndex: "full_name",
      render: (text: string) => <a className={styles.employeesTitle}>{text}</a>,
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      render: (phone: string) => (
        <a className={styles.employeesContent}>{phone}</a>
      ),
    },
    {
      title: "Mas'ul tashkilotlari",
      render: (render: CityEmployeeModel) => (
        <div className={styles.organization}>
          {render?.organizations?.map((item) => (
            <p>{item?.name}</p>
          ))}
        </div>
      ),
    },
    {
      render: (render: CityEmployeeModel) => (
        <div className={styles.employeesAction}>
          <EditIcon onClick={() => handleUpdateCityAdmin(render)} />
          <DeleteConfirm
            text={`${render?.full_name} ismli xodimni o'chirmoqchimisiz?`}
            onConfirm={() => handleDeleteCityAdmin(render?.id)}
            isLoading={deleteAdmin?.isLoading}
          >
            <DeleteIcon />
          </DeleteConfirm>
        </div>
      ),
    },
  ]

  return (
    <BodyLayout
      title="Xodimlar"
      createButton={{
        text: "Xodimlar qo'shish",
        onCreate: () => handleOpenModal(),
      }}
      pagination={{
        total: data?.total,
      }}
    >
      <Table
        rowKey={(render) => render.id}
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        className={styles.employeesTable}
        loading={isLoading}
      />
      <CityEmployeeModal />
    </BodyLayout>
  )
}

export default Index
