import { Button, Input, Rate } from "antd"
import React, { useState } from "react"

import MultiplyIcon from "../../../../assets/icons/MultiplyIcon"
import {
  EvolutionModel,
  EvolutionVariantsModelCreate,
} from "../../utils/models/EvolutionModel"
import SettingsChildInput from "./SettingsChildInput"
import { useGetEvolutionVariant } from "../../services/mutations"
import Spinner from "../../../../common/spinner/Spinner"
import SmallCheckIcon from "../../../../assets/icons/SmallCheckIcon"

import styles from "./settingsCenter.module.scss"

type Props = {
  data: EvolutionModel
  isLoading: boolean
}

const SenterChild: React.FC<Props> = ({ data }) => {
  const [isCreating, setIsCreating] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const evolutionVariant = useGetEvolutionVariant()

  // const inputRef = useRef<InputRef | null>(null)
  // useEffect(() => {
  //   inputRef.current?.focus()
  // }, [])

  //handleChangeIsCreating
  const handleChangeIsCreating = () => {
    setIsCreating(true)
  }

  //onFinish
  const onFinish = (value: EvolutionVariantsModelCreate) => {
    evolutionVariant.mutateAsync(value).then(() => {
      setIsCreating(false)
      setInputValue("")
    })
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onFinish({ evaluation_id: data?.id, name: inputValue })
    }
  }

  return (
    <div className={styles.settingsCenter_body_main_child}>
      <div className={styles.settingsCenter_body_main_child_left}>
        <p>{data?.name} baho berilsa</p>
        <div className={styles.settingsCenter_body_main_child_left_stars}>
          <Rate disabled defaultValue={data?.stars_number} />
        </div>
      </div>
      <div className={styles.settingsCenter_body_main_child_right}>
        <p>Berilgan izoh variantlari</p>
        {data?.evaluation_variants?.map((data) => {
          return <SettingsChildInput key={data?.id} data={data} />
        })}
        <div className={styles.editing}>
          {isCreating && (
            <Input
              // ref={inputRef}
              onKeyPress={handleKeyPress}
              onChange={(e) => setInputValue(e.target.value)}
            />
          )}
          {inputValue && (
            evolutionVariant.isLoading ? (
              <Spinner />
            ) : (
              <Button
                type="primary"
                className={styles.update}
                onClick={() =>
                  onFinish({ evaluation_id: data?.id, name: inputValue })
                }
              >
                <SmallCheckIcon />
              </Button>
            )
          )}

          {!isCreating && (
            <button className={styles.btn} onClick={handleChangeIsCreating}>
              <MultiplyIcon /> <span>Variant qo'shish</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default SenterChild
