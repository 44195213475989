import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"
import { AccidentModel } from "../../features/organization-accidents/utils/models/accidentModel"

const initialState: {
  modalData: {
    visible: boolean,
    data?: AccidentModel
  }
} = {
  modalData: {
    visible: false,
  },
}

const organizationAccidentReducer = createSlice({
  name: sliceNames.ORGANIZATION_ACCIDENT,
  initialState,
  reducers: {
    setModalData: (state, action: PayloadAction<{ visible: boolean, data?: AccidentModel }>) => {
      state.modalData = action.payload
    },
  },
})

export default organizationAccidentReducer.reducer
export const organizationAccidentReducerActions = organizationAccidentReducer.actions