import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"

const initialState: {
  modalData: {
    visible: boolean
    id?: number
  }
} = {
  modalData: { visible: false },
}

const cityProblemReducer = createSlice({
  name: sliceNames.CITY_PROBLEM,
  initialState,
  reducers: {
    setModalData: (state, actions: PayloadAction<{ visible: boolean, id?: number }>) => {
      state.modalData = actions.payload
    },
  },
})

export default cityProblemReducer.reducer
export const cityProblemReducerActions = cityProblemReducer.actions