import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"

const initialState: {
  modalData: {
    visible: boolean
    id?: number
  }
} = {
  modalData: {
    visible: false,
  },
}

const regionOrganizationReducer = createSlice({
  name: sliceNames.REGION_ORGANIZATION,
  initialState,
  reducers: {
    setModalData: (
      state,
      action: PayloadAction<{ visible: boolean; id?: number }>,
    ) => {
      state.modalData = action.payload
    },
  },
})

export default regionOrganizationReducer.reducer
export const regionOrganizationReducerActions =
  regionOrganizationReducer.actions
