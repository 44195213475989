import React from "react"
import { Table } from "antd"

import BodyLayout from "../../common/body-layout/BodyLayout"
import EditIcon from "../../assets/icons/EditIcon"
import DeleteIcon from "../../assets/icons/DeleteIcon"
import { subsidiaryReducerActions } from "../../store/reducers/subsidiaryReducer"
import { useAppDispatch } from "../../hooks/redux"
import SubsidiaryModal from "./components/subsidinary-modal/SubsidiaryModal"
import { useSubsidiariesAdmins } from "./services/queries"
import { SubsidiaryAdminModel } from "./utils/models/subsidiaryAdminModel"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import { useDeleteSubsidiaryAdmin } from "./services/mutation"

import styles from "./subsidinary.module.scss"
import { roles } from "../../utils/enums/roles"

const Index: React.FC = () => {
  const { data, isLoading } = useSubsidiariesAdmins()
  const { setSubsidiary } = subsidiaryReducerActions
  const deleteSubsidiaryAdmin = useDeleteSubsidiaryAdmin()
  const dispatch = useAppDispatch()

  //handleOpenModal
  const handleOpen = () => {
    dispatch(
      setSubsidiary({
        visible: true,
      }),
    )
  }

  //handleUpdate
  const handleUpdateSubsidiaryAdmin = (data: SubsidiaryAdminModel) => {
    dispatch(
      setSubsidiary({
        visible: true,
        data,
      }),
    )
  }

  //handleDelete
  const handleDeleteSubsidiaryAdmin = (id: number) => {
    return deleteSubsidiaryAdmin.mutateAsync(id)
  }

  const columns = [
    {
      title: "To'liq ismi",
      key: "name",
      render: (render: SubsidiaryAdminModel) => (
        <span className={styles.subsidinartTable_name}>{render.full_name}</span>
      ),
      width: 427,
    },
    {
      title: "Telefon raqami",
      key: "phone",
      render: (render: SubsidiaryAdminModel) => <span>{render.phone}</span>,
    },
    {
      title: "Lavozimi",
      dataIndex: "role",
      render: (text: string) => <span>{text === roles.SUBSIDIARY_ADMIN ? "Xodim" : "Admin"}</span>,
    },
    {
      title: "",
      render: (render: SubsidiaryAdminModel) => (
        <span className={styles.subsidinartTable_actions}>
          <EditIcon onClick={() => handleUpdateSubsidiaryAdmin(render)} />
          <DeleteConfirm
            text={`${render.full_name} ismli xodimni o'chirmoqchimisiz?`}
            onConfirm={() => handleDeleteSubsidiaryAdmin(render?.id)}
            isLoading={deleteSubsidiaryAdmin?.isLoading}
          >
            <DeleteIcon />
          </DeleteConfirm>
        </span>
      ),
    },
  ]

  return (
    <BodyLayout
      title="Xodimlar ro'yxati"
      createButton={{ text: "Xodim qo'shish", onCreate: handleOpen }}
      pagination={{
        total: data?.total,
      }}
    >
      <Table
        className={styles.subsidinartTable}
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        loading={isLoading}
        rowKey={record => record.id}
      />
      <SubsidiaryModal />
    </BodyLayout>
  )
}

export default Index
