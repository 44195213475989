import React from "react"

import BodyLayout from "../../common/body-layout/BodyLayout"
import MainOrganizationsFolder from "./components/main-organizations-folder/MainOrganizationsFolder"
import AdminModal from "./components/admin-modal/AdminModal"
import { useGetMainOrganization } from "./services/queries"
import FolderLoading from "../../common/folder-loading/FolderLoading"
import { useAppDispatch } from "../../hooks/redux"
import { regionMainOrganizationReducerActions } from "../../store/reducers/regionMainOrganizationReducer"

import styles from "./index.module.scss"
import MainOrganizationModal from "./components/main-organization-modal/MainOrganizationModal"

const Index: React.FC = () => {
  const { data, isLoading } = useGetMainOrganization()
  const dispatch = useAppDispatch()
  const { setMainOrganizationModalData } = regionMainOrganizationReducerActions

  const handleOpenModal = () => {
    dispatch(
      setMainOrganizationModalData({
        visible: true,
      }),
    )
  }

  return (
    <BodyLayout
      title="Katta tashkilotlar"
      createButton={{
        text: "Katta tashkilot qo'shish",
        onCreate: handleOpenModal,
      }}
      pagination={{
        total: data?.total,
      }}
    >
      <div className={styles.cities}>
        {isLoading ? (
          <FolderLoading />
        ) : (
          data?.data?.map((item) => <MainOrganizationsFolder {...item} key={item.id} />)
        )}
      </div>
      <AdminModal />
      <MainOrganizationModal />
    </BodyLayout>
  )
}

export default Index
