import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { Pagination } from "../../../utils/models/paginationType"
import { FeedbackModel } from "../utils/models/feedbackModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"


// get feedbacks
export const useGetFeedbacks = () => {
  const url = addQueryParams(endpoints.REGION_FEEDBACK_VIEW)

  return useQuery<Pagination<FeedbackModel>, ErrorRes>(
    [queryKeys.REGION_FEEDBACKS_VIEW, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get one feedback
export const useGetOneFeedback = (feedbackId: number | null) => {
  return useQuery<FeedbackModel, ErrorRes>(
    [queryKeys.REGION_ONE_FEEDBACK_VIEW, feedbackId],
    async () => {
      const res = await $api.get(`${endpoints.REGION_FEEDBACK_VIEW}?id=${feedbackId}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
      enabled: !!feedbackId,
    },
  )
}