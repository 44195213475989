import React from "react"

import DeleteIcon from "../../../../assets/icons/DeleteIcon"
import EditIcon from "../../../../assets/icons/EditIcon"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import { useAppDispatch } from "../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../store/reducers/regionProblemReducer"
import { useChangeStatusProblem, useDeleteProblem } from "../../services/mutations"
import { ProblemModel } from "../../utils/models/problemModel"

import styles from "./problem.module.scss"
import { Switch } from "antd"
import { endpoints } from "../../utils/constants/endpoints"

const Problem: React.FC<ProblemModel> = ({
  id,
  name_uz,
  name_ru,
  image_upload_mandatory,
  problem_type_id,
  status,
}) => {
  const dispatch = useAppDispatch()
  const deleteProblem = useDeleteProblem()
  const { setProblemModalData: setModalData } = regionProblemReducerActions
  const changeStatusProblem = useChangeStatusProblem()

  // stop propagation
  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
  }

  // change status
  const handleChangeStatus = () => {
    changeStatusProblem.mutate({ id: id, endpoint: endpoints.REGION_PROBLEM_STATUS })
  }

  // update
  const handleUpdate = () => {
    dispatch(
      setModalData({
        data: {
          id,
          name_uz,
          name_ru,
          image_upload_mandatory,
          problem_type_id,
          status,
        },
        visible: true,
        typeId: problem_type_id,
      })
    )
  }

  // delete
  const handleDelete = () => {
    return deleteProblem.mutateAsync(id)
  }

  return (
    <div className={styles.problem}>
      <div className={styles.left}>
        <p>{name_uz}</p>
        {image_upload_mandatory ? (
          <div className={styles.tag}>
            <span />
            <p>Rasm</p>
          </div>
        ) : null}
      </div>
      <div className={styles.right}>
        <div onClick={handleStopPropagation}>
          <Switch
            onClick={handleChangeStatus}
            checked={status}
            loading={changeStatusProblem.isLoading}
          ></Switch>
        </div>
        <div className={styles.actions}>
          <div className="f_div" onClick={handleUpdate}>
            <EditIcon />
          </div>
          <DeleteConfirm
            text={`${name} nomli muammoni o'chirmoqchimisiz?`}
            onConfirm={handleDelete}
            isLoading={deleteProblem.isLoading}
          >
            <div className="f_div">
              <DeleteIcon />
            </div>
          </DeleteConfirm>
        </div>
      </div>
    </div>
  )
}

export default Problem
