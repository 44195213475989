import React from "react"

const RedUnsuccessfullyCompleteIcon: React.FC = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" strokeWidth="8" />
      <path
        d="M36 26V22.8C36 21.1198 36 20.2798 35.673 19.638C35.3854 19.0735 34.9265 18.6146 34.362 18.327C33.7202 18 32.8802 18 31.2 18H24.8C23.1198 18 22.2798 18 21.638 18.327C21.0735 18.6146 20.6146 19.0735 20.327 19.638C20 20.2798 20 21.1198 20 22.8V33.2C20 34.8802 20 35.7202 20.327 36.362C20.6146 36.9265 21.0735 37.3854 21.638 37.673C22.2798 38 23.1198 38 24.8 38H26.5M29 27H24M27 31H24M32 23H24M35.25 33V31.25C35.25 30.2835 34.4665 29.5 33.5 29.5C32.5335 29.5 31.75 30.2835 31.75 31.25V33M31.6 37H35.4C35.9601 37 36.2401 37 36.454 36.891C36.6422 36.7951 36.7951 36.6422 36.891 36.454C37 36.2401 37 35.9601 37 35.4V34.6C37 34.0399 37 33.7599 36.891 33.546C36.7951 33.3578 36.6422 33.2049 36.454 33.109C36.2401 33 35.9601 33 35.4 33H31.6C31.0399 33 30.7599 33 30.546 33.109C30.3578 33.2049 30.2049 33.3578 30.109 33.546C30 33.7599 30 34.0399 30 34.6V35.4C30 35.9601 30 36.2401 30.109 36.454C30.2049 36.6422 30.3578 36.7951 30.546 36.891C30.7599 37 31.0399 37 31.6 37Z"
        stroke="#D92D20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default RedUnsuccessfullyCompleteIcon