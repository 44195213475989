export enum roles {
  REGION_ADMIN = "region_super_admin",
  MAIN_ORGANIZATION_SUPER_ADMIN = "main_organization_super_admin",
  MAIN_ORGANIZATION_ADMIN = "main_organization_admin",
  CITY_ADMIN = "city_admin",
  CITY_SUPER_ADMIN = "city_super_admin",
  ORGANIZATION_ADMIN = "organization_admin",
  ORGANIZATION_SUPER_ADMIN = "organization_super_admin",
  SUBSIDIARY_SUPER_ADMIN = "subsidiary_super_admin",
  SUBSIDIARY_ADMIN = "subsidiary_admin"
}
