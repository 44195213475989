import { queryKeys } from "../utils/constants/queryKeys"
import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endPoints"
import { CityModel } from "../utils/models/cityModel"
import { Pagination } from "../../../utils/models/paginationType"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

// get cities
export function useGetCities() {
  const url = addQueryParams(endpoints.REGION_CITY_VIEW)

  return useQuery<Pagination<CityModel>, ErrorRes>(
    [queryKeys.REGION_CITIES_VIEW, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    }, {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    })
}
