import { Button, Input } from "antd"
import React, { ReactNode, useMemo, useRef } from "react"

import PlusIcon from "../../assets/icons/PlusIcon"
import SearchIcon from "../../assets/icons/SearchIcon"
import CustomPagination from "../custom-pagination/CustomPagination"
import { useQueryParams } from "../../hooks/useQueryParams"
import { SEARCH } from "../../utils/constants/queryParamsKeys"

import styles from "./bodyLayout.module.scss"

type Props = {
  title: string
  children: ReactNode
  createButton?: {
    onCreate: () => void
    text: string
  }
  pagination?: {
    total: number | undefined
  }
}

const BodyLayout: React.FC<Props> = ({
  children,
  title,
  createButton,
  pagination,
}) => {
  const { append, remove } = useQueryParams()
  const timerRef = useRef<any>(null)

  // visible pagination
  const visiblePagination = useMemo(() => {
    return pagination && pagination.total && pagination.total > 10
  }, [pagination, pagination?.total])

  // change search
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value
    clearTimeout(timerRef.current)

    timerRef.current = setTimeout(() => {
      if (search.length >= 3) {
        append(SEARCH, search)
      }

      if (!search) {
        remove(SEARCH)
      }
    }, 800)
  }

  return (
    <div className={styles.layout}>
      <div className={styles.layout_header}>
        <p className={styles.title}>{title}</p>
      </div>
      <div className={styles.layout_body}>
        <div className={styles.layout_body_top}>
          <div className={styles.filter}>
            <Input className={styles.filter_search} onChange={handleChangeSearch} prefix={<SearchIcon />} />
          </div>
          {createButton && (
            <Button type="primary" onClick={createButton.onCreate}>
              <PlusIcon />
              <span>{createButton.text}</span>
            </Button>
          )}
        </div>
        <div className={styles.layout_body_center}>
          <div className={styles.body}>{children}</div>
        </div>
        {visiblePagination ? (
          <div className={styles.layout_body_bottom}>
            <CustomPagination
              total={pagination?.total}
              showSizeChanger={false}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BodyLayout
