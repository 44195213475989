export const lengthValidator = (
  length: number,
  handleSuccess?: () => void,
  handleError?: () => void,
) => ({
  validator(_: unknown, value: string) {
    if (value && value?.replace(/[+()_-\s]/g, "").length !== length) {
      handleError && handleError()
      return Promise.reject()
    }
    handleSuccess && handleSuccess()
    return Promise.resolve()
  },
  message: "",
})
