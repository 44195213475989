import React, { useState } from "react"
import { Image } from "antd"

import { getNameFromUrl } from "../../../../../utils/helpers/getNameFromUrl"

import styles from "../problemModal.module.scss"

type Props = {
  images: {
    id: number
    image: string
  }[] | undefined
}

const Images: React.FC<Props> = ({ images }) => {
  const [visiblePreview, setVisiblePreview] = useState(false)
  const [src, setSrc] = useState("")

  // open preview
  const handleOpenPreview = (imageSrc: string) => {
    setSrc(imageSrc)
    setVisiblePreview(true)
  }

  // visible change
  const handleVisibleChange = (visible: boolean) => {
    setVisiblePreview(visible)
  }

  return (
    <div className={styles.images}>
      <span className={styles.title}>Rasm</span>
      <div className={styles.images_cont}>
        {images?.map((item, index) => (
          <div className={styles.item} key={item.id}>
            <span>{index + 1}.&nbsp;</span>
            <p onClick={() => handleOpenPreview(item.image)}>{getNameFromUrl(item.image)}</p>
          </div>
        ))}
      </div>
      <Image
        preview={{
          visible: visiblePreview,
          onVisibleChange: handleVisibleChange,
          src,
        }}
        style={styles.image_preview}
      />
    </div>
  )
}

export default Images