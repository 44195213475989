import React, { useEffect } from "react"
import { Col, Form, Input, Modal, Row, Select } from "antd"

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { useGetOrganizationsSelect } from "../../services/queries"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"
import { useCreateEmployee, useUpdateEmployee } from "../../services/mutations"
import { EmployeeBodyModel } from "../../utils/models/employeeBodyModel"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"
import { mainOrganizationOrganizationReducerActions } from "../../../../store/reducers/mainOrganizationOrganizationReducer"

const EmployeeModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setEmployeeModalData } = mainOrganizationOrganizationReducerActions
  const { employeeModalData } = useAppSelector(
    (state) => state.mainOrganizationOrganizationReducer,
  )
  const { data: organizations, isLoading: organizationsIsLoading } =
    useGetOrganizationsSelect(employeeModalData.visible)
  const createEmployee = useCreateEmployee()
  const updateEmployee = useUpdateEmployee()

  // initial fields
  useEffect(() => {
    form.setFieldsValue({
      organization_id: employeeModalData.organizationId,
    })

    if (employeeModalData.data) {
      form.setFieldsValue({
        ...employeeModalData.data,
      })
    }
  }, [form, employeeModalData])

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setEmployeeModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // on finish
  const onFinish = (fields: EmployeeBodyModel) => {
    const req = { ...fields, phone: parsePhoneNumber(fields.phone) }

    if (employeeModalData.data) {
      updateEmployee.mutateAsync({ ...req, id: employeeModalData.data.id }).then(handleCancel)
    } else {
      createEmployee.mutateAsync(req).then(handleCancel)
    }
  }


  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title="Xodim yaratish"
      onCancel={handleCancel}
      open={employeeModalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      // className={styles.problem-accident-modal}
      okButtonProps={{
        size: "large",
        loading: createEmployee.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="full_name"
          label="To'liq ismi"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <PhoneInput
          name="phone"
          label="Telefon raqam"
          required={true}
        />
        <Form.Item
          name="organization_id"
          label="Tashkilot"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            loading={organizationsIsLoading}
            suffixIcon={<SelectSuffixIcon />}
            disabled
          >
            {organizations?.map((item) => (
              <Select.Option
                key={item.id}
                value={item.id}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={[5, 0]}>
          <Col flex={1}>
            <Form.Item name="login" label="Login" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="password" label="Parol" rules={[{ required: true, message: "" }]}>
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default EmployeeModal
