import React from "react"

import StatisticsLayout from "../../common/statistics-layout/StatisticsLayout"
import { useGetStatistics } from "./services/queries"
import StatisticsProblems from "../../common/statistics/problems/StatisticsProblems"
import StatisticsEvaluations from "../../common/statistics/evaluations/StatisticsEvaluations"
import StatisticsOrganizations from "./components/organizations/StatisticsOrganizations"
import StatisticsProblemClasses from "../../common/statistics/problem-classes/StatisticsProblemClasses"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  const { data, isLoading } = useGetStatistics()

  return (
    <StatisticsLayout>
      <div className={styles.statistics}>
        <div className={styles.statistics_left}>
          <StatisticsProblems problems={data?.troubles} isLoading={isLoading} />
          <StatisticsProblemClasses
            problem_classes={data?.classes}
            isLoading={isLoading}
          />
        </div>
        <div className={styles.statistics_right}>
          <StatisticsEvaluations
            evaluations={data?.evaluations}
            isLoading={isLoading}
          />
          <StatisticsOrganizations isModal={false} />
        </div>
      </div>
    </StatisticsLayout>
  )
}

export default Index
