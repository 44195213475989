import React, { useEffect, useMemo, useState } from "react"
import { Typography, Form, Select, FormInstance } from "antd"
import { UseMutationResult, useQueryClient, UseQueryResult } from "@tanstack/react-query"

import TagsModal from "./TagsModal"
import SettingsIcon from "../../assets/icons/SettingsIcon"
import { tagsToFormFields } from "../../features/app/utils/helpers/tagsToFormFields"
import SelectSuffixIcon from "../../assets/icons/SelectSuffixIcon"
import Spinner from "../spinner/Spinner"
import { ErrorRes, SuccessRes } from "../../utils/models/responseType"

const { Text } = Typography

type Props = {
  formInstance: FormInstance
  initialData?: {
    id: number
    name: string
  }[]
  useGetTags: () => UseQueryResult<{ id: number, name: string }[], ErrorRes>
  useCreateTag: () => UseMutationResult<SuccessRes<{ data: { id: number, name: string } }>, ErrorRes, {
    name: string
  }>
  useDeleteTag: () => UseMutationResult<SuccessRes, ErrorRes, { id: number }>
  queryKey: string
}

const Tags: React.FC<Props> = ({ formInstance, initialData, useGetTags, useCreateTag, useDeleteTag, queryKey }) => {
  const queryClient = useQueryClient()
  const { data: tags, isLoading } = useGetTags()
  const createTag = useCreateTag()
  const [searchText, setSearchText] = useState("")
  const [visibleModal, setVisibleModal] = useState(false)
  const tagsFormField = formInstance.getFieldValue("tag_ids")

  // initial data
  useEffect(() => {
    if (initialData && tags && !tagsFormField) {
      formInstance.setFieldsValue({
        tag_ids: tagsToFormFields(initialData),
      })
    }
  }, [initialData, tags])

  // select
  const handleSelect = (value: string, option: { key: string | undefined }) => {
    if (!option?.key) {
      const tags = formInstance.getFieldValue("tag_ids")

      createTag.mutateAsync({ name: value }).then(res => {
        queryClient.invalidateQueries([queryKey]).then(() => {
          tags[tags.length - 1] = JSON.stringify({
            label: res?.data?.name,
            value: res?.data?.id,
          })
        })
      })

      formInstance.setFieldValue("tag_ids", tags)
    }

    setSearchText("")
  }

  // search
  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  // existing tag
  const existingTag = useMemo(() => {
    return tags?.some(item => item.name.toLocaleLowerCase() === searchText.toLocaleLowerCase())
  }, [searchText, tags])

  // open modal
  const handleOpenModal = () => {
    setVisibleModal(true)
  }

  return (
    <>
      <Form.Item
        label={
          <>
            <span>Teglar</span>
            &nbsp;
            <SettingsIcon className="cursor-p" size={20} onClick={handleOpenModal} />
          </>
        }
        name="tag_ids"
        rules={[{ required: true, message: "" }]}
      >
        <Select
          mode="multiple"
          onSelect={handleSelect}
          onSearch={handleSearch}
          suffixIcon={(isLoading || createTag.isLoading) ? <Spinner /> : <SelectSuffixIcon />}
        >
          {(searchText && !existingTag) && (
            <Select.Option value={searchText}>
              <div className="d-f jc-sb">
              <span>
                {searchText}
              </span>
                <Text type="secondary">
                  + yangi qo'shish
                </Text>
              </div>
            </Select.Option>
          )}
          {tags?.map(item => (
            <Select.Option
              value={JSON.stringify({
                label: item.name,
                value: item.id,
              })}
              key={item.id}
            >
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <TagsModal
        tags={tags}
        visible={visibleModal}
        setVisible={setVisibleModal}
        useDeleteTag={useDeleteTag}
      />
    </>
  )
}

export default Tags