import React from "react"

import PlusIcon from "../../../../../assets/icons/PlusIcon"
import { useAppDispatch } from "../../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../../store/reducers/regionProblemReducer"

import styles from "../../../index.module.scss"

type Props = {
  typeId: number
}

const ProblemCreate: React.FC<Props> = ({ typeId }) => {
  const dispatch = useAppDispatch()
  const { setProblemModalData } = regionProblemReducerActions

  // open problem-accident-modal
  const handleOpenModal = () => {
    dispatch(
      setProblemModalData({
        visible: true,
        typeId,
      }),
    )
  }

  return (
    <>
      <div
        className={`${styles.create} ${styles.problem}`}
        onClick={handleOpenModal}
      >
        <PlusIcon />
        <p>Muammo qo’shish</p>
      </div>
    </>
  )
}

export default ProblemCreate
