import { Button, Popover } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'

import { ProblemTypeModel } from '../../../utils/models/problemTypeModel'
import DeleteIcon from '../../../../../assets/icons/DeleteIcon'
import DotsIcon from '../../../../../assets/icons/DotsIcon'
import EditIcon from '../../../../../assets/icons/EditIcon'
import DeleteConfirm from '../../../../../common/delete-confirm/DeleteConfirm'
import { useAppDispatch } from '../../../../../hooks/redux'
import { regionProblemReducerActions } from '../../../../../store/reducers/regionProblemReducer'
import { useDeleteProblemType } from '../../../services/mutations'

import styles from '../problemType.module.scss'

const ProblemTypeActions: React.FC<ProblemTypeModel> = ({
  id,
  name_uz,
  name_ru,
  problem_class_id,
  problems_count,
  status,
}) => {
  const dispatch = useAppDispatch()
  const deleteProblemType = useDeleteProblemType()
  const [visiblePopover, setVisiblePopover] = useState(false)
  const { setProblemTypeModalData: setModalData } = regionProblemReducerActions

  // close popover
  const handleClosePopover = useCallback(() => {
    visiblePopover && setVisiblePopover(false)
  }, [visiblePopover])

  // add event listener
  useEffect(() => {
    window.addEventListener('click', handleClosePopover)

    return () => {
      window.removeEventListener('click', handleClosePopover)
    }
  }, [visiblePopover, handleClosePopover])

  // stop propagation
  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
  }

  // open popover
  const handleOpenPopover = () => {
    setVisiblePopover(true)
  }

  // update
  const handleUpdate = () => {
    handleClosePopover()
    dispatch(
      setModalData({
        data: {
          id,
          name_uz,
          name_ru,
          problem_class_id,
          problems_count,
          status,
        },
        visible: true,
        classId: id,
      })
    )
  }

  // delete
  const handleDelete = () => {
    return deleteProblemType.mutateAsync(id)
  }

  return (
    <Popover
      content={
        <div className={styles.popover_content} onClick={handleStopPropagation}>
          <Button type="link" onClick={handleUpdate}>
            <EditIcon />
            <span>Tahrirlash</span>
          </Button>
          <DeleteConfirm
            text={`${name} nomli muammo turini o'chirmoqchimisiz?`}
            onConfirm={handleDelete}
            isLoading={deleteProblemType.isLoading}
          >
            <Button type="link" onClick={handleClosePopover}>
              <DeleteIcon />
              <span>O’chirish</span>
            </Button>
          </DeleteConfirm>
        </div>
      }
      open={visiblePopover}
      trigger="click"
      placement="left"
    >
      <div
        className="f_div"
        onClick={(e) => {
          handleStopPropagation(e)
          handleOpenPopover()
        }}
      >
        <DotsIcon />
      </div>
    </Popover>
  )
}

export default ProblemTypeActions
