import React, { useMemo } from "react"
import queryString from "query-string"
import { useNavigate } from "react-router-dom"
import { Table, Tabs, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import BodyLayout from "../../common/body-layout/BodyLayout"
import { useGetTroubles } from "./services/queries"
import { problemStatuses } from "../../utils/enums/problemStatuses"
import { isEmptyObject } from "../../utils/helpers/isEmptyObject"
import { STATUS } from "../../utils/constants/queryParamsKeys"
import { TroubleModel } from "./utils/models/troubleModel"
import { parseUnix } from "../../utils/helpers/parseUnix"
import { problemStatusLabels } from "../../utils/constants/problemStatusLabels"
import { useAppDispatch } from "../../hooks/redux"
import { regionTroubleReducerActions } from "../../store/reducers/regionTroubleReducer"
import ProblemModal from "./components/problem-modal/ProblemModal"

import styles from "./index.module.scss"
import dayjs from "dayjs"

const Index: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { setModalData } = regionTroubleReducerActions
  const { data, isLoading, isFetching } = useGetTroubles()

  // change tab
  const handleChangeTab = (activeKey: string) => {
    const query: { status?: string } = {}

    if (activeKey) {
      query.status = activeKey
    } else {
      delete query?.status
    }

    navigate({
      search: queryString.stringify(query),
    })
  }

  // columns
  const columns: ColumnsType<TroubleModel> = [
    {
      title: "Yaratilgan sana",
      dataIndex: "created_at",
      render: (value) => parseUnix(value),
    },
    {
      title: "Nomi",
      dataIndex: "problem",
      render: (value) => value.name,
    },
    {
      title: "Shahar/tumanlar",
      dataIndex: "city",
      render: (city) => city?.name,
    },
    {
      title: "Tashkilot",
      render: (record) => {
        if (record.organization) {
          return record.organization.name
        } else if (record.main_organization) {
          return record.main_organization.name
        }
        return "-"
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => (
        <span className={`${styles.troubles_status} status_${status}`}>
          {problemStatusLabels[status as problemStatuses]}
        </span>
      ),
    },
    {
      title: "Ijrochi",
      render: (record) => {
        if (record.organization_user) {
          return (
            <Tooltip title={record?.organization_user?.phone}>
              <span>{record?.organization_user?.full_name}</span>
            </Tooltip>
          )
        }
        if (record.main_organization_user) {
          return (
            <Tooltip title={record?.main_organization_user?.phone}>
              <span>{record?.main_organization_user?.full_name}</span>
            </Tooltip>
          )
        }
        if (record.subsidiary_user) {
          return (
            <Tooltip title={record?.subsidiary_user?.phone}>
              <span>{record?.subsidiary_user?.full_name}</span>
            </Tooltip>
          )
        }

        return "-"
      },
    },
    {
      title: "Tugatish muddati",
      render: (record) => {
        if (record.deadline) {
          return dayjs.unix(record?.deadline).format("DD.MM.YYYY")
        }

        return "-"
      },
    },
  ]

  // active key
  const activeKey = useMemo(() => {
    return (queryString.parse(location.search)[STATUS] as string) ?? ""
  }, [queryString.parse(location.search)[STATUS]])

  // on row
  const onRow = (record: TroubleModel) => {
    return {
      onClick: () => {
        dispatch(
          setModalData({
            visible: true,
            id: record?.id,
          })
        )
      },
    }
  }

  return (
    <BodyLayout title="Muammolar" pagination={{ total: data?.total }}>
      <Tabs
        onChange={handleChangeTab}
        className={styles.tabs}
        activeKey={activeKey}
        items={[
          { key: "", label: <span>Barchasi</span> },
          { key: problemStatuses.NEW, label: <span>Yangi</span> },
          {
            key: problemStatuses.REJECTED,
            label: <span>Qayta yo'naltirilgan</span>,
          },
          { key: problemStatuses.PROCESS, label: <span>Jarayonda</span> },
          { key: problemStatuses.COMPLETED, label: <span>Yakunlangan</span> },
          { key: problemStatuses.CLOSED, label: <span>Tasdiqlangan</span> },
          { key: problemStatuses.USER_REJECTED, label: <span>Tasdiqlanmagan</span> },
          {
            key: problemStatuses.DELETED,
            label: <span>Muvaffaqiyatsiz yakunlangan</span>,
          },
        ]}
      />
      <Table
        className={styles.troubles}
        columns={columns.filter((item) => !isEmptyObject(item))}
        dataSource={data?.data}
        pagination={false}
        rowKey={(record) => record.id}
        loading={isLoading || isFetching}
        onRow={onRow}
      />
      <ProblemModal />
    </BodyLayout>
  )
}

export default Index
