import React, { useState } from "react"
import { Dropdown, MenuProps, Select, TableColumnsType, Table } from "antd"

import { StatisticsCitiesModel } from "../../utils/models/statisticsCitiesModel"
import { StatisticsOrganizationsModel } from "../../utils/models/statisticsOrganizationsModel"
import { useGetStatisticsOrganizationsAndCities } from "../../services/queries"
import { useGetTagSelect } from "../../../app/services/queries"
import SelectSuffixIconBlue from "../../../../assets/icons/SelectSuffixIconBlue"
import StatisticsOrganizationsAndCitiesModal from "./modal/StatisticsOrganizationsAndCitiesModal"
import { useAppDispatch } from "../../../../hooks/redux"
import { regionStatisticsActions } from "../../../../store/reducers/regionStatisticsReducer"
import CustomPagination from "../../../../common/custom-pagination/CustomPagination"

import styles from "./statisticsOrganizationsAndCities.module.scss"

type Props = {
  isModal: boolean
}

const StatisticsOrganizationsAndCities: React.FC<Props> = ({ isModal }) => {
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()
  const { setModalData } = regionStatisticsActions
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const { data, isLoading } = useGetStatisticsOrganizationsAndCities(
    selectedTags,
    isModal,
    page
  )
  const { data: tags, isLoading: tagsIsLoading } = useGetTagSelect()
  const [activeKey, setActiveKey] = useState<string>("cities")

  // name
  const name = {
    cities: "Shaharlar va Tumanlar",
    organizations: "Tashkilotlar",
  }

  // change active key
  const handleChangeActiveKey = (activeKey: string) => {
    setActiveKey(activeKey)
    setSelectedTags([])
  }

  // columns
  const columns: TableColumnsType<
    StatisticsCitiesModel | StatisticsOrganizationsModel
  > = [
    {
      title: "№",
      width: 66,
      render: (_, __, index) => index + 1,
    },
    {
      title: name[activeKey as keyof typeof data],
      render: (_, payload) => payload.name,
    },
    {
      title: "Yangi",
      width: 100,
      align: "center",
      render: (_, payload) => (
        <span className="status_new">{payload.troubles?.new}</span>
      ),
    },
    {
      title: "Jarayonda",
      width: 100,
      align: "center",
      render: (_, payload) => (
        <span className="status_completed">{payload.troubles?.process}</span>
      ),
    },
    {
      title: "Yakunlandi",
      width: 100,
      align: "center",
      render: (_, payload) => (
        <span className="status_confirmed">{payload.troubles?.completed}</span>
      ),
    },
    {
      title: "Bajarildi",
      width: 100,
      align: "center",
      render: (_, payload) => (
        <span className="status_confirmed">{payload.troubles?.closed}</span>
      ),
    },
    {
      title: "Rad etilgan",
      width: 140,
      align: "center",
      render: (_, payload) => (
        <span className="status_rejected">{payload.troubles?.rejected}</span>
      ),
    },
    {
      title: "Qayta yo'naltirilgan",
      width: 150,
      align: "center",
      render: (_, payload) => (
        <span className="status_rejected">{payload.troubles?.user_rejected}</span>
      ),
    },
  ]

  // dropdown menu
  const dropdownMenu: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Tashkilotlar</span>,
      onClick: () => handleChangeActiveKey("organizations"),
    },
    {
      key: "2",
      label: <span>Shaharlar va tumanlar</span>,
      onClick: () => handleChangeActiveKey("cities"),
    },
  ]

  // open modal
  const handleOpenModal = () => {
    dispatch(
      setModalData({
        visible: true,
      })
    )
  }

  // change tags
  const handleChangeTags = (tags: string[]) => {
    setSelectedTags(tags)
    if (page > 1) {
      setPage(1)
    }
  }

  return (
    <div
      className={`${styles.organizations_or_cities} ${isModal ? styles.modal : ""}`}
    >
      <div className={styles.header}>
        <div className={styles.header_left}>
          {!isModal && <h2>Top 10</h2>}
          <Dropdown menu={{ items: dropdownMenu }} trigger={["click"]}>
            <p className={styles.dropdown_label}>
              <span>{name[activeKey as keyof typeof data]}</span>
              <SelectSuffixIconBlue />
            </p>
          </Dropdown>
        </div>
        <div className={styles.header_right}>
          {activeKey === "organizations" && (
            <Select
              value={selectedTags}
              placeholder="Teglar bo'yicha"
              mode="multiple"
              onChange={handleChangeTags}
              loading={tagsIsLoading}
            >
              {tags?.map((item) => (
                <Select.Option
                  key={item.id}
                  value={JSON.stringify({
                    label: item.name,
                    value: item.id,
                  })}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={data && data[activeKey as keyof typeof data]?.data}
        pagination={false}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <div className={styles.footer}>
        {isModal ? (
          data && (
            <CustomPagination
              total={data[activeKey as keyof typeof data]?.total}
              stateData={{
                page,
                setPage,
              }}
            />
          )
        ) : (
          <p onClick={handleOpenModal}>Barchasini ko’rish</p>
        )}
      </div>
      {!isModal && <StatisticsOrganizationsAndCitiesModal />}
    </div>
  )
}

export default StatisticsOrganizationsAndCities
