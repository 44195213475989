import React from "react"
import { Link, Outlet, useLocation } from "react-router-dom"

import Logo from "../../assets/images/logo.png"
import Profile from "../../features/profile/Index"
import { rootLinks } from "./rootLinks"
import { rootPaths } from "../../routing/root/rootPaths"

import styles from "./mainLayout.module.scss"
// import TelegramIcon from "../../assets/icons/TelegramIcon"

const MainLayout: React.FC = () => {
  const location = useLocation()

  // active
  const isActive = (path: string) => {
    if (location.pathname === path) return styles.active
    return ""
  }

  return (
    <div className={styles.layout}>
      <div className={styles.layout_navbar}>
        <div className={styles.layout_navbar_top}>
          <img alt="" src={Logo} />
        </div>
        <div className={styles.layout_navbar_center}>
          {rootLinks?.map((item) => (
            <Link
              to={item.path}
              className={`${isActive(item.path)} ${item.path === rootPaths.SETTINGS ? styles.last : ""}`}
              key={item.path}
            >
              <item.icon />
              <span>{item.text}</span>
            </Link>
          ))}
        </div>
        <div className={styles.layout_navbar_bottom}>
          <Profile />
        </div>
        <a className={styles.layout_help_center} href="https://t.me/+WbVicyEP19JmYjhi" target="_blank">
          {/*<TelegramIcon />*/}
          <span>
            Yordam markazi
          </span>
        </a>
      </div>
      <Outlet />
    </div>
  )
}

export default MainLayout
