import React from "react"
import dayjs from "dayjs"
import { Modal, Spin } from "antd"
import { useQueryClient } from "@tanstack/react-query"

import { useGetOneFeedback } from "../services/queries"
import CloseIcon from "../../../assets/icons/CloseIcon"
import { queryKeys } from "../utils/constants/queryKeys"
import { dayjsFormats } from "../../../utils/constants/dayjsFormats"

import styles from "./feedbackModal.module.scss"

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  feedbackId: number | null
  setFeedbackId: (feedbackId: number | null) => void
}

const FeedbackModal: React.FC<Props> = ({ visible, setVisible, feedbackId, setFeedbackId }) => {
  const { data, isLoading } = useGetOneFeedback(feedbackId)
  const queryClient = useQueryClient()

  // cancel
  const handleCancel = () => {
    setVisible(false)
  }

  // after close
  const handleAfterClose = () => {
    setFeedbackId(null)
    queryClient.invalidateQueries([queryKeys.REGION_FEEDBACKS_VIEW])
  }

  return (
    <Modal
      centered
      open={visible}
      onCancel={handleCancel}
      title={data?.user?.full_name}
      closeIcon={<CloseIcon />}
      className={styles.modal}
      footer={null}
      afterClose={handleAfterClose}
    >
      <Spin spinning={isLoading}>
        <div className={styles.modal_body}>
          <div className={styles.message}>
            <p className={styles.label}>Feedback:</p>
            <p className={styles.value}>{data?.message}</p>
            <p
              className={styles.date}
            >
              {data?.created_at && dayjs.unix(data?.created_at).format(`${dayjsFormats.DATE} ${dayjsFormats.TIME}`)}
            </p>
          </div>
          <div className={styles.phone}>
            <p className={styles.label}>Telefon raqam</p>
            <p className={styles.value}>{data?.user?.phone}</p>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default FeedbackModal