import { Button, Form, Input, Modal } from "antd"
import React, { ReactNode, useState } from "react"

import RedUnsuccessfullyCompleteIcon from "../../assets/icons/RedUnsuccessfullyCompleteIcon"

import styles from "./deleteConfirm.module.scss"

type Props = {
  children: ReactNode
  onConfirm: (comment: string) => Promise<unknown>
  isLoading: boolean
  text: string
}

const DeleteConfirm: React.FC<Props> = ({
                                          children,
                                          isLoading,
                                          onConfirm,
                                          text,
                                        }) => {
  const [formInstance] = Form.useForm()
  const [visible, setVisible] = useState(false)

  // open
  const handleOpen = () => {
    setVisible(true)
  }

  // close
  const handleClose = () => {
    setVisible(false)
  }

  // submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // finish
  const onFinish = (fields: { comment: string }) => {
    onConfirm(fields.comment).then(handleClose)
  }

  return (
    <>
      <Modal
        open={visible}
        closable={false}
        onCancel={handleClose}
        className={styles.confirm}
        centered
        footer={
          <>
            <Button onClick={handleClose}>Bekor qilish</Button>
            <Button
              type="primary"
              danger
              onClick={handleSubmit}
              loading={isLoading}
            >
              Yakunlash
            </Button>
          </>
        }
      >
        <div className={styles.body}>
          <RedUnsuccessfullyCompleteIcon />
          <p className={styles.text}>{text}</p>
          <Form layout="vertical" form={formInstance} onFinish={onFinish}>
            <Form.Item name="comment" rules={[{ required: true, message: "" }]} label="Izoh">
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <div
        onClick={handleOpen}
      >
        {children}
      </div>
    </>
  )
}

export default DeleteConfirm
