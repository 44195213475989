import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sliceNames } from "../../utils/constants/sliceNames";
import { OrganizationAdminsModel } from "../../features/organization-employees/utils/models/organizationAdminsModels";

const initialState: {
    organizationAdminsModalData: {
        visible: boolean,
        data?: OrganizationAdminsModel
    }
} = {
    organizationAdminsModalData: { visible: false }
}

const organizationAdminsReducer = createSlice({
    name: sliceNames.ORGANIZATION_ADMINS,
    initialState,
    reducers: {
        setOrganizationModalData: (
            state,
            action: PayloadAction<{
                visible: boolean,
                data?: OrganizationAdminsModel
            }>) => {
            state.organizationAdminsModalData = action.payload
        }
    }
})

export default organizationAdminsReducer.reducer
export const organizationAdminsReducerActions = organizationAdminsReducer.actions