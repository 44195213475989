import { Checkbox, Form, Input, Modal, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"

import { CheckboxChangeEvent } from "antd/es/checkbox"
import { useSubsidiarys } from "../../services/queries"
import {
  useCreateOrganizationAdmins,
  useUpdateOrganizationAdmin,
} from "../../services/mutation"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { organizationAdminsReducerActions } from "../../../../store/reducers/organizationAdminsReducer"
import { OrganizationAdminsModel } from "../../utils/models/organizationAdminsModels"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"

import styles from "./modal.module.scss"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"
import { isEmptyArray } from "../../../../utils/helpers/isEmptyArray"
import { roles } from "../../../../utils/enums/roles"

const EmployeeModal: React.FC = () => {
  const { data } = useSubsidiarys()
  const createOrgAdmin = useCreateOrganizationAdmins()
  const updateOrgAdmin = useUpdateOrganizationAdmin()
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { organizationAdminsModalData } = useAppSelector(
    (state) => state.organizationAdminsReducer,
  )
  const { setOrganizationModalData } = organizationAdminsReducerActions
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  const selectOptions = data?.data?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }))

  // is updating
  const isUpdating = useMemo(() => {
    return !!organizationAdminsModalData.data
  }, [organizationAdminsModalData.data])

  useEffect(() => {
    if (organizationAdminsModalData?.data) {
      form.setFieldsValue({
        ...organizationAdminsModalData?.data,
        subsidiary_ids: organizationAdminsModalData?.data?.subsidiaries?.map(
          (item) => item?.id,
        ),
      })
    }
  }, [organizationAdminsModalData?.data])

  // onCancel
  const onCancel = () => {
    dispatch(
      setOrganizationModalData({
        visible: false,
      }),
    )
  }

  //onFinish
  const onFinish = (values: OrganizationAdminsModel) => {
    const req = { ...values, phone: parsePhoneNumber(values.phone) }

    if (organizationAdminsModalData?.data) {
      updateOrgAdmin
        .mutateAsync({ ...req, id: organizationAdminsModalData?.data?.id })
        .then(onCancel)
    } else {
      createOrgAdmin.mutateAsync(req).then(onCancel)
    }
  }

  // handleSubmit
  const handleSubmit = () => {
    form.submit()
  }

  const afterClose = () => {
    form.resetFields()
    setIsChangingPassword(false)
  }

  // change is updating password
  const handleChangeIsUpdatingPassword = (e: CheckboxChangeEvent) => {
    setIsChangingPassword(e.target.checked)
  }

  // title
  const title = useMemo(() => {
    if (organizationAdminsModalData.data) return "Xodim tahrirlash"
    return "Xodim qo'shish"
  }, [organizationAdminsModalData.data])

  return (
    <Modal
      title={title}
      open={organizationAdminsModalData.visible}
      onCancel={onCancel}
      okText="Saqlash"
      cancelText="Bekor qilish"
      className={styles.modal}
      afterClose={afterClose}
      centered
      okButtonProps={{
        onClick: handleSubmit,
        loading: createOrgAdmin.isLoading || updateOrgAdmin.isLoading,
      }}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          label="To'liq ismi"
          name={"full_name"}
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <PhoneInput name="phone" label="Telefon raqam" required={true} />
        <Form.Item
          label="Lavozimi"
          name={"role"}
          rules={[{ required: true, message: "" }]}
        >
          <Select>
            <Select.Option value={roles.ORGANIZATION_SUPER_ADMIN}>
              Admin
            </Select.Option>
            <Select.Option value={roles.ORGANIZATION_ADMIN}>
              Xodim
            </Select.Option>
          </Select>
        </Form.Item>
        {!isEmptyArray(data?.data) && (
          <Form.Item
            label="Shirkat"
            name={"subsidiary_ids"}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              mode="multiple"
              options={selectOptions}
            />
          </Form.Item>
        )}
        <div className={styles.auth}>
          <Form.Item
            label="Login"
            name={"login"}
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          {(isChangingPassword || !isUpdating) && (
            <Form.Item
              label="Parol"
              name={"password"}
              rules={[{ required: true, message: "" }]}
            >
              <Input.Password />
            </Form.Item>
          )}
        </div>
        {isUpdating && (
          <Form.Item className={styles.change_password}>
            <Checkbox checked={isChangingPassword} onChange={handleChangeIsUpdatingPassword}>Parolni
              yangilash</Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default EmployeeModal
