import { endpoints } from "../utils/constants/endpoints"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { StatisticsModel } from "../utils/models/statisticsModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { StatisticsOrganizationsModel } from "../utils/models/statisticsOrganizationsModel"
import { StatisticsCitiesModel } from "../utils/models/statisticsCitiesModel"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"
import { Pagination } from "../../../utils/models/paginationType"

// get statistics
export const useGetStatistics = () => {
  const url = addQueryParams(endpoints.REGION_STATISTICS)

  return useQuery<StatisticsModel, ErrorRes>(
    [queryKeys.REGION_STATISTICS, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get statistics organizations and cities
export const useGetStatisticsOrganizationsAndCities = (tags: string[], isModal: boolean, page: number) => {
  const url = addQueryParams(
    endpoints.REGION_STATISTICS_ORGANIZATIONS_CITIES,
    {
      "tag_ids[]": tags.map(item => JSON.parse(item)?.value),
      page: page,
    },
  )

  return useQuery<{
    organizations: Pagination<StatisticsOrganizationsModel>,
    cities: Pagination<StatisticsCitiesModel>
  }, ErrorRes>(
    [queryKeys.REGION_STATISTICS_ORGANIZATIONS_CITIES, url, isModal],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}