import React, { ReactNode, useMemo } from "react"
import dayjs, { Dayjs } from "dayjs"
import weekday from "dayjs/plugin/weekday"
import { useLocation, useNavigate } from "react-router-dom"
import queryString from "query-string"

import DatePicker from "../../utils/helpers/datePicker"
import { dayjsFormats } from "../../utils/constants/dayjsFormats"
import { dayjsUnits } from "../../utils/enums/dayjsUnits"

import styles from "./statisticsLayout.module.scss"

type Props = {
  children: ReactNode
}

dayjs.extend(weekday)

const StatisticsLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const query = queryString.parse(location.search)

  // default value
  const defaultValue: [Dayjs, Dayjs] | undefined = useMemo(() => {
    if (query?.start_at && query?.finish_at) {
      return [dayjs.unix(Number(query?.start_at)), dayjs.unix(Number(query?.finish_at))]
    }
    return undefined
  }, [query])

  // change range
  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      navigate({
        search: queryString.stringify({
          start_at: dayjs(dates[0]).unix(),
          finish_at: dayjs(dates[1]).unix(),
        }),
      })
    } else {
      const newQuery = query
      delete newQuery.start_at
      delete newQuery?.finish_at

      navigate({
        search: queryString.stringify(newQuery),
      })
    }
  }

  // range presets
  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: "Bugun", value: [dayjs(), dayjs()] },
    { label: "Kecha", value: [dayjs().add(-1, dayjsUnits.DAY), dayjs().add(-1, dayjsUnits.DAY)] },
    { label: "Shu hafta", value: [dayjs().weekday(0), dayjs().weekday(6)] },
    { label: "O'tgan hafta", value: [dayjs().weekday(-7), dayjs().weekday(-1)] },
    { label: "Shu oy", value: [dayjs().startOf(dayjsUnits.MONTH), dayjs().endOf(dayjsUnits.MONTH)] },
    {
      label: "O'tgan oy",
      value: [dayjs().add(-1, dayjsUnits.MONTH).startOf(dayjsUnits.MONTH), dayjs().add(-1, dayjsUnits.MONTH).endOf(dayjsUnits.MONTH)],
    },
    { label: "Shu yil", value: [dayjs().startOf(dayjsUnits.YEAR), dayjs().endOf(dayjsUnits.YEAR)] },
    {
      label: "O'tgan yil",
      value: [dayjs().add(-1, dayjsUnits.YEAR).startOf(dayjsUnits.YEAR), dayjs().add(-1, dayjsUnits.YEAR).endOf(dayjsUnits.YEAR)],
    },
    {
      label: "Barcha vaqtlar",
      value: [dayjs().startOf(dayjsUnits.YEAR), dayjs()],
    },
  ]

  return (
    <div className={styles.layout}>
      <div className={styles.layout_header}>
        <p className={styles.title}>Statistika</p>
        <DatePicker.RangePicker
          placeholder={["Boshlanish vaqti", "Tugash vaqti"]}
          presets={rangePresets}
          onChange={onRangeChange}
          format={dayjsFormats.DATE}
          defaultValue={defaultValue}
        />
      </div>
      <div className={styles.layout_body}>
        {children}
      </div>
    </div>
  )
}

export default StatisticsLayout