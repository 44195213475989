import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"


const initialState: {
  modalData: {
    visible: boolean,
  }
} = {
  modalData: {
    visible: false,
  },
}

const regionStatisticsReducer = createSlice({
  name: sliceNames.REGION_STATISTICS,
  initialState,
  reducers: {
    setModalData: (state, action: PayloadAction<{
      visible: boolean,
    }>) => {
      state.modalData = action.payload
    },
  },
})

export default regionStatisticsReducer.reducer
export const regionStatisticsActions = regionStatisticsReducer.actions