import { Form, Input, Modal } from "antd"
import React from "react"

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { useRejectProblem } from "../../services/mutations"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import RedRejectIcon from "../../../../assets/icons/RedRejectIcon"
import { mainOrganizationProblemReducerActions } from "../../../../store/reducers/mainOrganizationProblemReducer"

import styles from "./rejectModal.module.scss"

const RejectModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setRejectModalData: setModalData } = mainOrganizationProblemReducerActions
  const { rejectModalData: modalData } = useAppSelector(state => state.mainOrganizationProblemReducer)
  const rejectProblem = useRejectProblem()

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // finish
  const onFinish = (fields: { comment: string }) => {
    rejectProblem.mutateAsync({ ...fields, id: modalData.id as number }).then(handleCancel)
  }

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Qayta yo'naltirish"
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      className={styles.modal}
      okButtonProps={{
        size: "large",
        loading: rejectProblem.isLoading,
        danger: true,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <div className={styles.modal_content}>
        <div className={styles.top}>
          <RedRejectIcon />
          <p>Qayta yo'naltirish</p>
          <span>
            Muammo qayta yo'naltirishga yuborilgandan keyin mutahasislar tomonidan bu muammo qayta o'rganib chiqiladi
          </span>
        </div>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item label="Izoh*" name="comment" rules={[{ required: true, message: "" }]}>
            <Input />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default RejectModal
