import React from "react"
import { Form, Input } from "antd"

import styles from "./fakePassword.module.scss"

const FakePassword: React.FC = () => {
  return (
    <div className={styles.fake}>
      <Form.Item name="llogin" label="login">
        <Input />
      </Form.Item>
      <Form.Item name="fake_password">
        <Input.Password />
      </Form.Item>
    </div>
  )
}

export default FakePassword