import React from "react"

import PlusIcon from "../../../../../assets/icons/PlusIcon"
import { useAppDispatch } from "../../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../../store/reducers/regionProblemReducer"

import styles from "../../../index.module.scss"

type Props = {
  classId: number
}

const ProblemTypeCreate: React.FC<Props> = ({ classId }) => {
  const dispatch = useAppDispatch()
  const { setProblemTypeModalData } = regionProblemReducerActions

  // open problem-accident-modal
  const handleOpenModal = () => {
    dispatch(setProblemTypeModalData({ visible: true, classId }))
  }

  return (
    <>
      <div className={`${styles.create} ${styles.type}`} onClick={handleOpenModal}>
        <PlusIcon />
        <p>Muammo turi qo’shish</p>
      </div>
    </>
  )
}

export default ProblemTypeCreate
