import axios from "axios"
import { TOKEN } from "../utils/constants/localStorageKeys"
import { LocalStorage } from "./LocalStorage"
import { isBuilding } from "../utils/helpers/isBuilding"
import { rootPaths } from "../routing/root/rootPaths"

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

$api.interceptors.request.use((config) => {
  if (config.headers && !isBuilding) {
    config.headers.Authorization = LocalStorage.get(TOKEN)
  }
  return config
})


$api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.pathname !== rootPaths.AUTH && (
        window.location.href = window.location.origin
      )
    }
    return Promise.reject(error)
  },
)


export default $api
