import { Button, Checkbox, Form, Input, Modal, Select } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { CheckboxChangeEvent } from "antd/es/checkbox"

import { useCreateMainOrganizationAdmin, useUpdateMainOrganizationAdmin } from "../../services/mutation"
import { useGetMainOrganizationOrganizations } from "../../services/queries"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { mainOrganizationEmployeeReducerActions } from "../../../../store/reducers/mainOrganizationEmployeeReducer"
import { MainOrganizationEmployeeModel } from "../../utils/models/MainOrganizationEmployeeModel"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"
import { parsePhoneNumber } from "../../../../utils/helpers/parsePhoneNumber"

import styles from "./modal.module.scss"
import { roles } from "../../../../utils/enums/roles"

const MainOrganizationEmployeeModal: React.FC = () => {
  const [form] = Form.useForm()
  const createMainOrganizationAdmin = useCreateMainOrganizationAdmin()
  const { data: mainOrganizationOrganization } = useGetMainOrganizationOrganizations()
  const { mainOrganizationEmployeeModalData } = useAppSelector(
    (state) => state.mainOrganizationEmployeeReducer,
  )
  const dispatch = useAppDispatch()
  const { setMainOrganizationEmployeeModalData: setModalOpen } = mainOrganizationEmployeeReducerActions
  const updateMainOrganizationAdmin = useUpdateMainOrganizationAdmin()
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  // is updating
  const isUpdating = useMemo(() => {
    return !!mainOrganizationEmployeeModalData.data
  }, [mainOrganizationEmployeeModalData.data])


  const selectOptions = mainOrganizationOrganization?.data
    ?.filter((item) => item?.my_organization === true)
    ?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }))

  // onCancel
  const onCancel = () => {
    dispatch(
      setModalOpen({
        visible: false,
      }),
    )
  }

  useEffect(() => {
    if (mainOrganizationEmployeeModalData.data) {
      form.setFieldsValue({
        ...mainOrganizationEmployeeModalData.data,
        organization_ids: mainOrganizationEmployeeModalData?.data?.organizations.map(
          (item) => item.id,
        ),
      })
    }
  }, [form, mainOrganizationEmployeeModalData.data])

  //onFinish
  const onFinish = (values: MainOrganizationEmployeeModel) => {
    const req = {
      ...values,
      phone: parsePhoneNumber(values.phone),
    }

    if (mainOrganizationEmployeeModalData.data) {
      updateMainOrganizationAdmin
        .mutateAsync({ ...req, id: mainOrganizationEmployeeModalData?.data?.id })
        .then(onCancel)
    } else {
      createMainOrganizationAdmin.mutateAsync(req).then(onCancel)
    }
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
    setIsChangingPassword(false)
  }

  // change is updating password
  const handleChangeIsUpdatingPassword = (e: CheckboxChangeEvent) => {
    setIsChangingPassword(e.target.checked)
  }

  // title
  const title = useMemo(() => {
    if (mainOrganizationEmployeeModalData.data) return "Xodim tahrirlash"
    return "Xodim qo'shish"
  }, [mainOrganizationEmployeeModalData.data])

  return (
    <Modal
      title={title}
      open={mainOrganizationEmployeeModalData.visible}
      onCancel={onCancel}
      className={styles.modal}
      footer={false}
      centered
      afterClose={handleAfterClose}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          label="To'liq ismi"
          name={"full_name"}
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <PhoneInput name="phone" label="Telefon raqam" required={true} />
        <Form.Item
          label="Lavozimi"
          name={"role"}
          rules={[{ required: true, message: "" }]}
        >
          <Select>
            <Select.Option value={roles.MAIN_ORGANIZATION_SUPER_ADMIN}>
                Admin
            </Select.Option>
            <Select.Option value={roles.MAIN_ORGANIZATION_ADMIN}>
              Xodim
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Tashkilot"
          name={"organization_ids"}
          // rules={[{ required: true, message: "" }]}
        >
          <Select
            mode="multiple"
            options={selectOptions}
          />
        </Form.Item>
        <div className={styles.auth}>
          <Form.Item
            label="Login"
            name={"login"}
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          {(isChangingPassword || !isUpdating) && (
            <Form.Item
              label="Parol"
              name={"password"}
              rules={[{ required: true, message: "" }]}
            >
              <Input.Password />
            </Form.Item>
          )}
        </div>
        {isUpdating && (
          <Form.Item className={styles.change_password}>
            <Checkbox checked={isChangingPassword} onChange={handleChangeIsUpdatingPassword}>Parolni
              yangilash</Checkbox>
          </Form.Item>
        )}
        <div className={styles.btns}>
          <Button onClick={onCancel}>Bekor qilish</Button>
          <Button htmlType="submit" loading={createMainOrganizationAdmin.isLoading || updateMainOrganizationAdmin.isLoading}>
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default MainOrganizationEmployeeModal
