import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"
import { AccidentModel } from "../../features/city-accidents/utils/models/accidentModel"


const initialState: {
  modalData: {
    visible: boolean,
    data?: AccidentModel
  }
} = {
  modalData: {
    visible: false,
  },
}

const cityAccidentReducer = createSlice({
  name: sliceNames.CITY_ACCIDENT,
  initialState,
  reducers: {
    setModalData: (state, action: PayloadAction<{ visible: boolean, data?: AccidentModel }>) => {
      state.modalData = action.payload
    },
  },
})

export default cityAccidentReducer.reducer
export const cityAccidentReducerActions = cityAccidentReducer.actions