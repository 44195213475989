import { AxiosError } from "axios"
import { messageTypes } from "../enums/messageTypes"
import { showMessage } from "./showMessage"

export const errorHandler = (err: AxiosError<{ message?: { uz: string } }>) => {
  const message = err?.response?.data?.message?.uz

  if (message) {
    showMessage(message, messageTypes.ERROR)
  } else {
    showMessage("Xatolik", messageTypes.ERROR)
  }
}

