import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { queryKeys } from "../utils/constants/queryKeys"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// complete problem
export function useCompleteProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(endpoints.SUBSIDIARY_PROBLEM_COMPLETE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.SUBSIDIARY_PROBLEMS_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// reject problem
export function useRejectProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number, comment?: string }>(
    async (req) => {
      const res = await $api.post(endpoints.SUBSIDIARY_PROBLEM_REJECT, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.SUBSIDIARY_PROBLEMS_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// attach problem
export function useAttachProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, {
    "subsidiary_user_id": number
    "id": number
    "deadline": number
    confirmed: number
  }>(
    async (req) => {
      const res = await $api.put(endpoints.SUBSIDIARY_PROBLEM_ATTACH, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.SUBSIDIARY_PROBLEMS_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete problem
export function useDeleteProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.SUBSIDIARY_PROBLEM_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.SUBSIDIARY_PROBLEMS_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// send message
export function useSendMessage() {
  return useMutation<SuccessRes, ErrorRes, { trouble_id: number, message: string }>(
    async (req) => {
      const res = await $api.post(endpoints.SUBSIDIARY_PROBLEM_SEND_MESSAGE, req)
      return res.data
    },
    {
      onError: errorHandler,
    },
  )
}