import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"


const initialState: {
  modalData: {
    visible: boolean
  }
} = {
  modalData: {
    visible: false,
  },
}

const cityStatisticsReducer = createSlice({
  name: sliceNames.CITY_STATISTICS,
  initialState,
  reducers: {
    setModalData: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.modalData = action.payload
    },
  },
})

export default cityStatisticsReducer.reducer
export const cityStatisticsReducerActions = cityStatisticsReducer.actions