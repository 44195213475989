import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sliceNames } from "../../utils/constants/sliceNames";
import { SubsidiaryAdminModel } from "../../features/subsidiary-employees/utils/models/subsidiaryAdminModel";

const initialState: {
    subsidiaryModalData: {
        visible: boolean,
        data?: SubsidiaryAdminModel
    }
} = {
    subsidiaryModalData: {
        visible: false
    }
}

export const subsidiaryReducer = createSlice({
    name: sliceNames.SUBSIDIARY,
    initialState,
    reducers: {
        setSubsidiary: (
            state,
            action: PayloadAction<{
                visible: boolean,
                data?: SubsidiaryAdminModel
            }>) => {
            state.subsidiaryModalData = action.payload
        }
    }
})

export default subsidiaryReducer.reducer
export const subsidiaryReducerActions = subsidiaryReducer.actions