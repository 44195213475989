import React, { useEffect } from "react"
import { TreeSelect, Form, FormInstance } from "antd"
import queryString from "query-string"

import SelectSuffixIcon from "../../assets/icons/SelectSuffixIcon"
import { useGetProblemSelect } from "../../features/app/services/queries"
import Spinner from "../spinner/Spinner"

import styles from "./treeSelectProblems.module.scss"

type Props = {
  enabled: boolean
  initialData?: string[]
  formInstance: FormInstance
  endpoint: string
}

const TreeSelectProblems: React.FC<Props> = ({ enabled, initialData, formInstance, endpoint }) => {
  const { data, isLoading } = useGetProblemSelect(enabled, endpoint)

  // initial data
  useEffect(() => {
    if (initialData && data) {
      formInstance.setFieldsValue({
        problems: initialData,
      })
    }
  }, [initialData, formInstance, data])

  // tree data
  const treeData = data?.map(problem_class => {
    return {
      title: problem_class.name_uz,
      disabled: "selected" in problem_class && !problem_class.selected,
      value: queryString.stringify({
        name: problem_class.name_uz,
        key: "problem_class_ids",
        id: problem_class.id,
      }),
      children: problem_class.problem_types.map(problem_type => {
        return {
          title: problem_type.name,
          disabled: "selected" in problem_type && !problem_type.selected,
          value: queryString.stringify({
            name: problem_type.name,
            key: "problem_type_ids",
            id: problem_type.id,
          }),
          children: problem_type.problems.map(item => {
            return {
              title: item.name,
              disabled: "selected" in item && !item.selected,
              value: queryString.stringify({
                name: item.name,
                key: "problem_ids",
                id: item.id,
              }),
            }
          }),
        }
      }),
    }
  })


  return (
    <Form.Item name="problems" label="Muammolar" rules={[{ required: true, message: "" }]}>
      <TreeSelect
        className={styles.tree_select}
        treeData={treeData}
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_ALL}
        loading={isLoading}
        suffixIcon={isLoading ? <Spinner /> : <SelectSuffixIcon />}
      />
    </Form.Item>
  )
}


export default TreeSelectProblems