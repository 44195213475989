import React from "react"
import styles from "./navbar.module.scss"

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <span>Sozlamalar</span>
    </div>
  )
}

export default Navbar
