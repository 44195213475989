import React, { useCallback, useEffect, useState } from "react"
import { Button, Popover } from "antd"

import EditIcon from "../../../../../assets/icons/EditIcon"
import DeleteConfirm from "../../../../../common/delete-confirm/DeleteConfirm"
import DeleteIcon from "../../../../../assets/icons/DeleteIcon"
import DotsIcon from "../../../../../assets/icons/DotsIcon"
import { SubsidiaryModel } from "../../../utils/models/subsidiaryModel"
import { useAppDispatch } from "../../../../../hooks/redux"
import { organizationSubsidiaryReducerActions } from "../../../../../store/reducers/organizationSubsidiaryReducer"
import { useDeleteSubsidiary } from "../../../services/mutations"

import styles from "../../../../../styles/actions-popover/actionsPopover.module.scss"

const SubsidiaryFolderActions: React.FC<SubsidiaryModel> = (props) => {
  const { name, id } = props
  const dispatch = useAppDispatch()
  const deleteSubsidiary = useDeleteSubsidiary()
  const [visiblePopover, setVisiblePopover] = useState(false)
  const { setSubsidiaryModalData: setModalData } = organizationSubsidiaryReducerActions

  // close popover
  const handleClosePopover = useCallback(() => {
    visiblePopover && setVisiblePopover(false)
  }, [visiblePopover])

  // add event listener
  useEffect(() => {
    window.addEventListener("click", handleClosePopover)

    return () => {
      window.removeEventListener("click", handleClosePopover)
    }
  }, [visiblePopover, handleClosePopover])

  // stop propagation
  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
  }

  // open popover
  const handleOpenPopover = () => {
    setVisiblePopover(true)
  }

  // update
  const handleUpdate = () => {
    handleClosePopover()
    dispatch(
      setModalData({
        visible: true,
        id,
      }),
    )
  }

  // delete
  const handleDelete = () => {
    return deleteSubsidiary.mutateAsync(id)
  }

  return (
    <div className={styles.actions_popover}>
      <Popover
        onOpenChange={setVisiblePopover}
        content={
          <div className={styles.popover_content} onClick={handleStopPropagation}>
            <Button type="link" onClick={handleUpdate}>
              <EditIcon />
              <span>Tahrirlash</span>
            </Button>
            <DeleteConfirm
              text={`${name} nomli shirkatni o'chirmoqchimisiz?`}
              onConfirm={handleDelete}
              isLoading={deleteSubsidiary.isLoading}
            >
              <Button type="link" onClick={handleClosePopover}>
                <DeleteIcon />
                <span>O’chirish</span>
              </Button>
            </DeleteConfirm>
          </div>
        }
        open={visiblePopover}
        trigger="click"
        placement="left"
      >
        <div
          className="f_div"
          onClick={(e) => {
            handleStopPropagation(e)
            handleOpenPopover()
          }}
        >
          <DotsIcon />
        </div>
      </Popover>
    </div>
  )
}

export default SubsidiaryFolderActions