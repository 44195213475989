import { Button } from "antd"
import React from "react"

import DeleteIcon from "../../../../../assets/icons/DeleteIcon"
import DotsIcon from "../../../../../assets/icons/DotsIcon"
import EditIcon from "../../../../../assets/icons/EditIcon"
import DeleteConfirm from "../../../../../common/delete-confirm/DeleteConfirm"
import { useAppDispatch } from "../../../../../hooks/redux"
import { regionProblemReducerActions } from "../../../../../store/reducers/regionProblemReducer"
import { useDeleteProblemClass } from "../../../services/mutations"
import { ProblemClassModel } from "../../../utils/models/problemClassModel"
import PopoverActions from "../../../../../common/popover-actions/PopoverActions"

import styles from "../problemClass.module.scss"

const ProblemClassActions: React.FC<ProblemClassModel> = ({
                                                            id,
                                                            image,
                                                            name,
                                                            status,
                                                            problem_types_count,
                                                            color,
                                                            name_ru,
                                                            name_uz
                                                          }) => {
  const dispatch = useAppDispatch()
  const deleteProblemClass = useDeleteProblemClass()
  const { setProblemClassModalData: setModalData } = regionProblemReducerActions

  // update
  const handleUpdate = () => {
    dispatch(
      setModalData({
        data: {
          id,
          name,
          name_ru,
          name_uz,
          image,
          status,
          problem_types_count,
          color,
        },
        visible: true,
      }),
    )
  }

  // delete
  const handleDelete = () => {
    return deleteProblemClass.mutateAsync(id)
  }

  // content
  const content = (
    <div className={styles.popover_content}>
      <Button type="link" onClick={handleUpdate}>
        <EditIcon />
        <span>Tahrirlash</span>
      </Button>
      {problem_types_count === 0 && (
        <DeleteConfirm
          text={`${name} nomli muammo sinfini o'chirmoqchimisiz?`}
          onConfirm={handleDelete}
          isLoading={deleteProblemClass.isLoading}
        >
          <Button type="link">
            <DeleteIcon />
            <span>O’chirish</span>
          </Button>
        </DeleteConfirm>
      )}
    </div>
  )

  return (
    <PopoverActions content={content}>
      <DotsIcon />
    </PopoverActions>
  )
}

export default ProblemClassActions
