import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { SubsidiaryBodyModel } from "../utils/models/subsidiaryBodyModel"
import { EmployeeBodyModel } from "../utils/models/employeeBodyModel"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// create subsidiary
export function useCreateSubsidiary() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, SubsidiaryBodyModel>(
    async (req) => {
      const res = await $api.post(endpoints.ORGANIZATION_SUBSIDIARY_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.ORGANIZATION_SUBSIDIARY_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// update subsidiary
export function useUpdateSubsidiary() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, SubsidiaryBodyModel & { id: number }>(
    async (req) => {
      const res = await $api.put(endpoints.ORGANIZATION_SUBSIDIARY_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.ORGANIZATION_SUBSIDIARY_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete subsidiary
export function useDeleteSubsidiary() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.ORGANIZATION_SUBSIDIARY_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.ORGANIZATION_SUBSIDIARY_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// create employee
export function useCreateEmployee() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, EmployeeBodyModel>(
    async (req) => {
      const res = await $api.post(endpoints.ORGANIZATION_SUBSIDIARY_EMPLOYEE_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.ORGANIZATION_SUBSIDIARY_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// update employee
export function useUpdateEmployee() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, EmployeeBodyModel & { id: number }>(
    async (req) => {
      const res = await $api.put(endpoints.ORGANIZATION_SUBSIDIARY_EMPLOYEE_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.ORGANIZATION_SUBSIDIARY_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete employee
export function useDeleteEmployee() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.ORGANIZATION_SUBSIDIARY_EMPLOYEE_DELETE}?id=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.ORGANIZATION_SUBSIDIARY_VIEW])
      },
      onError: errorHandler,
    },
  )
}