import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import $api from "../../../services/RequestService"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"

// attach problem
export function useAttachProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, {
    organization_id?: number
    "id": number
  }>(
    async (req) => {
      const res = await $api.put(endpoints.CITY_PROBLEMS_ATTACH, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_PROBLEMS_VIEW])
      },
      onError: errorHandler,
    },
  )
}

// delete problem
export function useDeleteProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number, comment: string }>(
    async (req) => {
      const res = await $api.delete(endpoints.CITY_PROBLEMS_DELETE, { data: req })
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.CITY_PROBLEMS_VIEW])
      },
      onError: errorHandler,
    },
  )
}