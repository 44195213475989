import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"
import { CityEmployeeModel } from "../../features/city-employees/utils/models/CityEmployeeModel"

const initialState: {
  cityEmployeeModalData: { visible: boolean; data?: CityEmployeeModel; }
} = {
  cityEmployeeModalData: { visible: false },
}

const cityEmployeeReducer = createSlice({
  name: sliceNames.CITY_EMPLOYEE,
  initialState,
  reducers: {
    setCityEmployeeModalData: (state, action: PayloadAction<
      {
        visible: boolean,
        data?: CityEmployeeModel
      }
    >) => {
      state.cityEmployeeModalData = action.payload
    },
  },
})

export default cityEmployeeReducer.reducer
export const cityEmployeereducerActions = cityEmployeeReducer.actions