import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { sliceNames } from "../../utils/constants/sliceNames"

const initialState: {
  completeModalData: {
    visible: boolean
    id?: number
  }
  rejectModalData: {
    visible: boolean
    id?: number
  }
  modalData: {
    visible: boolean
    id?: number
  }
} = {
  completeModalData: {
    visible: false,
  },
  rejectModalData: {
    visible: false,
  },
  modalData: {
    visible: false,
  },
}

const mainOrganizationProblemReducer = createSlice({
  name: sliceNames.MAIN_ORGANIZATION_PROBLEM,
  initialState,
  reducers: {
    setModalData: (state, action: PayloadAction<{ visible: boolean, id?: number }>) => {
      state.modalData = action.payload
    },
    setRejectModalData: (state, action: PayloadAction<{ visible: boolean, id?: number }>) => {
      state.rejectModalData = action.payload
    },
    setCompleteModalData: (state, action: PayloadAction<{ visible: boolean, id?: number }>) => {
      state.completeModalData = action.payload
    },
  },
})

export default mainOrganizationProblemReducer.reducer
export const mainOrganizationProblemReducerActions = mainOrganizationProblemReducer.actions