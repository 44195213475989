import { Collapse, Switch } from "antd"
import React from "react"

import CollapseExpandIcon from "../../../../assets/icons/CollapseExpandIcon"
import SubfolderIcon from "../../../../assets/icons/SubfolderIcon"
import { ProblemTypeModel } from "../../utils/models/problemTypeModel"
import ProblemCreate from "../problem/create/ProblemCreate"
import Problem from "../problem/Problem"
import ProblemTypeActions from "./actions/ProblemTypeActions"

import styles from "./problemType.module.scss"
import { endpoints } from "../../utils/constants/endpoints"
import { useChangeStatusProblem } from "../../services/mutations"

const ProblemType: React.FC<ProblemTypeModel> = (props) => {
  const { id, name_uz, name_ru, problems, problems_count, status } = props
  const changeStatusProblem = useChangeStatusProblem()

  // stop propagation
  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
  }

  // change status
  const handleChangeStatus = () => {
    changeStatusProblem.mutate({ id: id, endpoint: endpoints.REGION_PROBLEM_TYPE_STATUS })
  }

  return (
    <Collapse
      ghost
      className={styles.type}
      expandIcon={() => <CollapseExpandIcon />}
    >
      <Collapse.Panel
        className="type"
        key={id}
        header={
          <div className={styles.type_header}>
            <div className={styles.type_header_left}>
              <SubfolderIcon />
              <p>{name_uz}</p>
            </div>
            <div className={styles.type_header_right}>
              <div onClick={handleStopPropagation}>
                <Switch onClick={handleChangeStatus} checked={status}
                        loading={changeStatusProblem.isLoading}></Switch>
              </div>
              <span>{problems_count} muammo</span>
              <ProblemTypeActions {...props} />
            </div>
          </div>
        }
      >
        {problems.map((item) => (
          <Problem key={item.id} {...item} />
        ))}
        <ProblemCreate typeId={id} />
      </Collapse.Panel>
    </Collapse>
  )
}

export default ProblemType
