import queryString from "query-string"

type Problems = {
  problem_class_ids: number[],
  problem_type_ids: number[],
  problem_ids: number[]
}

export const parseProblemsTreeData: (data: string[]) => Problems = (data) => {
  const problems: Problems = {
    problem_class_ids: [],
    problem_type_ids: [],
    problem_ids: [],
  }

  data?.forEach(item => {
    const parsed = queryString.parse(item)
    problems[parsed.key as keyof typeof problems]?.push(Number(parsed?.id))
  })

  return problems
}