import { ChatModel } from "../../utils/models/chatModel"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"

const initialState: { chats: ChatModel[] } = {
  chats: [],
}

const chatReducer = createSlice({
  name: sliceNames.CHATS,
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<ChatModel[]>) => {
      state.chats = action.payload
    },
    appendChat: (state, action: PayloadAction<ChatModel>) => {
      state.chats.unshift(action.payload)
    },
  },
})

export default chatReducer.reducer
export const chatReducerActions = chatReducer.actions