import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { ProblemClassModel } from "../utils/models/problemClassModel"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

export function useGetProblemClasses() {
  const url = addQueryParams(endpoints.REGION_PROBLEM_CLASS_VIEW)

  return useQuery<{
    data: ProblemClassModel[]
    current_page: number
    total: number
  }, ErrorRes>(
    [queryKeys.REGION_PROBLEM_CLASSES, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}


export function useGetRegionOrganizationSelectAll() {
  return useQuery([queryKeys.REGION_ORGANIZATION_SELECT_ALL], async () => {
    const res = await $api.get(endpoints.REGION_ORGANIZATION_SELECT_ALL)
    return res.data.data
  },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

export function useGetRegionMainOrganizationSelectAll() {
  return useQuery([queryKeys.REGION_MAIN_ORGANIZATION_SELECT_ALL], async () => {
    const res = await $api.get(endpoints.REGION_MAIN_ORGANIZATION_SELECT_ALL)
    return res.data.data
  },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}
export function useGetRegionCitySelectAll() {
  return useQuery([queryKeys.REGION_CITY_SELECT_ALL], async () => {
    const res = await $api.get(endpoints.REGION_CITY_SELECT_ALL)
    return res.data.data
  },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}