import React from "react"
import { Modal } from "antd"

import CloseIcon from "../../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux"
import { regionStatisticsActions } from "../../../../../store/reducers/regionStatisticsReducer"
import StatisticsOrganizationsAndCities from "../StatisticsOrganizationsAndCities"

import styles from "../statisticsOrganizationsAndCities.module.scss"

const StatisticsOrganizationsAndCitiesModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setModalData } = regionStatisticsActions
  const { modalData } = useAppSelector((state) => state.regionStatisticsReducer)

  // cancel
  const handleCancel = () => {
    dispatch(
      setModalData({
        visible: false,
      })
    )
  }

  return (
    <Modal
      centered
      width={1280}
      footer={false}
      onCancel={handleCancel}
      open={modalData?.visible}
      closeIcon={<CloseIcon />}
      className={styles.modal}
    >
      <StatisticsOrganizationsAndCities isModal={true} />
    </Modal>
  )
}

export default StatisticsOrganizationsAndCitiesModal
