import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/endpoints"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { showMessage } from "../../../utils/helpers/showMessage"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { queryKeys } from "../utils/queryKeys"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// update profile
export const useUpdateProfile = () => {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(endpoints.PROFILE_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.PROFILE_VIEW])
      },
      onError: errorHandler,
    },
  )
}