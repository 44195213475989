import { LocalStorage } from "../../services/LocalStorage"
import { USER } from "../constants/localStorageKeys"
import { roles } from "../enums/roles"
import { UserModel } from "../models/userModel"

export const hasPermission = (allowedRoles: roles[]) => {
  const userRole = LocalStorage.get<UserModel>(USER)?.role

  if (!userRole) return false

  if (allowedRoles.includes(userRole)) {
    return true
  }

  return false
}
