import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { REGION_ID } from "../../../utils/constants/queryParamsKeys"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { ProblemSelectModel } from "../utils/models/ProblemSelectModel"
import { NeighborhoodSelectModel } from "../utils/models/NeighborhoodSelectModel"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/models/responseType"
import { isEmptyArray } from "../../../utils/helpers/isEmptyArray"
import querystring from "query-string"

// region select
export function useGetRegionSelect(enabled: boolean) {
  return useQuery<{ id: number; name: string }[]>(
    [queryKeys.REGION_SELECT],
    async () => {
      const res = await $api.get(endpoints.REGION_SELECT)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
      staleTime: Infinity,
    },
  )
}

// district select
export function useGetDistrictSelect(regionId: number | undefined) {
  return useQuery<{ id: number; name: string }[]>(
    [queryKeys.DISTRICT_SELECT, regionId],
    async () => {
      const res = await $api.get(
        `${endpoints.DISTRICT_SELECT}?${REGION_ID}=${regionId}`,
      )
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!regionId,
      staleTime: Infinity,
    },
  )
}

// problems select
export function useGetProblemSelect(enabled: boolean, endpoint: string) {
  return useQuery<ProblemSelectModel[]>([queryKeys.PROBLEM_SELECT], async () => {
    const res = await $api.get(endpoint)
    return res.data.data
  }, {
    refetchOnWindowFocus: false,
    enabled,
    staleTime: Infinity,
  })
}

// neighborhood select
export function useGetNeighborhoodSelect(enabled: boolean, districtIds: number[] | undefined, endpoint: string) {
  let url = endpoint

  if (!isEmptyArray(districtIds)) {
    url += `?${querystring.stringify({ district_ids: districtIds }, { arrayFormat: "bracket" })}`
  }

  return useQuery<NeighborhoodSelectModel[]>([queryKeys.NEIGHBORHOOD_SELECT, districtIds], async () => {
    const res = await $api.get(url)
    return res.data.data
  }, {
    refetchOnWindowFocus: false,
    enabled: enabled !== undefined,
  })
}

// tag select
export const useGetTagSelect = () => {
  return useQuery<{ id: number, name: string }[], ErrorRes>(
    [queryKeys.TAG_SELECT],
    async () => {
      const res = await $api.get(endpoints.TAG_SELECT)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

