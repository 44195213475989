import React from "react"
import { Tag } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { UseMutationResult } from "@tanstack/react-query"

import CloseIcon from "../../assets/icons/CloseIcon"
import { ErrorRes, SuccessRes } from "../../utils/models/responseType"

import styles from "./tags.module.scss"

type Props = {
  id: number
  name: string
  useDeleteTag: () => UseMutationResult<SuccessRes, ErrorRes, { id: number }>
}

const TagItem: React.FC<Props> = ({ id, name, useDeleteTag }) => {
  const deleteTag = useDeleteTag()
  const spin = <LoadingOutlined style={{ fontSize: 12 }} spin />

  // delete
  const handleDelete = () => {
    deleteTag.mutate({ id })
  }

  return (
    <Tag className={`${styles.tag} ${deleteTag.isLoading ? styles.is_loading : ""}`}>
      {name}
      {deleteTag.isLoading ? spin : <CloseIcon onClick={handleDelete} />}
    </Tag>
  )
}

export default TagItem