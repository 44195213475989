export const rootPaths = {
  AUTH: "/",
  CITIES: "/cities",
  ORGANIZATIONS: "/organizations",
  MAIN_ORGANIZATIONS: "/main-organizations",
  FEEDBACKS: "/feedbacks",
  PROBLEMS: "/problems",
  PROBLEM_CLASS: "/problem-class",
  SETTINGS: "/settings",
  EMPLOYEES: "/employees",
  STATISTICS: "/statistics",
  ACCIDENTS: "/accidents",
  SUBSIDIARIES: "/subsidiaries",
}
