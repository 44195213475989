import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { sliceNames } from "../../utils/constants/sliceNames"
import { MainOrganizationModel } from "../../features/region-main-organizations/utils/models/mainOrganizationModel"
import { AdminModel } from "../../features/region-main-organizations/utils/models/adminModel"

const initialState: {
  mainOrganizationModalData: { visible: boolean; data?: Omit<MainOrganizationModel, "admins"> }
  mainOrganizationAdminModalData: {
    visible: boolean
    main_organization_id: number | null
    data?: AdminModel
  }
} = {
  mainOrganizationModalData: {
    visible: false,
  },
  mainOrganizationAdminModalData: {
    visible: false,
    main_organization_id: null,
  },
}

const regionMainOrganizationReducer = createSlice({
  name: sliceNames.REGION_MAIN_ORGANIZATION,
  initialState,
  reducers: {
    setMainOrganizationModalData: (
      state,
      action: PayloadAction<{
        visible: boolean
        data?: Omit<MainOrganizationModel, "admins">
      }>,
    ) => {
      state.mainOrganizationModalData = action.payload
    },
    setAdminModalData: (
      state,
      action: PayloadAction<{
        visible: boolean
        main_organization_id: number | null
        data?: AdminModel
      }>,
    ) => {
      state.mainOrganizationAdminModalData = action.payload
    },
  },
})

export default regionMainOrganizationReducer.reducer
export const regionMainOrganizationReducerActions = regionMainOrganizationReducer.actions
