import React from "react"

import BodyLayout from "../../common/body-layout/BodyLayout"
import { useAppDispatch } from "../../hooks/redux"
import { useGetSubsidiaries } from "./services/queries"
import SubsidiaryFolder from "./components/subsidiary-folder/SubsidiaryFolder"
import EmployeeModal from "./components/employee-modal/EmployeeModal"
import SubsidiaryModal from "./components/subsidiary-modal/SubsidiaryModal"
import { organizationSubsidiaryReducerActions } from "../../store/reducers/organizationSubsidiaryReducer"
import FolderLoading from "../../common/folder-loading/FolderLoading"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setSubsidiaryModalData } = organizationSubsidiaryReducerActions
  const { data, isLoading } = useGetSubsidiaries()

  // create
  const handleCreate = () => {
    dispatch(setSubsidiaryModalData({
      visible: true,
    }))
  }

  return (
    <>
      <BodyLayout
        title="Shirkatlar"
        createButton={{
          text: "Shirkat yaratish",
          onCreate: handleCreate,
        }}
        pagination={{
          total: data?.total,
        }}
      >
        <div className={styles.subsidiaries}>
          {isLoading ? (
            <FolderLoading />
          ) : data?.data?.map((item) => (
            <SubsidiaryFolder {...item} key={item.id} />
          ))}
        </div>
      </BodyLayout>
      <SubsidiaryModal />
      <EmployeeModal />
    </>
  )
}

export default Index