import React, { useEffect, useMemo } from "react"
import { Col, Form, Input, Modal, Row, Select } from "antd"
import dayjs from "dayjs"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { organizationAccidentReducerActions } from "../../../../store/reducers/organizationAccidentReducer"
import { useCreateAccident, useUpdateAccident } from "../../services/mutations"
import DatePicker from "../../../../utils/helpers/datePicker"
import { dayjsFormats } from "../../../../utils/constants/dayjsFormats"
import { accidentStatuses } from "../../../../utils/enums/accidentStatuses"
import { useGetProblemClass } from "../../services/queries"
import SelectSuffixIcon from "../../../../assets/icons/SelectSuffixIcon"
import { AccidentFormFieldsModel } from "../../utils/models/accidentFormFieldsModel"

import styles from "./accidentModal.module.scss"
import { AccidentCreateBodyModel } from "../../utils/models/accidentCreateBodyModel"


const AccidentModal: React.FC = () => {
  const [form] = Form.useForm<AccidentFormFieldsModel>()
  const dispatch = useAppDispatch()
  const { setModalData } = organizationAccidentReducerActions
  const { modalData } = useAppSelector(state => state.organizationAccidentReducer)
  const { data: problems, isLoading: problemsIsLoading } = useGetProblemClass()
  const createAccident = useCreateAccident()
  const updateAccident = useUpdateAccident()

  // is updating
  const isUpdating = useMemo(() => !!modalData.data, [modalData])

  // is confirmed
  const isConfirmed = useMemo(() => {
    return isUpdating && modalData.data?.status === accidentStatuses.CONFIRMED
  }, [isUpdating, modalData?.data?.status])

  // initial form fields
  useEffect(() => {
    if (isUpdating) {
      form.setFieldsValue({
        ...modalData.data,
        start_time: dayjs.unix(modalData.data?.start_time as number),
        finish_time: dayjs.unix(modalData.data?.finish_time as number),
        status: undefined,
      })
    }
  }, [isUpdating])

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // finish
  const onFinish = (fields: AccidentFormFieldsModel) => {
    const req = {
      ...fields,
      start_time: dayjs(fields.start_time).unix(),
      finish_time: dayjs(fields.finish_time).unix(),
    }

    if (isUpdating) {
      updateAccident.mutateAsync({ ...req, id: modalData.data?.id as number }).then(handleCancel)
    } else {
      createAccident.mutateAsync(req as AccidentCreateBodyModel).then(handleCancel)
    }
  }

  // title
  const title = useMemo(() => {
    if (modalData.data) return "Avariya tahrirlash"
    return "Avariya yaratish"
  }, [modalData.data])

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title={title}
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      okButtonProps={{
        size: "large",
        loading: createAccident.isLoading || updateAccident.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
      className={styles.modal}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {!isConfirmed && (
          <>
            <Form.Item name="name" label="Nomi" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
            <Row gutter={[5, 0]} className={styles.time_row}>
              <Col flex={1}>
                <Form.Item name="start_time" label="Boshlanish vaqti" rules={[{ required: true, message: "" }]}>
                  <DatePicker placeholder="" showTime format={`${dayjsFormats.DATE} ${dayjsFormats.TIME}`} />
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="finish_time" label="Tugash vaqti" rules={[{ required: true, message: "" }]}>
                  <DatePicker placeholder="" showTime format={`${dayjsFormats.DATE} ${dayjsFormats.TIME}`} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="problem_class_id" label="Turi" rules={[{ required: true, message: "" }]}>
              <Select suffixIcon={<SelectSuffixIcon />} loading={problemsIsLoading}>
                {problems?.map(item => (
                  <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="streets_name" label="Ta'siri" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </>
        )}
        {(isUpdating || isConfirmed) && (
          <Form.Item name="status" label="Statusi" rules={[{ required: isConfirmed, message: "" }]}>
            <Select suffixIcon={<SelectSuffixIcon />}>
              <Select.Option value={accidentStatuses.COMPLETED}>
                Yakunlangan
              </Select.Option>
              <Select.Option value={accidentStatuses.REJECTED}>
                Bekor qilingan
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        {!isUpdating && (
          <Form.Item name="comment" label="Izoh" rules={[{ required: true, message: "" }]}>
            <Input />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default AccidentModal
