import queryString from "query-string"

type Neighborhoods = {
  neighborhood_ids: number[]
  street_ids: number[]
  blok_ids: number[]
}

export const parseNeighborhoodsTreeData: (data: string[]) => Neighborhoods = (data) => {
  const neighborhoods: Neighborhoods = {
    neighborhood_ids: [],
    street_ids: [],
    blok_ids: [],
  }

  data?.forEach(item => {
    const parsed = queryString.parse(item)
    neighborhoods[parsed.key as keyof typeof neighborhoods]?.push(Number(parsed?.id))
  })

  return neighborhoods
}