import { Table } from "antd"
import React from "react"

import DeleteIcon from "../../assets/icons/DeleteIcon"
import EditIcon from "../../assets/icons/EditIcon"
import BodyLayout from "../../common/body-layout/BodyLayout"
import EmployeeModal from "./components/modal/EmployeeModal"
import { useOrganizationEmployees } from "./services/queries"
import { OrganizationAdminsModel } from "./utils/models/organizationAdminsModels"
import { useAppDispatch } from "../../hooks/redux"
import { organizationAdminsReducerActions } from "../../store/reducers/organizationAdminsReducer"
import { useDeleteOrganizationAdmins } from "./services/mutation"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"

import styles from "./organizationEmployes.module.scss"
import { roles } from "../../utils/enums/roles"

const Index: React.FC = () => {
  const { data, isLoading } = useOrganizationEmployees()
  const dispatch = useAppDispatch()
  const { setOrganizationModalData } = organizationAdminsReducerActions
  const deleteOrgAdmin = useDeleteOrganizationAdmins()

  const handleOpenModal = () => {
    dispatch(
      setOrganizationModalData({
        visible: true,
      }),
    )
  }

  const handleUpdateOrganizationAdmins = (data: OrganizationAdminsModel) => {
    dispatch(setOrganizationModalData({ visible: true, data }))
  }

  const handleDeleteOrgAdmin = (id: number) => {
    return deleteOrgAdmin.mutateAsync(id)
  }

  const columns = [
    {
      title: "To'liq ismi",
      dataIndex: "full_name",
      render: (text: string) => <a className={styles.employeesTitle}>{text}</a>,
    },
    {
      title: "Telefon raqam",
      dataIndex: "phone",
      render: (phone: string) => (
        <a className={styles.employeesContent}>{phone}</a>
      ),
    },
    {
      title: "Lavozimi",
      dataIndex: "role",
      render: (text: string) => <span>{text === roles.ORGANIZATION_ADMIN ? "Xodim" : "Admin"}</span>,
    },
    {
      title: "Mas'ul tashkilotlari",
      render: (render: OrganizationAdminsModel) => (
        <span>
          {render?.subsidiaries?.map((item) => (
            <p>{item?.name}</p>
          ))}
        </span>
      ),
    },
    {
      title: "",
      render: (render: OrganizationAdminsModel) => (
        <div className={styles.employeesAction}>
          <EditIcon onClick={() => handleUpdateOrganizationAdmins(render)} />
          <DeleteConfirm
            text={`${render?.full_name} ismli xodimni o'chirmoqchimisiz?`}
            onConfirm={() => handleDeleteOrgAdmin(render?.id)}
            isLoading={deleteOrgAdmin.isLoading}
          >
            <DeleteIcon />
          </DeleteConfirm>
        </div>
      ),
    },
  ]

  return (
    <BodyLayout
      title="Xodimlar"
      createButton={{
        text: "Xodimlar qo'shish",
        onCreate: () => handleOpenModal(),
      }}
      pagination={{
        total: data?.total,
      }}
    >
      <Table
        columns={columns}
        rowKey={(render) => render.id}
        dataSource={data?.data}
        pagination={false}
        loading={isLoading}
        className={styles.employeesTable}
      />
      <EmployeeModal />
    </BodyLayout>
  )
}

export default Index
