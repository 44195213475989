import { Skeleton } from "antd"
import React from "react"

import styles from "./folderLoading.module.scss"

const FolderLoading: React.FC = () => {
  return (
    <div className={styles.loading}>
      <Skeleton
        className={styles.skleton}
        active
        paragraph={{
          rows: 10,
          className: styles.paragraph,
        }}
        title={false}
      />
    </div>
  )
}

export default FolderLoading
