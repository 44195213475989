import React from "react"
import { Modal } from "antd"

import CloseIcon from "../../../../../assets/icons/CloseIcon"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux"
import { cityStatisticsReducerActions } from "../../../../../store/reducers/cityStatisticsReducer"
import StatisticsOrganizations from "../StatisticsOrganizations"

import styles from "../statisticsOrganizations.module.scss"

const StatisticsOrganizationModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setModalData } = cityStatisticsReducerActions
  const { modalData } = useAppSelector(state => state.cityStatisticsReducer)

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({
      visible: false,
    }))
  }

  return (
    <Modal
      centered
      width={1113}
      footer={false}
      onCancel={handleCancel}
      open={modalData?.visible}
      closeIcon={<CloseIcon />}
      className={styles.modal}
    >
      <StatisticsOrganizations isModal={true} />
    </Modal>
  )
}

export default StatisticsOrganizationModal