import { endpoints } from "../utils/constants/endpoints"
import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { queryKeys } from "../utils/constants/queryKeys"
import { CityEmployeeModel } from "../utils/models/CityEmployeeModel"
import { CityOrganizationSelect } from "../utils/models/CityOrganizationSelectModels"
import { Pagination } from "../../../utils/models/paginationType"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/models/responseType"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

export function useCityAdmins() {
  const url = addQueryParams(endpoints.CITY_ADMIN_VIEW)

  return useQuery<Pagination<CityEmployeeModel>, ErrorRes>(
    [queryKeys.CITY_ADMIN_VIEW, url],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

export function useGetCityOrganizations() {
  return useQuery<CityOrganizationSelect, ErrorRes>([queryKeys.CITY_ORGANIZATION_SELECT], async () => {
      const res = await $api.get(endpoints.CITY_ORGANIZATION_SELECT)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    })
}