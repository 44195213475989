import queryString from "query-string"

import { NeighborhoodSelectModel } from "../models/NeighborhoodSelectModel"

export const neighborhoodsToFormFields: (neighborhoods: NeighborhoodSelectModel[]) => string[] = (neighborhoods) => {
  const data: string[] = []

  neighborhoods?.forEach(neighborhood => {
    if (neighborhood.selected) {
      data.push(queryString.stringify({
        name: neighborhood.name,
        key: "neighborhood_ids",
        id: neighborhood.id,
      }))
    }

    neighborhood?.streets?.forEach(street => {
      if (street.selected) {
        data.push(queryString.stringify({
          name: street.name,
          key: "street_ids",
          id: street.id,
        }))
      }

      street?.bloks?.forEach(item => {
        if (item.selected) {
          data.push(queryString.stringify({
            name: item.name,
            key: "blok_ids",
            id: item.id,
          }))
        }
      })
    })
  })

  return data
}