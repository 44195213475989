import React, { useState } from "react"
import { Select, Table, TableColumnsType } from "antd"

import { StatisticsOrganizationsModel } from "../../utils/models/statisticsOrganizationsModel"
import { useGetTagSelect } from "../../../app/services/queries"
import { useGetStatisticsOrganizations } from "../../services/queries"
import CustomPagination from "../../../../common/custom-pagination/CustomPagination"
import StatisticsOrganizationModal from "./modal/StatisticsOrganizationModal"
import { useAppDispatch } from "../../../../hooks/redux"
import { cityStatisticsReducerActions } from "../../../../store/reducers/cityStatisticsReducer"

import styles from "./statisticsOrganizations.module.scss"

type Props = {
  isModal: boolean
}

const StatisticsOrganizations: React.FC<Props> = ({ isModal }) => {
  const dispatch = useAppDispatch()
  const { setModalData } = cityStatisticsReducerActions
  const [page, setPage] = useState(1)
  const { data: tags, isLoading: tagsIsLoading } = useGetTagSelect()
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const { data, isLoading } = useGetStatisticsOrganizations(selectedTags)

  // columns
  const columns: TableColumnsType<StatisticsOrganizationsModel> = [
    {
      title: "№",
      width: 66,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Tashkilotlar",
      render: (_, payload) => payload.name,
    },
    {
      title: "Yangi",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_new">{payload.troubles?.new}</span>
      ),
    },
    {
      title: "Jarayonda",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_completed">{payload.troubles?.process}</span>
      ),
    },
    {
      title: "Yakunlandi",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_confirmed">{payload.troubles?.completed}</span>
      ),
    },
    {
      title: "Bajarildi",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_confirmed">{payload.troubles?.closed}</span>
      ),
    },
    {
      title: "Rad etilgan",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_rejected">{payload.troubles?.rejected}</span>
      ),
    },
    {
      title: "Qayta yo'naltirilgan",
      width: 160,
      align: "center",
      render: (_, payload) => (
        <span className="status_rejected">{payload.troubles?.user_rejected}</span>
      ),
    },
  ]

  // open modal
  const handleOpenModal = () => {
    dispatch(
      setModalData({
        visible: true,
      })
    )
  }

  // change tags
  const handleChangeTags = (tags: string[]) => {
    setSelectedTags(tags)
    if (page > 1) {
      setPage(1)
    }
  }

  return (
    <div className={`${styles.organizations} ${isModal ? styles.modal : ""}`}>
      <div className={styles.header}>
        <h2>{isModal ? "Tashkilotlar" : "Top 10 tashkilotlar"}</h2>
        <Select
          value={selectedTags}
          placeholder="Teglar bo'yicha"
          mode="multiple"
          onChange={handleChangeTags}
          loading={tagsIsLoading}
        >
          {tags?.map((item) => (
            <Select.Option
              key={item.id}
              value={JSON.stringify({
                label: item.name,
                value: item.id,
              })}
            >
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
      <div className={styles.footer}>
        {isModal ? (
          data && (
            <CustomPagination
              total={data?.total}
              stateData={{
                page,
                setPage,
              }}
            />
          )
        ) : (
          <p onClick={handleOpenModal}>Barchasini ko’rish</p>
        )}
      </div>
      {!isModal && <StatisticsOrganizationModal />}
    </div>
  )
}

export default StatisticsOrganizations
