import React from "react"

import BodyLayout from "../../common/body-layout/BodyLayout"
import CitiesFolder from "./components/cities-folder/CitiesFolder"
import AdminModal from "./components/admin-modal/AdminModal"
import { useGetCities } from "./services/queries"
import CityModal from "./components/city-modal/CityModal"
import FolderLoading from "../../common/folder-loading/FolderLoading"
import { useAppDispatch } from "../../hooks/redux"
import { regionCityReducerActions } from "../../store/reducers/regionCityReducer"

import styles from "./index.module.scss"

const Index: React.FC = () => {
  const { data, isLoading } = useGetCities()
  const dispatch = useAppDispatch()
  const { setCityModalData } = regionCityReducerActions

  const handleOpenModal = () => {
    dispatch(
      setCityModalData({
        visible: true,
      }),
    )
  }

  return (
    <BodyLayout
      title="Shaharlar"
      createButton={{
        text: "Shahar qo'shish",
        onCreate: handleOpenModal,
      }}
      pagination={{
        total: data?.total,
      }}
    >
      <div className={styles.cities}>
        {isLoading ? (
          <FolderLoading />
        ) : (
          data?.data?.map((item) => <CitiesFolder {...item} key={item.id} />)
        )}
      </div>
      <CityModal />
      <AdminModal />
    </BodyLayout>
  )
}

export default Index
