import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import React from "react"
import DeleteIcon from "../../assets/icons/DeleteIcon"
import EditIcon from "../../assets/icons/EditIcon"
import BodyLayout from "../../common/body-layout/BodyLayout"
import MainOrganizationEmployeeModal from "./components/modal/MainOrganizationEmployeeModal"
import { useMainOrganizationAdmins } from "./services/queries"
import { MainOrganizationEmployeeModel } from "./utils/models/MainOrganizationEmployeeModel"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import { useDeleteMainOrganizationAdmin } from "./services/mutation"

import styles from "./mainOrganizationEmployees.module.scss"
import { useAppDispatch } from "../../hooks/redux"
import { mainOrganizationEmployeeReducerActions } from "../../store/reducers/mainOrganizationEmployeeReducer"
import { roles } from "../../utils/enums/roles"

const Index: React.FC = () => {
  const { data, isLoading } = useMainOrganizationAdmins()
  const deleteAdmin = useDeleteMainOrganizationAdmin()
  const { setMainOrganizationEmployeeModalData: setModalOpen } = mainOrganizationEmployeeReducerActions
  const dispatch = useAppDispatch()

  //handleOpenModal
  const handleOpenModal = () => {
    dispatch(
      setModalOpen({
        visible: true,
      }),
    )
  }

  //handleDeleteAdmin
  const handleDeleteCityAdmin = (id: number) => {
    return deleteAdmin.mutateAsync(id)
  }

  //handleUpdateAdmin

  const handleUpdateCityAdmin = (data: MainOrganizationEmployeeModel) => {
    dispatch(
      setModalOpen({
        visible: true,
        data,
      }),
    )
  }

  const columns: ColumnsType<MainOrganizationEmployeeModel> = [
    {
      title: "To'liq ismi",
      dataIndex: "full_name",
      render: (text: string) => <a className={styles.employeesTitle}>{text}</a>,
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      render: (phone: string) => (
        <a className={styles.employeesContent}>{phone}</a>
      ),
    },
    {
      title: "Lavozimi",
      dataIndex: "role",
      render: (text: string) => <span>{text === roles.MAIN_ORGANIZATION_ADMIN ? "Xodim" : "Admin"}</span>,
    },
    {
      title: "Mas'ul tashkilotlari",
      render: (render: MainOrganizationEmployeeModel) => (
        <div className={styles.organization}>
          {render?.organizations?.map((item) => (
            <p>{item?.name}</p>
          ))}
        </div>
      ),
    },
    {
      render: (render: MainOrganizationEmployeeModel) => (
        <div className={styles.employeesAction}>
          <EditIcon onClick={() => handleUpdateCityAdmin(render)} />
          <DeleteConfirm
            text={`${render?.full_name} ismli xodimni o'chirmoqchimisiz?`}
            onConfirm={() => handleDeleteCityAdmin(render?.id)}
            isLoading={deleteAdmin?.isLoading}
          >
            <DeleteIcon />
          </DeleteConfirm>
        </div>
      ),
    },
  ]

  return (
    <BodyLayout
      title="Xodimlar"
      createButton={{
        text: "Xodimlar qo'shish",
        onCreate: () => handleOpenModal(),
      }}
      pagination={{
        total: data?.total,
      }}
    >
      <Table
        rowKey={(render) => render.id}
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        className={styles.employeesTable}
        loading={isLoading}
      />
      <MainOrganizationEmployeeModal />
    </BodyLayout>
  )
}

export default Index
