import React from "react"

const RedRejectIcon: React.FC = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" strokeWidth="8" />
      <path
        d="M18 26C18 26 20.005 23.2682 21.6338 21.6382C23.2627 20.0083 25.5136 19 28 19C32.9706 19 37 23.0294 37 28C37 32.9706 32.9706 37 28 37C23.8969 37 20.4351 34.2543 19.3518 30.5M18 26V20M18 26H24"
        stroke="#D92D20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default RedRejectIcon