import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { ID } from "../../../utils/constants/queryParamsKeys"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { showMessage } from "../../../utils/helpers/showMessage"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { messageTypes } from "../../../utils/enums/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"

// create problem class
export function useCreateProblemClass() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_PROBLEM_CLASS_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// update problem class
export function useUpdateProblemClass() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_PROBLEM_CLASS_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// delete problem class
export function useDeleteProblemClass() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.REGION_PROBLEM_CLASS_DELETE}?${ID}=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// create problem type
export function useCreateProblemType() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { name_uz: string, name_ru: string, problem_class_id: number }>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_PROBLEM_TYPE_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// update problem type
export function useUpdateProblemType() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { name_uz: string, name_ru: string, id: number }>(
    async (req) => {
      const res = await $api.put(endpoints.REGION_PROBLEM_TYPE_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// delete problem type
export function useDeleteProblemType() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.REGION_PROBLEM_TYPE_DELETE}?${ID}=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// create problem
export function useCreateProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, {
    name_uz: string
    name_ru: string
    image_upload_mandatory: number
    problem_type_id: number
  }>(
    async (req) => {
      const res = await $api.post(endpoints.REGION_PROBLEM_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// update problem
export function useUpdateProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, {
    name_uz: string,
    name_ru: string,
    image_upload_mandatory: number
    id: number
    problem_type_id: number
  }>(
    async (req) => {
      const res = await $api.put(endpoints.REGION_PROBLEM_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// delete problem
export function useDeleteProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, number>(
    async (id) => {
      const res = await $api.delete(`${endpoints.REGION_PROBLEM_DELETE}?${ID}=${id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}

// change status problem
export function useChangeStatusProblem() {
  const qc = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number, endpoint: string }>(
    async (req) => {
      const res = await $api.patch(req.endpoint, { id: req.id })
      return res.data
    }, {
      onSuccess: (res) => {
        showMessage(res.message.uz, messageTypes.SUCCESS)
        qc.invalidateQueries([queryKeys.REGION_PROBLEM_CLASSES])
      },
      onError: errorHandler,
    },
  )
}