import { useMutation } from "@tanstack/react-query"
import { LocalStorage } from "../../../services/LocalStorage"
import $api from "../../../services/RequestService"
import { initialRoute } from "../../../utils/constants/initialRoute"
import { TOKEN } from "../../../utils/constants/localStorageKeys"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/endPoints"
import { LoginBodyType, LoginResType } from "../utils/models/loginType"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { isBuilding } from "../../../utils/helpers/isBuilding"
import { useAppDispatch } from "../../../hooks/redux"
import { userReducerActions } from "../../../store/reducers/userReducer"

export function useLogin() {
  const dispatch = useAppDispatch()
  const { setUser, setIsAuth } = userReducerActions

  return useMutation<SuccessRes<LoginResType>, ErrorRes, LoginBodyType>(
    async (data) => {
      const res = await $api.post(`${endpoints.LOGIN}`, data)
      return res.data
    },
    {
      onSuccess: async (res) => {
        !isBuilding && LocalStorage.set(TOKEN, res.data.Authorization)
        dispatch(setUser({
          role: res.data.user.role,
          id: res.data.user.id,
          full_name: res.data.user.full_name,
          image: res.data.user.image,
          login: res.data.user.login,
          phone: res.data.user.phone,
        }))
        dispatch(setIsAuth(true))
        window.location.replace(
          initialRoute[res.data.user.role as keyof typeof initialRoute],
        )
      },
      onError: errorHandler,
    },
  )
}
