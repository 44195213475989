import React from "react"
import { Button } from "antd"

import { OrganizationModel } from "../../../utils/models/organizationModel"
import EditIcon from "../../../../../assets/icons/EditIcon"
import DeleteConfirm from "../../../../../common/delete-confirm/DeleteConfirm"
import DeleteIcon from "../../../../../assets/icons/DeleteIcon"
import DotsIcon from "../../../../../assets/icons/DotsIcon"
import { useAppDispatch } from "../../../../../hooks/redux"
import { cityOrganizationReducerActions } from "../../../../../store/reducers/cityOrganizationReducer"
import { useDeleteOrganization } from "../../../services/mutations"
import PopoverActions from "../../../../../common/popover-actions/PopoverActions"

import styles from "../../../../../styles/actions-popover/actionsPopover.module.scss"

const OrganizationFolderActions: React.FC<OrganizationModel> = (props) => {
  const { id, name, admins_count } = props
  const dispatch = useAppDispatch()
  const deleteOrganization = useDeleteOrganization()
  const { setOrganizationModalData: setModalData } = cityOrganizationReducerActions


  // update
  const handleUpdate = () => {
    dispatch(
      setModalData({
        data: props,
        visible: true,
        organizationId: id,
      }),
    )
  }

  // delete
  const handleDelete = () => {
    return deleteOrganization.mutateAsync(id)
  }

  // content
  const content = (
    <div className={styles.popover_content}>
      <Button type="link" className="d-n" onClick={handleUpdate}>
        <EditIcon />
        <span>Tahrirlash</span>
      </Button>
      {admins_count === 0 && (
        <DeleteConfirm
          text={`${name} nomli tashkilotni o'chirmoqchimisiz?`}
          onConfirm={handleDelete}
          isLoading={deleteOrganization.isLoading}
        >
          <Button type="link">
            <DeleteIcon />
            <span>O’chirish</span>
          </Button>
        </DeleteConfirm>
      )}
    </div>
  )

  return (
    <PopoverActions content={content}>
      <DotsIcon />
    </PopoverActions>
  )
}

export default OrganizationFolderActions