import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../utils/constants/queryKeys"
import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/endpoints"
import { Pagination } from "../../../utils/models/paginationType"
import { SubsidiaryModel } from "../utils/models/subsidiaryModel"
import { SubsidiarySelectModel } from "../utils/models/subsidiarySelectModel"
import { DetailedSubsidiaryModel } from "../utils/models/detailedSubsidiaryModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { addQueryParams } from "../../../utils/helpers/addQueryParams"

// get subsidiaries
export function useGetSubsidiaries() {
  const url = addQueryParams(endpoints.ORGANIZATION_SUBSIDIARY_VIEW)

  return useQuery<Pagination<SubsidiaryModel>, ErrorRes>(
    [queryKeys.ORGANIZATION_SUBSIDIARY_VIEW, url],
    async () => {
      const res = await $api(url)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    },
  )
}

// get detailed subsidiary
export function useGetDetailedSubsidiary(id: number | undefined) {
  return useQuery<DetailedSubsidiaryModel, ErrorRes>(
    [queryKeys.ORGANIZATION_DETAILED_SUBSIDIARY, id],
    async () => {
      const res = await $api.get(`${endpoints.ORGANIZATION_SUBSIDIARY_VIEW}?id=${id}`)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: errorHandler,
    },
  )
}

// get subsidiaries select
export function useGetSubsidiariesSelect(enabled: boolean) {
  return useQuery<SubsidiarySelectModel[], ErrorRes>(
    [queryKeys.ORGANIZATION_SUBSIDIARY_SELECT],
    async () => {
      const res = await $api.get(endpoints.ORGANIZATION_SUBSIDIARY_SELECT)
      return res.data.data
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      retry: false,
      onError: errorHandler,
    },
  )
}