import React, { useEffect, useMemo } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal } from "antd"

import CloseIcon from "../../../../assets/icons/CloseIcon"
import TreeSelectProblems from "../../../../common/tree-select-problems/TreeSelectProblems"
import { problemsToFormFields } from "../../../app/utils/helpers/problemsToFormFields"
import TreeSelectNeighborhoods from "../../../../common/tree-select-neighborhoods/TreeSelectNeighborhoods"
import { neighborhoodsToFormFields } from "../../../app/utils/helpers/neighborhoodsToFormFields"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux"
import { organizationSubsidiaryReducerActions } from "../../../../store/reducers/organizationSubsidiaryReducer"
import { useCreateSubsidiary, useUpdateSubsidiary } from "../../services/mutations"
import { useGetDetailedSubsidiary } from "../../services/queries"
import { SubsidiaryBodyModel } from "../../utils/models/subsidiaryBodyModel"
import { parseProblemsTreeData } from "../../../app/utils/helpers/parseProblemsTreeData"
import { parseNeighborhoodsTreeData } from "../../../app/utils/helpers/parseNeighborhoodsTreeData"
import { endpoints } from "../../utils/constants/endpoints"

const SubsidiaryModal: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setSubsidiaryModalData: setModalData } = organizationSubsidiaryReducerActions
  const { subsidiaryModalData: modalData } = useAppSelector(
    (state) => state.organizationSubsidiaryReducer,
  )
  const createSubsidiary = useCreateSubsidiary()
  const updateSubsidiary = useUpdateSubsidiary()
  const { data, isLoading: dataIsLoading, isFetching: dataIsFetching } = useGetDetailedSubsidiary(modalData.id)

  // initial fields
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
      })
    }
  }, [data, form])

  // ok
  const handleOk = () => {
    form.submit()
  }

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({ visible: false }))
  }

  // after close
  const handleAfterClose = () => {
    form.resetFields()
  }

  // on finish
  const onFinish = (fields: { name: string, problems: string[], neighborhoods: string[] }) => {

    // req
    const req: SubsidiaryBodyModel = {
      ...parseProblemsTreeData(fields.problems),
      ...parseNeighborhoodsTreeData(fields.neighborhoods),
      name: fields.name,
    }

    if (modalData.id) {
      updateSubsidiary.mutateAsync({ ...req, id: modalData.id }).then(handleCancel)
    } else {
      createSubsidiary.mutateAsync(req).then(handleCancel)
    }
  }

  // initial tree problems
  const initialTreeProblems = useMemo(() => {
    return data && problemsToFormFields(data?.problem_classes)
  }, [data])

  // initial tree neighborhoods
  const initialTreeNeighborhoods = useMemo(() => {
    return data && neighborhoodsToFormFields(data?.neighborhoods)
  }, [data])

  // title
  const title = useMemo(() => {
    if (modalData.id) return "Shirkat tahrirlash"
    return "Shirkat yaratish"
  }, [modalData.id])

  return (
    <Modal
      centered
      width={512}
      onOk={handleOk}
      okText="Saqlash"
      title={
        <>
          {title}
          &nbsp;
          {modalData.id && (dataIsLoading || dataIsFetching) && <LoadingOutlined spin />}
        </>
      }
      onCancel={handleCancel}
      open={modalData.visible}
      cancelText="Bekor qilish"
      closeIcon={<CloseIcon />}
      afterClose={handleAfterClose}
      okButtonProps={{
        size: "large",
        loading: createSubsidiary.isLoading || updateSubsidiary.isLoading,
      }}
      cancelButtonProps={{ size: "large" }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Shirkat nomi"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <TreeSelectProblems
          endpoint={endpoints.PROBLEMS_TREE_SELECT}
          formInstance={form}
          enabled={modalData.visible}
          initialData={initialTreeProblems}
        />
        <TreeSelectNeighborhoods
          enabled={modalData.visible}
          districtIds={[0]}
          formInstance={form}
          endpoint={endpoints.NEIGHBORHOODS_TREE_SELECT}
          initialData={initialTreeNeighborhoods}
        />
      </Form>
    </Modal>
  )
}

export default SubsidiaryModal